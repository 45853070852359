import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_USERS_BY_IDS } from '@crimson-education/core-shared-graphql';
import _ from 'lodash';
import dayjs from 'dayjs';
import { ExpandMoreIcon } from '../icons';
import {
  StyledActivityLogDate,
  StyledActivityLogDesc,
  StyledActivityLogItem,
  StyledTitle,
  StyledViewMoreButton,
  StyledViewMoreContainer,
} from './style';
import { ActivityLogType, MissionDetailObjectType } from '../../types';
import {
  applyTemplate,
  hasTemplate,
  rbacCanAccess,
  templates,
} from './templates';
import { useApiClient, useUserInfo } from '../../context';

const perCount = 4;
export /* @__PURE__*/ function ActivityLog({
  mission,
  missionRework,
}: {
  mission: MissionDetailObjectType;
  missionRework?: boolean;
}): JSX.Element {
  const { coreApiClient } = useApiClient();
  const user = useUserInfo();
  const { refetch: refetchGetUserByIds } = useQuery(GET_USERS_BY_IDS, {
    client: coreApiClient,
    skip: true,
  });

  // step 1: find userId in values
  // step 2: fetch userName by userId
  // step 3: create a new variable named values={userId: newUserName}
  // step 4 use string replace to replace it.
  const [dataSource, setDataSource] = useState<ActivityLogType[]>([]);
  const [visibleDataSource, setVisibleDataSource] = useState<ActivityLogType[]>(
    []
  );
  const [currentVisibleCount, setCurrentVisibleCount] = useState(0);

  useEffect(() => {
    const roles = user.roles.map((role) => role.roleId);
    async function fetchData() {
      if (mission) {
        const { activityLogs } = mission;
        const sortedActivityLogs = _.orderBy(
          activityLogs,
          (v) => new Date(v.createdAt),
          ['desc']
        );
        setDataSource(
          sortedActivityLogs.filter(
            (v) =>
              hasTemplate(templates, v.template) &&
              rbacCanAccess(templates, { roles, code: v.template.code })
          )
        );
      }
    }
    fetchData();
  }, [mission, refetchGetUserByIds, user.roles]);

  useEffect(() => {
    if (dataSource) {
      if (dataSource.length > perCount) {
        setVisibleDataSource(dataSource.slice(0, perCount));
        setCurrentVisibleCount(perCount);
      } else {
        setCurrentVisibleCount(dataSource.length);
        setVisibleDataSource(dataSource);
      }
    }
  }, [dataSource]);
  const viewMore = () => {
    const nextCount = currentVisibleCount + perCount;
    if (dataSource.length > nextCount) {
      setVisibleDataSource(dataSource.slice(0, nextCount));
      setCurrentVisibleCount(nextCount);
    } else {
      setCurrentVisibleCount(dataSource.length);
      setVisibleDataSource(dataSource);
    }
  };
  return (
    <div>
      {!missionRework && <StyledTitle>Activity Log</StyledTitle>}
      {visibleDataSource.map((data) => {
        const {
          values,
          template: { code },
        } = data;
        return (
          <StyledActivityLogItem key={data.id}>
            <StyledActivityLogDesc style={{ color: '#73747D' }}>
              {applyTemplate(templates, { code, values })}
            </StyledActivityLogDesc>
            <StyledActivityLogDate>
              {dayjs(data.createdAt).format('MMM DD')} at{' '}
              {dayjs(data.createdAt).format('hh:mm a')}
            </StyledActivityLogDate>
          </StyledActivityLogItem>
        );
      })}
      {currentVisibleCount < dataSource.length ? (
        <StyledViewMoreContainer>
          <StyledViewMoreButton onClick={viewMore}>
            View More
            <ExpandMoreIcon width={20} />
          </StyledViewMoreButton>
        </StyledViewMoreContainer>
      ) : null}
    </div>
  );
}
