import { AddCircle } from '@styled-icons/material';
import { Checkbox, Spin, Tooltip } from 'antd';
import { useState, useEffect, useMemo } from 'react';
import { ItemContainer, StyledPopover, LoadingContainer } from './style';
import { QUERY_PREDEFINED_TASKS, CREATE_ACTION_ITEMS_FROM_PREDEFINED_TASKS } from '@crimson-education/core-shared-graphql';
import { useMutation, useQuery } from '@apollo/client';
import { MissionObjectType, ActionItem, PredefineTask } from '../../types';
import { transformPredefinedTask } from './util';
import { useApiClient } from '../../context';

const Item = ({
  checked,
  onClick,
  title,
  textStyle,
}: {
  checked: boolean;
  onClick: () => void;
  title: string;
  textStyle?: React.CSSProperties;
}): JSX.Element => {
  return (
    <Tooltip title={title} getPopupContainer={(node) => node.parentElement as HTMLElement}>
      <ItemContainer onClick={onClick}>
        <Checkbox checked={checked} />
        <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', ...textStyle }}>{title}</span>
      </ItemContainer>
    </Tooltip>
  );
};

const PopoverContent = ({
  data,
  onClickItem,
  onClickSelectAll,
  selectedIds,
}: {
  data: PredefineTask[];
  onClickItem: (id: string) => void;
  onClickSelectAll: () => void;
  selectedIds: string[];
}): JSX.Element => {
  const allSelected = useMemo(() => {
    return data.length === selectedIds.length;
  }, [selectedIds, data]);
  return (
    <div
      style={{
        width: 400,
        maxHeight: 300,
        overflowY: 'auto',
        position: 'relative',
      }}
    >
      <Item
        title={'Select All'}
        onClick={onClickSelectAll}
        checked={allSelected}
        textStyle={{
          color: '#6D6D77',
          fontSize: 14,
        }}
      />
      {data.map((item) => (
        <Item
          key={item.id}
          title={item.item}
          onClick={() => onClickItem(item.id)}
          checked={selectedIds.includes(item.id)}
        />
      ))}
    </div>
  );
};
export type PredefinedTaskToggleProps = {
  mission: MissionObjectType;
  updatePendingActionItems: (actionItems: Omit<ActionItem, 'id'>[]) => void;
  onNewActionItems: (actionItems: ActionItem[]) => void;
};
export const PredefinedTaskToggle = ({
  mission,
  updatePendingActionItems,
  onNewActionItems,
}: PredefinedTaskToggleProps): JSX.Element | null => {
  const [open, setOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { roadmapApiClient } = useApiClient();
  useEffect(() => {
    setSelectedIds([]);
  }, [mission]);
  const { data, loading } = useQuery<{
    getPredefinedTasks: PredefineTask[];
  }>(QUERY_PREDEFINED_TASKS, {
    client: roadmapApiClient,
    variables: {
      missionId: mission.id,
    },
  });
  const [createFromPredefinedTasks, { loading: creating }] = useMutation<{
    createActionItemsFromPredefinedTasks: ActionItem[];
  }>(CREATE_ACTION_ITEMS_FROM_PREDEFINED_TASKS, {
    client: roadmapApiClient,
    variables: {
      missionId: mission.id,
      predefinedTaskIds: selectedIds,
    },
  });

  useEffect(() => {
    if (!data?.getPredefinedTasks.length) return;
    const selectedItems = data.getPredefinedTasks.filter((item) => selectedIds.includes(item.id));
    const actionItems = selectedItems.map(transformPredefinedTask);
    updatePendingActionItems(actionItems);
  }, [selectedIds, updatePendingActionItems, data?.getPredefinedTasks]);

  const onClickSelectAll = () => {
    if (!data?.getPredefinedTasks.length) return;
    const ids = data.getPredefinedTasks.map((item) => item.id);
    if (selectedIds.length === ids.length) {
      setSelectedIds([]);
    } else {
      setSelectedIds(ids);
    }
  };

  const onClickItem = (id: string) => {
    if (!data?.getPredefinedTasks.length) return;
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((item) => item !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const createActionItems = async () => {
    if (!selectedIds.length) return true;
    const { data, errors } = await createFromPredefinedTasks();
    if (errors?.length) {
      return false;
    }
    setSelectedIds([]);
    onNewActionItems(data?.createActionItemsFromPredefinedTasks || []);
    return true;
  };
  const onVisibleChange = (visible: boolean) => {
    if (!visible) {
      createActionItems().then((success) => setOpen(!success));
      return;
    }

    setOpen(visible);
  };
  if (loading || !data?.getPredefinedTasks.length) return null;
  return (
    <StyledPopover
      placement={'bottomRight'}
      getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
      showArrow={false}
      content={
        <div>
          <PopoverContent
            selectedIds={selectedIds}
            onClickItem={onClickItem}
            onClickSelectAll={onClickSelectAll}
            data={data.getPredefinedTasks}
          />
          {creating && (
            <LoadingContainer>
              <Spin spinning />
            </LoadingContainer>
          )}
        </div>
      }
      open={open}
      onOpenChange={onVisibleChange}
      trigger={'click'}
    >
      <AddCircle
        style={{
          color: '#464AC9',
          width: 20,
          height: 20,
          cursor: 'pointer',
          marginRight: 20,
        }}
      />
    </StyledPopover>
  );
};
