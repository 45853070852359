
import styled from 'styled-components';
import { ExpandMore } from '@styled-icons/material/ExpandMore';
import { Select } from 'antd';

export const MoreLessIcon = styled(ExpandMore) <{ $open?: boolean }>`
  width: 18px;
  height: 18px;
  transform: rotate(${(props) => (props.$open ? '180deg' : '0deg')});
  transition: 0.4s;
  color: #1d1e2b;
`;

export const StyledOption = styled(Select.Option)`
  color: var(--color-stone);
  padding-left: 15px;
`;

export const StyledSelect = styled(Select)`
  & .ant-select-selector {
    min-height: 40px !important;
    border: 1px solid #e3e7ed;
    border-radius: 32px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  & .ant-select-selection-item {
    display: inline-block;
    line-height: 38px!important;
  }
  .ant-select-selection-placeholder{
    line-height: 38px!important;
  }
`;
