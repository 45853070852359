import { Textarea } from '../textarea/textarea';
import { Teams } from '../teams/teams';
import { ActivityLog } from '../activity-log/activity-log';
import { MissionStatus } from '../mission-status/mission-status';
import { RemoveButton } from '../remove-button/remove-button';
import { ActionItems } from '../action-items/action-items';
import { ActionItemsRework } from '../action-items/action-items-rework';
import {
  CHANGE_MISSION_TITLE,
  GET_MISSION_BY_ID,
  CHANGE_MISSION_DESCRIPTION,
  CHANGE_MISSION_STATUS,
  CHANGE_MISSION_START_DATE,
  CHANGE_MISSION_DUE_DATE,
  ADD_OR_UPDATE_TEST,
  LOAD_TEST_BY_MISSION_ID,
  ADD_ACTION_ITEMS,
  EDIT_MISSION,
  LOAD_ACTION_ITEMS,
} from '@crimson-education/core-shared-graphql';
import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { StyledClose } from '../icons';
import moment from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import { Tabs, notification } from 'antd';
import type { TabsProps } from 'antd';
import {
  Categories,
  MissionCategoryNameMapping,
  MissionDetailObjectType,
  missionStatusObject,
  MissionSubCategoryMapping,
  MissionChangeActions,
} from '../../types';
import {
  StyledMissionHeader,
  StyledMissionTitleContainer,
  StyledLabel,
  StyledMissionHeaderRight,
  StyledMissionDesc,
  StyledTeamContainer,
  StyledCloseIcon,
  StyledRemoveButton,
  StyledCategoryContainer,
  StyledRightOutlined,
  StyledRangeDatePickerContainer,
  StyledMissionTextareaContainer,
  StyledActionItemsContainer,
  StyledMoreHoriz,
  StyledTextArea,
  StyledResourcesContainer,
  StyledResourcesList,
  StyledTabContainer,
} from './style';
import { Drawer } from 'antd';
import { LoadingIndicator } from '../loading-indicator/loading-indicator';
import { RangeDatePicker } from '../range-date-picker/range-date-picker';
import { InternalNotes } from '../internal-notes/internal-notes';
import { MissionResourceLink } from '../mission-resource-link/mission-resource-link';
import { Reflections } from '../reflections/reflections';
import { useCanAccessInternalNotes, replaceDateInTitle } from './util';
import { theme } from '../../utils/theme';
import { useApiClient, useUserInfo, useFeatureFlag } from '../../context';
import { QUERY_TEMPLATE_ROADMAP } from './graphql';
import OperationButton from '../mission-operation/mission-operation';
import CWEditAuthProvider from './CWEditAuthProvider';

export type MissionDrawerProps = {
  visible: boolean;
  onClose: () => void;
  missionId: string;
  removeMission: () => void;
  editPermitted: boolean;
  deletePermitted: boolean;
  showRemoveBtn?: boolean;
  onMissionChange?: (action: MissionChangeActions) => void;
  isCWStudent?: boolean;
  isMissionCreatedByLoginUser?: boolean;
};
export /* @__PURE__*/ const MissionDrawer = ({
  visible,
  onClose,
  missionId,
  removeMission,
  editPermitted,
  deletePermitted,
  onMissionChange,
  showRemoveBtn = true,
  isCWStudent = false,
  isMissionCreatedByLoginUser = true,
}: MissionDrawerProps): JSX.Element => {
  const { roadmapApiClient, studentCenterApiClient } = useApiClient();
  const [missionData, setMissionData] = useState<MissionDetailObjectType>();
  const currentUser = useUserInfo();
  const canViewInternalNotes = useCanAccessInternalNotes(currentUser);
  const { refetch: refetchMission } = useQuery(GET_MISSION_BY_ID, {
    variables: { missionId },
    client: roadmapApiClient,
    skip: true,
  });
  const { refetch: refetchLoadTestByMissionId } = useQuery(
    LOAD_TEST_BY_MISSION_ID,
    {
      skip: true,
      client: studentCenterApiClient,
    },
  );
  const [changeMissionTitle] = useMutation(CHANGE_MISSION_TITLE, {
    client: roadmapApiClient,
    ignoreResults: true,
  });
  const [changeMissionDescription] = useMutation(CHANGE_MISSION_DESCRIPTION, {
    ignoreResults: true,
    client: roadmapApiClient,
  });
  const [changeMissionStatus] = useMutation(CHANGE_MISSION_STATUS, {
    ignoreResults: true,
    client: roadmapApiClient,
  });
  const [changeMissionStartDate] = useMutation(CHANGE_MISSION_START_DATE, {
    ignoreResults: true,
    client: roadmapApiClient,
  });
  const [changeMissionEndDate] = useMutation(CHANGE_MISSION_DUE_DATE, {
    ignoreResults: true,
    client: roadmapApiClient,
  });
  const [addOrUpdateTest] = useMutation(ADD_OR_UPDATE_TEST, {
    client: studentCenterApiClient,
  });

  const [datePickerType, setDatePickerType] = useState('default');

  const SELECT_OFFICIAL_TEST_DATE = useFeatureFlag('SELECT_OFFICIAL_TEST_DATE');
  const REFLECTIONS = useFeatureFlag('REFLECTIONS');

  useEffect(() => {
    async function fetchData() {
      if (missionId) {
        const result = await refetchLoadTestByMissionId({ missionId });
        if (result && result.data && result.data.testRecord) {
          const testRecord = result.data.testRecord;
          const { type, isMockTest } = testRecord;
          if ((type === 'SAT' || type === 'ACT') && !isMockTest) {
            setDatePickerType('startDate');
          }
        }
      }
    }
    if (SELECT_OFFICIAL_TEST_DATE) {
      if (missionData?.category === Categories.TEST) {
        fetchData();
      }
    }
  }, [
    SELECT_OFFICIAL_TEST_DATE,
    missionData?.category,
    missionId,
    refetchLoadTestByMissionId,
  ]);

  useEffect(() => {
    async function fetchData() {
      if (visible) {
        if (missionId) {
          const res = await refetchMission({ missionId });
          if (res?.data && res.data.mission) {
            setMissionData(res.data.mission);
          }
        }
      }
    }
    fetchData();
  }, [missionId, refetchMission, visible]);

  const reloadMission = async () => {
    if (missionId) {
      const result = await refetchMission({ missionId });
      if (result && result.data && result.data.mission) {
        setMissionData(result.data.mission);
      }
    }
  };
  const onDateChange = async (dates: RangeValue<moment.Moment>) => {
    if (dates && dates.length > 1) {
      if (!missionData?.startAt && !missionData?.dueDate) {
        await changeMissionStartDate({
          variables: {
            input: {
              missionId,
              startAt: dates[0],
            },
          },
        });
        await changeMissionEndDate({
          variables: {
            input: {
              missionId,
              dueDate: dates[1],
            },
          },
        });
      } else {
        if (
          moment(missionData?.startAt).diff(dates[0], 'days') !== 0 ||
          moment(missionData?.dueDate).diff(dates[1], 'days') !== 0
        ) {
          if (moment(missionData?.startAt).diff(dates[0], 'days') !== 0) {
            await changeMissionStartDate({
              variables: {
                input: {
                  missionId,
                  startAt: dates[0],
                },
              },
            });
          }
          if (moment(missionData?.dueDate).diff(dates[1], 'days') !== 0) {
            await changeMissionEndDate({
              variables: {
                input: {
                  missionId,
                  dueDate: dates[1],
                },
              },
            });
            // if it's a test mission, change mission title with xxx on {{dueDate}}
            if (missionData?.category === Categories.TEST) {
              if (missionData?.title) {
                const date = moment(dates[1]).format('MMMM D, yyyy');
                const newMissionTitle = replaceDateInTitle(
                  missionData?.title,
                  date,
                );
                await changeMissionTitle({
                  variables: {
                    input: {
                      missionId,
                      title: newMissionTitle,
                    },
                  },
                });
                try {
                  const result = await refetchLoadTestByMissionId({
                    missionId,
                  });
                  if (result && result.data && result.data.testRecord) {
                    const testRecord = result.data.testRecord;
                    await addOrUpdateTest({
                      variables: {
                        input: {
                          id: testRecord.id,
                          userId: testRecord.userId,
                          type: testRecord.type,
                          isMockTest: testRecord.isMockTest,
                          testDate: dates[1],
                        },
                      },
                    });
                  }
                } catch (e) {
                  console.warn(e);
                }
              }
            }
          }
          reloadMission();
        }
      }
    }
  };

  const MISSION_LIBRARY =
    useFeatureFlag('MISSION_LIBRARY') &&
    missionData?.integrationSource !== 'cri';
  // const MISSION_LIBRARY = true;
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Tasks & Reflections',
      children: (
        <>
          {missionData && (
            <ActionItemsRework
              editPermitted={editPermitted}
              deletePermitted={deletePermitted}
              mission={missionData}
              onMissionChange={onMissionChange}
              visible={visible}
              reloadMission={reloadMission}
              isCWStudent={isCWStudent}
              isMissionCreatedByLoginUser={isMissionCreatedByLoginUser}
            />
          )}
          {REFLECTIONS && missionData && (
            <StyledActionItemsContainer>
              <Reflections
                editPermitted={editPermitted}
                missionId={missionData.id}
              />
            </StyledActionItemsContainer>
          )}
        </>
      ),
    },
  ];
  if (canViewInternalNotes && missionData) {
    items.push({
      key: '2',
      label: 'Internal Notes',
      children: (
        <CWEditAuthProvider
          isCWStudent={isCWStudent}
          isMissionCreatedByLoginUser={isMissionCreatedByLoginUser}
        >
          <InternalNotes
            studentId={missionData.userId}
            missionId={missionData.id}
            editPermitted={editPermitted}
            missionRework={MISSION_LIBRARY}
          />
        </CWEditAuthProvider>
      ),
    });
  }
  if (missionData) {
    items.push({
      key: '3',
      label: 'Activity Log',
      children: (
        <ActivityLog mission={missionData} missionRework={MISSION_LIBRARY} />
      ),
    });
  }
  const [addActionItems] = useMutation(ADD_ACTION_ITEMS, {
    client: roadmapApiClient,
    fetchPolicy: 'network-only',
  });
  const [editMission] = useMutation(EDIT_MISSION, {
    client: roadmapApiClient,
  });
  const { refetch: loadActionItems } = useQuery(LOAD_ACTION_ITEMS, {
    client: roadmapApiClient,
    fetchPolicy: 'network-only',
    skip: true,
  });
  const { refetch: refetchRoadmap } = useQuery(QUERY_TEMPLATE_ROADMAP, {
    skip: true,
    client: roadmapApiClient,
  });
  const addToMissionLib = async () => {
    const { data: roadmapData } = await refetchRoadmap();
    const queriedRoadmapId = roadmapData?.templateRoadmap?.id;
    const missions = [
      {
        action: 'add',
        category: missionData?.category,
        subcategory: missionData?.subcategory,
        title: missionData?.title,
        description: missionData?.description,
      },
    ];
    const { data } = await editMission({
      variables: {
        input: {
          roadmapId: queriedRoadmapId,
          userId: missionData?.userId,
          missions,
        },
      },
    });
    const { data: actionItemData } = await loadActionItems({
      missionId: missionData?.id,
    });
    if (data && actionItemData && actionItemData.items) {
      const tasks = actionItemData.items;
      if (tasks && tasks.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const input = tasks.map((task: any) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const inputVariables: any = {
            description: task.description,
            missionId: data.editMissions[0].id,
          };
          if (task.content) {
            inputVariables['content'] = task.content;
          }

          if (task.resources) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            inputVariables['resources'] = task.resources.map(
              (resource: any) => {
                return {
                  title: resource.title,
                  url: resource.url,
                  mediaType: resource.mediaType,
                  orderIndex: resource.orderIndex,
                  type: resource.type,
                };
              },
            );
          }
          return inputVariables;
        });

        await addActionItems({
          variables: {
            input,
          },
        });
      }
    }
    notification.success({
      message: 'Great!',
      description:
        'Your mission has been successfully added to the mission library!',
    });
  };
  return (
    <Drawer
      contentWrapperStyle={{ width: '620px' }}
      bodyStyle={{ padding: '28px 36px' }}
      headerStyle={{ display: 'none' }}
      open={visible}
      onClose={onClose}
    >
      {!missionData ? (
        <LoadingIndicator />
      ) : (
        <>
          <StyledMissionHeader data-testid={'drawer-header'}>
            <MissionStatus
              status={missionData?.status as keyof typeof missionStatusObject}
              onSelectValue={async (value) => {
                await changeMissionStatus({
                  variables: {
                    input: {
                      missionId,
                      status: value,
                    },
                  },
                });
                reloadMission();
                onMissionChange?.('ChangeMissionStatus');
              }}
              editPermitted={editPermitted}
            />

            <StyledMissionHeaderRight>
              <CWEditAuthProvider
                isCWStudent={isCWStudent}
                isMissionCreatedByLoginUser={isMissionCreatedByLoginUser}
              >
                {editPermitted && showRemoveBtn && (
                  <StyledRemoveButton>
                    {MISSION_LIBRARY &&
                    missionData?.category !== 'APPLICATION_COMMON' ? (
                      <OperationButton
                        removeMission={removeMission}
                        addToMissionLib={addToMissionLib}
                      />
                    ) : (
                      <RemoveButton
                        Icon={<StyledMoreHoriz />}
                        onConfirm={() => {
                          onClose();
                          removeMission();
                        }}
                        removeButtonText="Remove a mission"
                        removeModalText={`Are you sure you want to remove this ${
                          MissionSubCategoryMapping[
                            missionData?.subcategory as keyof typeof MissionSubCategoryMapping
                          ]
                            ? MissionSubCategoryMapping[
                                missionData?.subcategory as keyof typeof MissionSubCategoryMapping
                              ]
                            : 'mission'
                        }?`}
                      />
                    )}
                  </StyledRemoveButton>
                )}
              </CWEditAuthProvider>
              <StyledCloseIcon onClick={onClose}>
                <StyledClose />
              </StyledCloseIcon>
            </StyledMissionHeaderRight>
          </StyledMissionHeader>
          <StyledMissionTitleContainer>
            <StyledTextArea>
              <Textarea
                placeholder="Mission Name"
                value={
                  missionData?.title === 'Untitled'
                    ? 'Add a mission title'
                    : missionData.title
                }
                disabled={!editPermitted}
                textStyle={
                  missionData?.title === 'Untitled'
                    ? { color: theme.common.salmon }
                    : {}
                }
                onChangeProps={async (value) => {
                  await changeMissionTitle({
                    variables: {
                      input: {
                        missionId,
                        title: value,
                      },
                    },
                  });
                  reloadMission();
                }}
                autoSize={{
                  minRows: 1,
                  maxRows: 2,
                }}
              />
            </StyledTextArea>
          </StyledMissionTitleContainer>
          {!MISSION_LIBRARY && (
            <StyledCategoryContainer>
              <span>
                {
                  MissionCategoryNameMapping[
                    missionData?.category as keyof typeof MissionCategoryNameMapping
                  ]
                }
              </span>
              <StyledRightOutlined />
              <span>{missionData?.subcategory}</span>
            </StyledCategoryContainer>
          )}

          <StyledRangeDatePickerContainer>
            <CWEditAuthProvider
              isCWStudent={isCWStudent}
              isMissionCreatedByLoginUser={isMissionCreatedByLoginUser}
            >
              {MISSION_LIBRARY && <StyledLabel>Dates</StyledLabel>}
              <RangeDatePicker
                value={[
                  missionData?.startAt
                    ? moment(missionData?.startAt as string)
                    : null,
                  missionData?.dueDate
                    ? moment(missionData?.dueDate as string)
                    : null,
                ]}
                format="MMM DD, YYYY"
                suffixIcon={null}
                onChange={onDateChange}
                separator={<StyledRightOutlined />}
                disabled={
                  datePickerType !== 'default'
                    ? [!editPermitted, true]
                    : !editPermitted
                }
              />
            </CWEditAuthProvider>
            {MISSION_LIBRARY && (
              <div style={{ flex: 1 }}>
                <StyledLabel>Team</StyledLabel>
                <Teams
                  members={missionData?.members as string[]}
                  missionId={missionData?.id as string}
                  editPermitted={editPermitted}
                  studentUserId={missionData?.userId as string}
                />
              </div>
            )}
          </StyledRangeDatePickerContainer>
          <StyledMissionDesc>
            <StyledLabel>Description</StyledLabel>
            <StyledMissionTextareaContainer>
              <StyledTextArea>
                <Textarea
                  placeholder="Enter mission description here"
                  value={missionData?.description}
                  onChangeProps={async (value) => {
                    await changeMissionDescription({
                      variables: {
                        input: {
                          missionId,
                          description: value,
                        },
                      },
                    });
                    reloadMission();
                  }}
                  autoSize={{
                    minRows: 1,
                    maxRows: 8,
                  }}
                  disabled={!editPermitted}
                />
              </StyledTextArea>
            </StyledMissionTextareaContainer>
          </StyledMissionDesc>
          {missionData?.resources && missionData.resources.length > 0 && (
            <CWEditAuthProvider
              isCWStudent={isCWStudent}
              isMissionCreatedByLoginUser={isMissionCreatedByLoginUser}
            >
              <StyledResourcesContainer>
                <StyledLabel>Resources</StyledLabel>
                <StyledResourcesList>
                  {missionData.resources.map((resource, index) => (
                    <MissionResourceLink key={index} resource={resource} />
                  ))}
                </StyledResourcesList>
              </StyledResourcesContainer>
            </CWEditAuthProvider>
          )}
          {!MISSION_LIBRARY && (
            <StyledTeamContainer>
              <StyledLabel>Team</StyledLabel>
              <Teams
                members={missionData?.members as string[]}
                missionId={missionData?.id as string}
                editPermitted={editPermitted}
                studentUserId={missionData?.userId as string}
              />
            </StyledTeamContainer>
          )}
          {!MISSION_LIBRARY ? (
            <>
              <StyledActionItemsContainer>
                {missionData && (
                  <CWEditAuthProvider
                    isCWStudent={isCWStudent}
                    isMissionCreatedByLoginUser={isMissionCreatedByLoginUser}
                  >
                    <ActionItems
                      editPermitted={editPermitted}
                      deletePermitted={deletePermitted}
                      mission={missionData}
                      onMissionChange={onMissionChange}
                      visible={visible}
                    />
                  </CWEditAuthProvider>
                )}
              </StyledActionItemsContainer>
              {REFLECTIONS && (
                <StyledActionItemsContainer>
                  <Reflections
                    editPermitted={editPermitted}
                    missionId={missionData.id}
                  />
                </StyledActionItemsContainer>
              )}
              {canViewInternalNotes && missionData ? (
                <CWEditAuthProvider
                  isCWStudent={isCWStudent}
                  isMissionCreatedByLoginUser={isMissionCreatedByLoginUser}
                >
                  <StyledActionItemsContainer>
                    <InternalNotes
                      studentId={missionData.userId}
                      missionId={missionData.id}
                      editPermitted={editPermitted}
                    />
                  </StyledActionItemsContainer>
                </CWEditAuthProvider>
              ) : null}
              <StyledActionItemsContainer>
                <ActivityLog mission={missionData} />
              </StyledActionItemsContainer>
            </>
          ) : (
            <StyledTabContainer>
              <Tabs defaultActiveKey="1" items={items} />
            </StyledTabContainer>
          )}
        </>
      )}
    </Drawer>
  );
};
