import styled, { css, keyframes } from 'styled-components';
import { Input } from 'antd';
const { Search } = Input;

const tiltleIn = keyframes`
  0% {
    -webkit-transform: translateX(-150px);
            transform: translateX(-150px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
`;

export const DrawerHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const HeaderContainer = styled.div`
  display: flex;
  font-family: 'Montserrat-Bold';
  font-size: 24px;
  line-height: 29px;
  color: black;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 29px;
  border-bottom: 1px solid var(--color-mist);
  padding: 40px;
  background: linear-gradient(90deg, #EBEDFF 0%, #D3D6FF 100%);
`;

export const StyledSearch = styled(Search)`
  &.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    background: transparent;
  }
  &.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
    border: 0px;
    background: transparent;
    & ::hover svg {
      color: var(--color-indigo);
    }
  }
  .ant-input-affix-wrapper{
    padding-right: 0;
  }
`;

export const DrawerContentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0 40px;
`;

export const HideScrollBar = css`
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-track {
    background: white;
  }
  &::-webkit-scrollbar-thumb {
    background: white;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: white;
  }
  &::-webkit-scrollbar-corner {
    background: white;
  }
`;


export const PathfinderGoalList = styled.div`
  display: flex;
  flex-direction: column;
  height: 750px;
  background-color: white;
  padding-top: 10px;
  margin-top: 14px;
  overflow-y: scroll;
  padding-right: 50px;
  border-right: 1px solid var(--color-mist);
  width: 450px;
  /* hide scrollbar */
  ${HideScrollBar}
`;


export const LoadingContainer = styled.div`
`;

export const PathfinderGoalItem = styled.div<{active: boolean}>`
  display: flex;
  width: 100%;
  height: 48px;
  min-height: 48px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  padding-left: 24px;
  padding-right: 18px;
  font-weight: 600;
  font-size: 14px;
  color: var(--color-dark-navy);
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid white;
  border-color: ${(props) => (props.active ? 'var(--color-indigo)' : 'white')};
  background-color: ${(props) => (props.active ? 'var(--color-baby-blue)' : 'var(--color-mist)')};
  &:hover {
    border: 1px solid var(--color-light-purple);
    color: var(--color-light-purple);
  }
  /* div:first-of-type {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 4px;
  } */
  svg:last-child {
    flex: 0 0 20px;
  }
`;

export const GoalTitle = styled.div`
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 4px;
`;

export const GoalTitleRight = styled.div`
  display: flex;
  align-items: center;
`;

export const SharedContainer = styled.div`
  width: 60px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background: #EDFDF4;
  color: #12C39A;
  font-size: 11px;
`;

export const CustomContainer = styled.div`
  width: 60px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background: #FEF9F2;
  color: #FDAA02;
  font-size: 11px;
`;

export const Points = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 8px;
  min-width: 34px;
  justify-content: flex-end;
  & .achieved {
    color: var(--color-dark-navy);
  }
  & .total {
    color: var(--color-cool-grey);
  }
`;

export const CardListContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 750px;
  overflow-y: scroll;
  padding-top: 32px;
  padding-left: 32px;
  /* hide scrollbar */
  ${HideScrollBar}
`;

export const CardHeader = styled.div`
  textarea.ant-input {
    font-family: 'Montserrat-Bold';
    font-size: 20px;
    line-height: 26px;
    max-width: 460px;
    color: var(--color-darkNavy);
  }
`;

export const CardDesc = styled.div`
  textarea.ant-input {
    color: var(--color-stone);
    font-size: 12px;
  }
`;

export const MissionListContainer = styled.div`
  border: 1px solid #E3E7ED;
  background-color: #F4F5FF;
  padding: 16px 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 32px;
`;
export const StyledMissionListHeader = styled.div`
  font-size: 12px;
  color: #A9ACC0;
`;
export const StyledNote = styled.div`
  margin-top: 12px;
  color: #A9ACC0;
`;
