import { useState, useEffect } from 'react';
import { Input } from 'antd';
import { TextAreaProps } from 'antd/es/input';
const { TextArea: AntTextArea } = Input;

export type TextareaProps = {
  name?: string;
  placeholder?: string;
  value?: string;
  onChangeProps?: (value: string) => void;
  rows?: number;
  maxLength?: number;
  maxWarningLength?: number;
  disabled?: boolean;
  enterSubmit?: boolean;
  textStyle?: React.CSSProperties;
} & Omit<TextAreaProps, 'onChange' | 'value'>;

export function Textarea({
  value,
  placeholder = 'Enter',
  onChangeProps,
  autoSize,
  maxWarningLength,
  disabled = false,
  enterSubmit = true,
  textStyle,
  ...rest
}: TextareaProps) {
  const [showValue, setShowValue] = useState('');
  useEffect(() => {
    setShowValue(value as string);
  }, [value]);
  const onSubmit = () => {
    onChangeProps?.(showValue);
  };
  return (
    <>
      <AntTextArea
        value={showValue}
        placeholder={placeholder}
        bordered={false}
        onChange={(e) => {
          const value = e.target.value;
          setShowValue(value);
        }}
        autoSize={autoSize}
        onPressEnter={() => {
          enterSubmit && onSubmit();
        }}
        onBlur={onSubmit}
        disabled={disabled}
        style={textStyle}
        {...rest}
      />
      {maxWarningLength && (
        <div style={{ float: 'right', color: '#A9ACC0' }}>
          {showValue && showValue.length > maxWarningLength ? (
            <span style={{ color: '#ED4B53' }}>
              {showValue ? showValue.length : 0}
            </span>
          ) : (
            <span>{showValue ? showValue.length : 0}</span>
          )}
          /{maxWarningLength}
        </div>
      )}
    </>
  );
}
