import styled from 'styled-components';
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;

export const StyledRangePicker = styled(RangePicker)`
  margin-left: 8px;
  .ant-picker-suffix {
    color: #a9acc0;
  }
  width: 230px;
  padding: 0px !important;
  .ant-picker-input {
    width: 85px;
  }
  .ant-picker-input > input {
    font-size: 12px !important;
  }
  input::placeholder {
    color: #73747d;
  }
  .ant-picker-suffix {
    height: auto;
  }
  .ant-picker-active-bar {
    margin-left: 0px;
  }
`;
