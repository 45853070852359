import { useEffect, useRef, useState } from 'react';
import { UploadProps, notification, UploadFile } from "antd";
import { AddCircle } from '@styled-icons/material';
import sanitize from 'sanitize-filename';
import { FileRefType } from '@crimson-education/common-config';

import {
  ActionItem as ActionItemType,
  MissionDetailObjectType
} from '../../types';
import {
  Container,
  Title,
  StyledOperationButton,
  MissionLibListContainer,
} from './style';
import { StyledPopover } from '../predefined-task-toggle/style';
import { AddFromScratch } from './add-from-scratch';
import { ActionItemRework } from './task-library-action-item';
import {
  Checkbox,
} from './style';
import { TaskLibrary } from '../task-library/task-library';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_ACTION_ITEM, ADD_ACTION_ITEM_RESOURCE, GET_SC_UPLOAD_URL, SAVE_FILE } from '@crimson-education/core-shared-graphql';
import { useApiClient, useUserInfo } from '../../context';
import { useIsStaff } from '../hooks';
export type FileType = Parameters<NonNullable<UploadProps['beforeUpload']>>[0];

export type ActionItemsProps = {
  actionItemsProps: ActionItemType[] | undefined;
  onCheckActionItem: (id: string, checked: boolean) => void;
  onCheckAll: (checked: boolean) => void;
  checkedItemIds: string[];
  from?: 'mission' | 'task';
  editPermitted?: boolean;
  loadActionItems?: () => void;
  mission?: MissionDetailObjectType;
};

export const TaskLibraryActionItems: React.FC<ActionItemsProps> = ({
  actionItemsProps,
  onCheckActionItem,
  onCheckAll,
  checkedItemIds,
  from,
  editPermitted,
  loadActionItems,
  mission
}) => {
  const [actionItems, setActionItems] = useState<ActionItemType[] | undefined>([]);
  const [checkAllItem, setCheckAllItem] = useState(false);
  const [isAddingActionItem, setIsAddingActionItem] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { roadmapApiClient, coreApiClient } = useApiClient();
  const { refetch: getStudentCenterUploadUrl } = useQuery(GET_SC_UPLOAD_URL, {
    client: coreApiClient,
    skip: true,
  });
  const [saveFile] = useMutation(SAVE_FILE, {
    client: coreApiClient,
  });
  useEffect(() => {
    setActionItems(actionItemsProps);
    if (from === 'mission') {
      setCheckAllItem(true);
      onCheckAll(true);
    }
  }, [actionItemsProps]);
  useEffect(() => {
    if (checkedItemIds.length === actionItemsProps?.length) {
      setCheckAllItem(true);
    } else if (checkAllItem){
      setCheckAllItem(false);
    }
  }, [checkedItemIds]);
  const [addActionItem] = useMutation(ADD_ACTION_ITEM, {
    client: roadmapApiClient,
  });
  const [addActionItemResource] = useMutation(ADD_ACTION_ITEM_RESOURCE, {
    client: roadmapApiClient,
  });
  const [taskLibraryVisible, setTaskLibraryVisible] = useState(false);

  const addActionItemFromScratch = async ({ description, dueDate, content, fileList }: {
    description: string,
    dueDate: string,
    content?: string,
    fileList?: UploadFile[]
  }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const input: any = {
      missionId: mission?.id,
      description,
      dueDate: new Date(dueDate),
    };

    if (content) {
      input['content'] = content;
    }
    const { data } = await addActionItem({
      variables: {
        input,
      },
    });
    const actionItemId = data?.addActionItem?.id;

    if (fileList && fileList.length > 0) {
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        if (file.type === 'Link') {
          const variables = {
            actionItemId,
            url: file.url,
            title: file.name,
            type: 'Link',
          }
          await addActionItemResource({
            variables,
          });
        } else {
          const { name, type, size } = file;
          const sanitized = sanitize(name);
          const encoded = encodeURIComponent(sanitized);
          try {
            const formData = new FormData();
            formData.append("files[]", file as FileType);
            const res = await getStudentCenterUploadUrl({ refId: actionItemId, fileName: encoded, contentType: type });
            const { putUrl, key } = res.data.getStudentCenterUploadUrl;
            await fetch(putUrl, {
              method: 'PUT',
              body: formData,
            });
            const result = await saveFile({
              variables: {
                location: key,
                sha512: ' ',
                name: name,
                size: `${size}`,
                type: type,
                refId: actionItemId,
                refType: FileRefType.ACADEMIC,
              },
            });
            if (result?.data?.saveFileUplRef?.id) {
              await addActionItemResource({
                variables: {
                  actionItemId,
                  type: 'Upload',
                  url: result?.data?.saveFileUplRef?.id,
                  title: file.name,
                  mediaType: file.type,
                },
              });
            }

          } catch {
            notification.error({
              message: 'Upload File Error',
            })
          }
        }
      }
    }
    notification.success({
      message: 'Add Successfully',
    })
    loadActionItems?.();
    setIsAddingActionItem(false);
  };

  const user = useUserInfo();
  const isStaff = useIsStaff(user);

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'relative',
        }}
      >
        <Title style={{paddingLeft: 0}}>
          <Checkbox
            checked={checkAllItem}
            onChange={async (e) => {
              const value = e.target.checked;
              onCheckAll(value);
              setCheckAllItem(value);
            }}
          />
          Tasks{' '}
          <span>{`${
            checkedItemIds.length
            ? '(' + checkedItemIds.length + ')'
              : ''
          }`}</span>
        </Title>
        {editPermitted && (
          <StyledPopover
            placement={'bottomRight'}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            getPopupContainer={(triggerNode: any) => triggerNode.parentNode as HTMLElement}
            showArrow={false}
            content={
              <div style={{ color: '#73747D', textAlign: 'right' }}>
                {isStaff && <StyledOperationButton onClick={() => {
                  setTaskLibraryVisible(true)
                }}>Add from task library</StyledOperationButton>}
                <StyledOperationButton onClick={() => {
                  setIsAddingActionItem(true);
                }}>Add from scratch</StyledOperationButton>
              </div>
            }
            trigger={'click'}
          >
            <AddCircle
              style={{
                color: '#464AC9',
                width: 20,
                height: 20,
                cursor: 'pointer',
              }}
            />
          </StyledPopover>
        )}
      </div>
      <MissionLibListContainer>
        {
          isAddingActionItem && <AddFromScratch setIsAddingActionItem={setIsAddingActionItem} from="library" isAddingActionItem={isAddingActionItem} isSubmitting={isSubmitting} editPermitted={editPermitted as boolean} addActionItem={async (...args) => {
            setIsSubmitting(true);
            await addActionItemFromScratch(...args);
            setIsSubmitting(false);
          }} />
        }
        {
          actionItems && actionItems.length > 0 && actionItems?.slice().map((item) => (
            <ActionItemRework
              key={item.id}
              item={item}
              onCheckActionItem={onCheckActionItem}
              checkedProps={checkedItemIds.includes(item.id)}
              editPermitted={editPermitted}
              loadActionItems={loadActionItems}
            />
          ))
        }
      </MissionLibListContainer>
      {taskLibraryVisible && mission && loadActionItems && <TaskLibrary visible={taskLibraryVisible} setDrawerVisible={setTaskLibraryVisible} mission={mission} loadActionItems={loadActionItems} reloadMission={loadActionItems} />}
    </Container>
  );
};
