import { Form, Input, Button } from 'antd';
import { Send } from '@styled-icons/material/Send';
import { useState } from 'react';

const UploadLinkForm = ({
  uploadLink,
}: {
  uploadLink: (resourceCaption: string, link: string) => void;
}) => {
  const [resourceCaption, setResourceCaption] = useState('');
  const [link, setLink] = useState('');
  const submit = () => {
    if (!link || !resourceCaption) {
      return;
    }
    //push this to parent filelist
    uploadLink(resourceCaption, link);
    setResourceCaption('');
    setLink('');
  };
  return (
    <Form layout="vertical" onFinish={submit}>
      <Form.Item label="Caption">
        <Input
          value={resourceCaption}
          placeholder="Add a resource caption"
          onChange={(e) => {
            setResourceCaption(e.target.value);
          }}
        />
      </Form.Item>
      <div style={{ position: 'relative' }}>
        <Form.Item label="URL">
          <Input
            placeholder="Add a link, eg: https://google.com"
            style={{ paddingRight: '28px' }}
            value={link}
            onChange={(e) => {
              setLink(e.target.value);
            }}
          />
        </Form.Item>
        <div
          style={{
            position: 'absolute',
            top: '28px',
            right: '4px',
            zIndex: 999,
          }}
        >
          <Form.Item>
            <Send
              onClick={submit}
              color="#464AC9"
              width="20px"
              opacity={!link || !resourceCaption ? 0.4 : 1}
              style={{ cursor: 'pointer' }}
            />
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};

export default UploadLinkForm;
