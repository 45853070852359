import { MoreLessIcon, StyledOption, StyledSelect } from './style';
import { useState } from 'react';
type DropdownMenuProps = {
  placeholder: string;
  options: { label: string; value: string }[];
  onClickOption: (v: string) => void;
  selectedOptionValue?: string | null;
  customOptionRenderer?: (option: { label: string; value: string }) => JSX.Element;
  optionStyle?: React.CSSProperties;
  selectorStyle?: React.CSSProperties;
};
export const DropdownMenu = ({
  placeholder,
  options,
  onClickOption,
  selectedOptionValue,
  customOptionRenderer,
  optionStyle,
  selectorStyle
}: DropdownMenuProps): JSX.Element => {
  const [visible, setVisible] = useState(false);
  return (
    <StyledSelect
      placeholder={placeholder}
      suffixIcon={<MoreLessIcon $open={visible} />}
      onChange={(e) => onClickOption(e as string)}
      onDropdownVisibleChange={setVisible}
      autoClearSearchValue={false}
      dropdownStyle={{ minWidth: 150 }}
      allowClear
      value={selectedOptionValue}
      getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
      style={selectorStyle}
    >
      {options.map((item) => {
        return (
          <StyledOption key={item.value} value={item.value} style={optionStyle}>
            {customOptionRenderer ? customOptionRenderer(item) : item.label}
          </StyledOption>
        );
      })}
    </StyledSelect>
  );
};
