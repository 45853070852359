import {
  StyledModalTitle,
  StyledModalContainer,
  SubmitButton,
  CancelButton,
} from '../remove-button/style';
import {
  StyledModalDesc,
  StyledButtonGroup,
  StyledModalDescContainer,
} from './style';
export const ConfirmModal = ({
  onClose,
  onConfirm,
  modalText,
  modalDesc = 'All the related data will be removed once done.',
  title = 'Are you sure',
}: {
  onClose: () => void;
  onConfirm: () => void;
  modalText: string;
  modalDesc?: string | null | React.ReactNode;
  title?: string;
}): JSX.Element => {
  return (
    <StyledModalContainer>
      <StyledModalTitle>{title}</StyledModalTitle>
      <StyledModalDesc>
        <div>{modalText}</div>
        {modalDesc && <StyledModalDescContainer>{modalDesc}</StyledModalDescContainer>}
      </StyledModalDesc>
      <StyledButtonGroup>
        <CancelButton width={150} onClick={onClose}>
          Cancel
        </CancelButton>
        <SubmitButton width={150} type="primary" htmlType="submit" onClick={onConfirm}>
          Confirm
        </SubmitButton>
      </StyledButtonGroup>
    </StyledModalContainer>
  );
};
