import { HighlightedSpan } from './style';
import _ from 'lodash';
import { TemplateVariables, Templates } from './type';
import { DateTime } from 'luxon';

const displayName = (user: { firstName: string; lastName: string }): string => {
  return user ? `${user.firstName} ${user.lastName}` : '[null]';
};

const displayDescription = (desc?: string): string => {
  return desc && desc.length > 100 ? `${desc.slice(0, 100)}...` : desc || '';
};

const displayBadge = (badgeLevel?: string): string => {
  if (!badgeLevel) return '';
  badgeLevel = badgeLevel.toLowerCase();
  return badgeLevel[0].toUpperCase() + badgeLevel.substring(1);
};

const displayDate = (date?: string): string => {
  if (date) {
    try {
      return DateTime.fromISO(date).toFormat('yyyy-MM-dd');
    } catch (e) {
      return `Date format error: [${date}]`;
    }
  }
  return '[null]';
};

export const displayCategory = (category?: string): string => {
  const categories: { [key: string]: string } = {
    ACADEMICS: 'Academics',
    ACTIVITIES: 'Activities',
    COMPETITIONS_HONORS: 'Honors',
    PERSONAL_DEVELOPMENT: 'Personal Development',
    CAREERS: 'Majors & Careers',
    TEST: 'Testing',
  };
  return category ? categories[category] || '[null]' : '[null]';
};

export const displayStatus = (status?: string): string => {
  const statuses: { [key: string]: string } = {
    PLANNED: 'Planned',
    IN_PROGRESS: 'In Progress',
    DONE: 'Completed',
    EXPIRED: 'Expired',
    POSTPONED: 'Postponed',
  };
  return status ? statuses[status] || '[null]' : '[null]';
};

export const rbacCanAccess = (
  templates: Templates,
  { roles, code }: { roles?: string[]; code: string },
): boolean => {
  const template = templates[code];

  if (template.rbac.includes('*')) {
    return true;
  }

  return (roles || []).some((role) =>
    template.rbac.includes(role.toLowerCase()),
  );
};

export const hasTemplate = (
  templates: Templates,
  { code }: { code: string },
): boolean => {
  return !!templates[code];
};

export const applyTemplate = (
  templates: Templates,
  { code, values }: { code: string; values: unknown },
): JSX.Element => {
  const template = templates[code];
  if (typeof values === 'string') {
    values = JSON.parse(values) as TemplateVariables;
  }

  if (template) {
    return template.render(values as TemplateVariables);
  }

  return <span>code unsupported: {code}</span>;
};

export const templates: Templates = {
  // Mission
  ACTIVITY_TOPIC_MISSION_CREATE: {
    rbac: ['*'],
    render: ({ operator }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>
          &nbsp;created this mission
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_MISSION_CHANGE_STATUS: {
    rbac: ['*'],
    render: ({ operator, oldState, newState }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>
          &nbsp;changed the status from&nbsp;
          <HighlightedSpan>
            &quot;{displayStatus(oldState)}&quot;
          </HighlightedSpan>
          &nbsp;to&nbsp;
          <HighlightedSpan>
            &quot;{displayStatus(newState)}&quot;
          </HighlightedSpan>
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_MISSION_ADD_STARTDATE: {
    rbac: ['*'],
    render: ({ operator, mission }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>
          &nbsp;set the start date to&nbsp;
          <HighlightedSpan>
            &quot;{displayDate(mission?.startAt)}&quot;
          </HighlightedSpan>
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_MISSION_ADD_DUEDATE: {
    rbac: ['*'],
    render: ({ operator, mission }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;set
          the due date to&nbsp;
          <HighlightedSpan>
            &quot;{displayDate(mission?.dueDate)}&quot;
          </HighlightedSpan>
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_MISSION_ADD_DESCRIPTION: {
    rbac: ['*'],
    render: ({ operator }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;added
          a description
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_MISSION_CHANGE_STARTDATE: {
    rbac: ['*'],
    render: ({ operator, oldState, newState }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>
          &nbsp;changed the start date from&nbsp;
          <HighlightedSpan>&quot;{displayDate(oldState)}&quot;</HighlightedSpan>
          &nbsp;to&nbsp;
          <HighlightedSpan>&quot;{displayDate(newState)}&quot;</HighlightedSpan>
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_MISSION_CHANGE_DUEDATE: {
    rbac: ['*'],
    render: ({ operator, oldState, newState }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>
          &nbsp;changed the due date from&nbsp;
          <HighlightedSpan>&quot;{displayDate(oldState)}&quot;</HighlightedSpan>
          &nbsp;to&nbsp;
          <HighlightedSpan>&quot;{displayDate(newState)}&quot;</HighlightedSpan>
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_MISSION_CHANGE_DESCRIPTION: {
    rbac: ['*'],
    render: ({ operator, oldState, newState }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>
          &nbsp;changed the description from&nbsp;
          <HighlightedSpan>
            &quot;{displayDescription(oldState)}&quot;
          </HighlightedSpan>
          &nbsp;to&nbsp;
          <HighlightedSpan>
            &quot;{displayDescription(newState)}&quot;
          </HighlightedSpan>
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_MISSION_CHANGE_TITLE: {
    rbac: ['*'],
    render: ({ operator, oldState, newState }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>
          &nbsp;changed the title from &nbsp;
          <HighlightedSpan>&quot;{oldState}&quot;</HighlightedSpan>
          &nbsp;to&nbsp;
          <HighlightedSpan>&quot;{newState}&quot;</HighlightedSpan>
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_MISSION_COMPLETE: {
    rbac: ['*'],
    render: ({ operator }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;marked
          this mission as Completed
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_MISSION_REMOVE: {
    rbac: ['*'],
    render: ({ operator }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>
          &nbsp;removed this mission
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_MISSION_REMOVE_DESCRIPTION: {
    rbac: ['*'],
    render: ({ operator }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>
          &nbsp;removed the description
        </span>
      );
    },
  },
  // Task
  ACTIVITY_TOPIC_MISSION_ADD_ACTION_ITEM: {
    rbac: ['*'],
    render: ({ operator, actionItem }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;added
          a task&nbsp;
          <HighlightedSpan>
            &quot;{actionItem?.description}&quot;
          </HighlightedSpan>
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_MISSION_ADD_ACTION_ITEM_DUEDATE: {
    rbac: ['*'],
    render: ({ operator, actionItem }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;set
          the due date of the task&nbsp;
          <HighlightedSpan>
            &quot;{actionItem?.description}&quot;
          </HighlightedSpan>
          &nbsp;to&nbsp;
          <HighlightedSpan>
            &quot;{displayDate(actionItem?.dueDate)}&quot;
          </HighlightedSpan>
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_MISSION_CHANGE_ACTION_ITEM_DUEDATE: {
    rbac: ['*'],
    render: ({
      operator,
      actionItem,
      oldState,
      newState,
    }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>
          &nbsp;changed the due date of the task &nbsp;
          <HighlightedSpan>
            &quot;{actionItem?.description}&quot;
          </HighlightedSpan>
          &nbsp;from&nbsp;
          <HighlightedSpan>&quot;{displayDate(oldState)}&quot;</HighlightedSpan>
          &nbsp;to&nbsp;
          <HighlightedSpan>&quot;{displayDate(newState)}&quot;</HighlightedSpan>
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_MISSION_CHANGE_ACTION_ITEM: {
    rbac: ['*'],
    render: ({ operator, oldState, newState }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>
          &nbsp;changed the task from&nbsp;
          <HighlightedSpan>&quot;{oldState}&quot;</HighlightedSpan>
          &nbsp;to&nbsp;
          <HighlightedSpan>&quot;{newState}&quot;</HighlightedSpan>
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_MISSION_COMPLETE_ACTION_ITEM: {
    rbac: ['*'],
    render: ({ operator, actionItem }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;marked
          the task&nbsp;
          <HighlightedSpan>
            &quot;{actionItem?.description}&quot;
          </HighlightedSpan>
          &nbsp;as Completed
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_MISSION_REMOVE_ACTION_ITEM: {
    rbac: ['*'],
    render: ({ operator, actionItem }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>
          &nbsp;removed the task of&nbsp;
          <HighlightedSpan>
            &quot;{actionItem?.description}&quot;
          </HighlightedSpan>
        </span>
      );
    },
  },
  // Team Member
  ACTIVITY_TOPIC_MISSION_ADD_MEMBER: {
    rbac: ['*'],
    render: ({ operator, member }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;added
          team member&nbsp;
          <HighlightedSpan>{displayName(member)}</HighlightedSpan>
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_MISSION_REMOVE_MEMBER: {
    rbac: ['*'],
    render: ({ operator, member }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>
          &nbsp;removed team member&nbsp;
          <HighlightedSpan>{displayName(member)}</HighlightedSpan>
        </span>
      );
    },
  },
  // Internal Note
  MISSION_INTERNAL_NOTE_CREATE: {
    rbac: ['strategist', 'tutor', 'case_manager', 'super_admin'],
    render: ({ operator }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;added
          an internal note
        </span>
      );
    },
  },
  MISSION_INTERNAL_NOTE_DELETE: {
    rbac: ['strategist', 'tutor', 'case_manager', 'super_admin'],
    render: ({ operator }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>
          &nbsp;removed an internal note
        </span>
      );
    },
  },
  // Honors & Compettions
  ACTIVITY_TOPIC_HONOR_UPDATED: {
    rbac: ['*'],
    render: ({ operator, change }: TemplateVariables) => {
      // Have a mapping of Honor Fields to log fillers here...
      let value = change.value;
      if (Array.isArray(value)) value = value.join(', ');
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp; set
          the value of {_.startCase(change.key)} to &nbsp;
          <HighlightedSpan>{value}</HighlightedSpan>
        </span>
      );
    },
  },
  // Reflections
  ACTICITY_TOPIC_REFLECTION_CREATED: {
    rbac: ['*'],
    render: ({ operator }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;added
          a reflection
        </span>
      );
    },
  },
  ACTICITY_TOPIC_REFLECTION_DELETED: {
    rbac: ['*'],
    render: ({ operator }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>
          &nbsp;removed a reflection
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_REFLECTION_COMPLETED: {
    rbac: ['*'],
    render: ({ operator }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;marked
          the reflection as Completed
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_REFLECTION_ADD_DUEDATE: {
    rbac: ['*'],
    render: ({ operator, reflection }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>&nbsp;set
          the due date of the reflection to&nbsp;
          <HighlightedSpan>
            &quot;{displayDate(reflection?.dueDate)}&quot;
          </HighlightedSpan>
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_REFLECTION_CHANGE_DUEDATE: {
    rbac: ['*'],
    render: ({ operator, oldState, newState }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>
          &nbsp;changed the due date of the reflection from&nbsp;
          <HighlightedSpan>&quot;{displayDate(oldState)}&quot;</HighlightedSpan>
          &nbsp;to&nbsp;
          <HighlightedSpan>&quot;{displayDate(newState)}&quot;</HighlightedSpan>
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_BADGE_CREATED: {
    rbac: ['*'],
    render: ({ operator, newState }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>
          &nbsp;assigned a{' '}
          <HighlightedSpan>{displayBadge(newState)}</HighlightedSpan> badge for
          this mission.
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_BADGE_DELETED: {
    rbac: ['*'],
    render: ({ operator }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>
          &nbsp;removed badge.
        </span>
      );
    },
  },
  ACTIVITY_TOPIC_BADGE_CHANGE: {
    rbac: ['*'],
    render: ({ operator, newState, oldState }: TemplateVariables) => {
      return (
        <span>
          <HighlightedSpan>{displayName(operator)}</HighlightedSpan>
          &nbsp;changed badge from{' '}
          <HighlightedSpan>{displayBadge(oldState)}</HighlightedSpan> to{' '}
          <HighlightedSpan>{displayBadge(newState)}</HighlightedSpan>.
        </span>
      );
    },
  },
};
