import roles from '@crimson-education/common-config/lib/authorization/legacy/roles';
import { useMemo } from 'react';
import { User } from '../../types';

export const useCanAccessInternalNotes = <U extends Pick<User, 'roles'>>(
  user?: U,
): boolean => {
  return useMemo(() => {
    const ACL = [
      roles.CASE_MANAGER,
      roles.TUTOR,
      roles.SUPER_ADMIN,
      roles.STRATEGIST,
      roles.ACADEMIC_ADVISOR,
    ];
    if (!user?.roles || user.roles.length < 1) return false;
    return user?.roles.filter(({ roleId }) => ACL.includes(roleId))?.length > 0;
  }, [user?.roles]);
};

export function replaceDateInTitle(title: string, date: string): string {
  const pattern = /on\s+(?!.*on).*$/i; // Matches the last "on" in the string
  const match = title.match(pattern); // Finds the last "on" in the title
  if (match) {
    return title.replace(match[0], `on ${date}`); // Replaces the matched text with the new date value
  }
  // If no match is found, return the original title string
  return title;
}
