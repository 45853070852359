import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { StyledRangePicker } from './style';
import { RangeValue } from 'rc-picker/lib/interface';
import { DateRangeIcon } from '../icons';
import { Popover } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

type Props = {
  value?: RangeValue<moment.Moment> | undefined;
  onChange?: ((values: RangeValue<moment.Moment>) => void) | undefined;
  format?: string;
  suffixIcon?: React.ReactNode;
  separator?: React.ReactNode;
  allowClear?: boolean;
  disabled?: boolean | [boolean, boolean];
  children?: React.ReactNode;
  style?: React.CSSProperties;
};
export /* @__PURE__*/ const RangeDatePicker = ({
  value,
  onChange,
  disabled = false,
  children,
  style,
  ...rest
}: Props): JSX.Element => {
  const [showValue, setShowValue] = useState<
    RangeValue<moment.Moment> | undefined
  >();
  useEffect(() => {
    if (value) {
      setShowValue(value);
    }
  }, [value]);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        alignItems: 'center',
        ...style,
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <DateRangeIcon color="#A9ACC0" width={22} height={22} />
      {children}
      <StyledRangePicker
        bordered={false}
        disabled={disabled}
        separator={null}
        allowClear={false}
        value={showValue}
        getPopupContainer={(target) => target.parentElement as HTMLElement}
        onChange={(value) => {
          setShowValue(value);
        }}
        suffixIcon={null}
        onOpenChange={(open) => {
          if (!open) {
            if (showValue) {
              onChange?.(showValue);
            }
          }
        }}
        {...rest}
      />
      {disabled &&
        Array.isArray(disabled) &&
        disabled.length === 2 &&
        disabled[0] === false &&
        disabled[1] === true && (
          <Popover
            placement="bottom"
            title={''}
            content={
              'Need to reschedule? Visit Testing to select a new official date.'
            }
            trigger="hover"
            getPopupContainer={(trigger) =>
              trigger.parentElement as HTMLElement
            }
          >
            <QuestionCircleOutlined
              style={{
                color: '#A9ACC0',
                marginLeft: '-24px',
                marginTop: '-1px',
                zIndex: 999,
              }}
            />
          </Popover>
        )}
    </div>
  );
};
