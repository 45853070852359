import { createGlobalStyle } from 'styled-components';

const convertToRGB = (hexString: string): string => {
  const hex = hexString.replace('#', '');
  if (hex.length !== 6) {
    throw new Error('Only six-digit hex colors are allowed.');
  }

  const aRgbHex = hex.match(/.{1,2}/g);
  if (aRgbHex && aRgbHex?.length < 3) {
    throw new Error('convert failed.');
  } else {
    const result = aRgbHex as unknown as string[];
    return `${parseInt(result[0], 16)}, ${parseInt(result[1], 16)}, ${parseInt(
      result[2],
      16
    )}`;
  }
};
export const theme = {
  breakPoints: {
    sm: '1440px',
    xm: '768px',
    xs: '375px',
  },
  common: {
    darkNavy: '#1d1e2b',
    stone: '#73747d',
    coolGrey: '#a9acc0',
    paleGrey: '#e3e7ed',
    mist: '#f3f6fa',
    indigo: '#464ac9',
    salmon: '#ed4b53',
    orange: '#ff764c',
    yellow: '#fdaa02',
    lightGray: '#f6f7fa',
    cyan: '#12c39a',
    babyBlue: '#f4f5ff',
  },
  avatar: {
    avatar0: '#ca0915',
    avatar1: '#ff5f00',
    avatar2: '#cda132',
    avatar3: '#b5ba1b',
    avatar4: '#64a324',
    avatar5: '#157112',
    avatar6: '#17b0a0',
    avatar7: '#1271ac',
    avatar8: '#1e4c77',
    avatar9: '#1b2387',
    avatar10: '#9b19ff',
    avatar11: '#620ba6',
    avatar12: '#d30f8e',
    avatar13: '#860759',
  },
};

export type ThemeType = typeof theme;

// Add new color here
export enum CR_COLORS {
  LIGHT_PURPLE = '#464AC9',
  FONT_BLACK = '#1F2937',
  BACKGROUND = '#e5e5e5',
  DARK_NAVY = '#1d1e2b',
  STONE = '#73747d',
  LIGHT_INDIGO = '#4044b8',
  INDIGO = '#464ac9',
  SALMON = '#ed4b53',
  COOL_GREY = '#a9acc0',
  PALE_GREY = '#e3e7ed',
  GREEN = '#12c39a',
  NEON_GREEN = '#31e886',
  OARNGE = '#ff764c',
  YELLOW = '#fdaa02',
  MIST = '#f3f6fa',
  BABY_PINK = '#fff2ef',
  BABY_BLUE = '#f4f5ff',
  BABY_GREEN = '#edfdf4',
  PURPLE = '#6C63FF',
  BLUE = '#3B86FE',
  LIGHT_RED = '#F4948D',
  LIGHT_BLUE = '#819FEE',
  LIGHT_DARK_BLUE = '#5973B9',
  SKY_BLUE = '#4EBBFE',
  HOVER_GREY = '#f9f9ff',
  PLACE_HOLDER_GREY = '#bfbfbf',
  BAR_ORANGE = '#F3998F',
  BAR_PURPLE = '#6C63FF',
  BAR_LIGHT_PURPLE = '#6c63ff66',
  BAR_GREEN = '#35CBA8',
  BAR_BLUE = '#3B86FE',
  BAR_LIGHT_BLUE = '#3b86fe66',
  BAR_YELLOW = '#F6AF46',
}

enum FONT {
  Montserrat = 'Montserrat',
  Montserrat_Bold = 'Montserrat-Bold',
  Montserrat_SemiBold = 'Montserrat-SemiBold',
}

enum FONT_SIZE {
  XSMALL = '10px',
  SMALL = '12px',
}

enum PADDINGS {
  BUTTON = '7px 28px',
  PAGE_CONTENT_LEFT = '68px',
  PAGE_CONTENT_RIGHT = '60px',
}

enum UNIT_WIDTH_HEIGHT {
  ONE_EM = '1em',
}

enum BORDERS {
  DEFAULT = '2px solid',
}

enum SHADOWS {
  DEFAULT = '0px 3px 6px',
}

// Use css variables to style your component eg: const Container = styled.div`color: var(theme-color-darknavy);`
export const GlobalStyle = createGlobalStyle`
  .pac-container {
    border-radius: 0 !important;
    box-shadow: none !important;
    border: 1px solid var(--color-pale-grey) !important;
    transform: translateY(2px) translateX(16px) scaleX(1.1);
    width: 320px !important;
    span {
      font-family: 'Montserrat' !important;
      font-size: 0.9rem !important;
      color: var(--color-stone) !important;
      cursor: pointer !important;
    }
  }
  .curriculum_list_tag_option .ant-select-item-option-state {
    display: none;
  }
  :root {
    a, a:link, a:visited, a:focus, a:hover, a:active {
      color: ${CR_COLORS.INDIGO};
      text-decoration: none; 
    }
    /* color variables */
    --color-font-black: ${CR_COLORS.FONT_BLACK};
    --color-background: ${CR_COLORS.BACKGROUND};
    --color-dark-navy: ${CR_COLORS.DARK_NAVY};
    --color-stone: ${CR_COLORS.STONE};
    --color-indigo: ${CR_COLORS.INDIGO};
    --color-salmon: ${CR_COLORS.SALMON};
    --color-cool-grey: ${CR_COLORS.COOL_GREY};
    --color-pale-grey: ${CR_COLORS.PALE_GREY};
    --color-green: ${CR_COLORS.GREEN};
    --color-neon-green: ${CR_COLORS.NEON_GREEN};
    --color-orange: ${CR_COLORS.OARNGE};
    --color-yellow: ${CR_COLORS.YELLOW};
    --color-mist: ${CR_COLORS.MIST};
    --color-baby-pink: ${CR_COLORS.BABY_PINK};
    --color-baby-blue: ${CR_COLORS.BABY_BLUE};
    --color-baby-green: ${CR_COLORS.BABY_GREEN};
    --color-light-indigo: ${CR_COLORS.LIGHT_INDIGO};
    --color-purple: ${CR_COLORS.PURPLE};
    --color-light-purple: ${CR_COLORS.LIGHT_PURPLE};
    --color-blue: ${CR_COLORS.BLUE};
    --color-light-red: ${CR_COLORS.LIGHT_RED};
    --color-light-blue: ${CR_COLORS.LIGHT_BLUE};
    --color-light-dark-blue: ${CR_COLORS.LIGHT_DARK_BLUE};
    --color-sky-blue: ${CR_COLORS.SKY_BLUE};
    --color-hover-grey: ${CR_COLORS.HOVER_GREY};
    --color-placeholder-grey: ${CR_COLORS.PLACE_HOLDER_GREY};
    --color-bar-orange: ${CR_COLORS.BAR_ORANGE};
    --color-bar-purple: ${CR_COLORS.BAR_PURPLE};
    --color-bar-light-purple: ${CR_COLORS.BAR_LIGHT_PURPLE};
    --color-bar-green: ${CR_COLORS.BAR_GREEN};
    --color-bar-blue: ${CR_COLORS.BAR_BLUE};
    --color-bar-light-blue: ${CR_COLORS.BAR_LIGHT_BLUE};
    --color-bar-yellow: ${CR_COLORS.BAR_YELLOW};
    /* color-varibles-rgb-version: this can help you modified the color with opacity */
    --rgb-color-light-purple: ${convertToRGB(CR_COLORS.LIGHT_PURPLE)};
    --rgb-color-placeholder-grey: ${convertToRGB(CR_COLORS.PLACE_HOLDER_GREY)};
    --rgb-color-hover-grey: ${convertToRGB(CR_COLORS.HOVER_GREY)};
    --rgb-color-font-black: ${convertToRGB(CR_COLORS.FONT_BLACK)};
    --rgb-color-background: ${convertToRGB(CR_COLORS.BACKGROUND)};
    --rgb-color-dark-navy: ${convertToRGB(CR_COLORS.DARK_NAVY)};
    --rgb-color-stone: ${convertToRGB(CR_COLORS.STONE)};
    --rgb-color-indigo: ${convertToRGB(CR_COLORS.INDIGO)};
    --rgb-color-salmon: ${convertToRGB(CR_COLORS.SALMON)};
    --rgb-color-cool-grey: ${convertToRGB(CR_COLORS.COOL_GREY)};
    --rgb-color-pale-grey: ${convertToRGB(CR_COLORS.PALE_GREY)};
    --rgb-color-green: ${convertToRGB(CR_COLORS.GREEN)};
    --rgb-color-neon-green: ${convertToRGB(CR_COLORS.NEON_GREEN)};
    --rgb-color-orange: ${convertToRGB(CR_COLORS.OARNGE)};
    --rgb-color-yellow: ${convertToRGB(CR_COLORS.YELLOW)};
    --rgb-color-mist: ${convertToRGB(CR_COLORS.MIST)};
    --rgb-color-baby-pink: ${convertToRGB(CR_COLORS.BABY_PINK)};
    --rgb-color-baby-blue: ${convertToRGB(CR_COLORS.BABY_BLUE)};
    --rgb-color-baby-green: ${convertToRGB(CR_COLORS.BABY_GREEN)};
    --rgb-color-light-indigo: ${convertToRGB(CR_COLORS.LIGHT_INDIGO)};
    --rgb-color-purple: ${convertToRGB(CR_COLORS.PURPLE)};
    --rgb-color-blue: ${convertToRGB(CR_COLORS.BLUE)};
    --rgb-color-light-red: ${convertToRGB(CR_COLORS.LIGHT_RED)};
    --rgb-color-light-blue: ${convertToRGB(CR_COLORS.LIGHT_BLUE)};
    --rgb-color-light-dark-blue: ${convertToRGB(CR_COLORS.LIGHT_DARK_BLUE)};
    --rgb-color-sky-blue: ${convertToRGB(CR_COLORS.SKY_BLUE)};
    /* padding */
    --padding-button: ${PADDINGS.BUTTON};
    --padding-page-content-left: ${PADDINGS.PAGE_CONTENT_LEFT};
    --padding-page-content-right: ${PADDINGS.PAGE_CONTENT_RIGHT};
    /* PAGE_CONTENT_LEFT */
    /* border */
    --border-nocolor: ${BORDERS.DEFAULT};
    --border-button: ${BORDERS.DEFAULT} var(--color-indigo);
    /* box-shadow */
    --shadow-nocolor: ${SHADOWS.DEFAULT};
    --shadow-button: ${SHADOWS.DEFAULT} var(--color-light-indigo);
    :focus-visible {
      outline: 0px;
      outline-color: transparent;
      outline-width: 0px;
    }
    /* width & height */
    --unit-one-em: ${UNIT_WIDTH_HEIGHT.ONE_EM};
    /* font size */
    --font-size-xs: ${FONT_SIZE.XSMALL};
    --font-size-small: ${FONT_SIZE.SMALL};
  }
  body {
    font-family: ${FONT.Montserrat}
  }
`;
