import styled from 'styled-components';
import { motion } from 'framer-motion';
import { DatePicker } from 'antd';

export const TaskContainer = styled.div`
  margin-top: 30px;
  max-height: 574px;
  min-height: 100px;
  overflow: auto;
`;

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  margin: 40px 50px 60px;
  box-sizing: border-box;
  flex: 1;
  background: #ffffff;
`;

export const Title = styled(motion.div)`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #1d1e2b;
  text-align: center;
`;

export const SelectorContainer = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 24px;
`;

export const StyledButtonContainer = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
`;

export const StyledTitle = styled.div`
 text-align: center;
 margin-top: 12px;
`

export const StyledTaskContainer = styled.div`
  margin-top: 32px;
`;

export const StyledSectionTitle = styled.div`
  font-family: 'Montserrat-Bold';
  margin-bottom: 12px;
`;

export const StyleIndigoDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #464AC9;
`

export const StyledTaskItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
`;

export const StyledContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StyledAddTaskButtonContainer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  gap: 8px;
`;

export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledTaskItemContent = styled.div`
  width: 368px;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TaskDrawerContentContainer = styled.div`
  padding: 25px 40px;
`;

export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  font-family:'Montserrat-Bold';
`;

export const StyledDatePicker = styled(DatePicker)`
  .ant-picker-input > input:placeholder-shown {
    text-align: right;
  }
  .ant-picker-input > input {
    text-align: right;
  }
`;
