import { useContext } from 'react';
import { ApiClientContext } from './apiClientContext';
import { getEnhancer } from './enhancer';
import { getProvider } from './provider';
import { UserInfoContext } from './userInfoContext';
import { FeatureFlagContext } from './featureFlagContext';

type Enhancer = <P extends JSX.IntrinsicAttributes>(
  Comp: React.ComponentType<P>,
) => React.ComponentType<P>;

const useContextCheck = <C,>(ctx: React.Context<C>) => {
  const context = useContext(ctx);
  if (!context) {
    throw new Error(
      `useContext ${ctx.displayName} must be used within a ${ctx.displayName}Provider`,
    );
  }
  return context;
};

export /* @__PURE__*/ const composeEnhancers =
  (...enhancers: Enhancer[]) =>
  <P extends JSX.IntrinsicAttributes>(
    Comp: React.ComponentType<P>,
  ): React.ComponentType<P> =>
    enhancers.reduce(
      (acc, enhancer) => enhancer(acc),
      Comp,
    ) as React.ComponentType<P>;

export /* @__PURE__*/ const ApiClientProvider = getProvider(ApiClientContext);
export /* @__PURE__*/ const apiClientEnhancer = getEnhancer(ApiClientContext);
export /* @__PURE__*/ const useApiClient = () =>
  useContextCheck(ApiClientContext);
export type { /* @__PURE__*/ ApiClientContextType } from './apiClientContext';

export /* @__PURE__*/ const UserInfoProvider = getProvider(UserInfoContext);
export /* @__PURE__*/ const userInfoEnhancer = getEnhancer(UserInfoContext);
export /* @__PURE__*/ const useUserInfo = () =>
  useContextCheck(UserInfoContext);
export type { /* @__PURE__*/ UserInfoContextType } from './userInfoContext';

export /* @__PURE__*/ const FeatureFlagProvider =
  getProvider(FeatureFlagContext);
export type {
  /* @__PURE__*/ FeatureFlagContextType,
} from './featureFlagContext';
export /* @__PURE__*/ const useFeatureFlag = (flagName: string) => {
  const ctx = useContextCheck(FeatureFlagContext);
  return ctx.featureFlags?.[flagName] ?? false;
};
