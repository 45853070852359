import styled from 'styled-components';
import { StyledButtonFilled, StyledButtonOutlined } from '../buttons';

export const StyledContainer = styled.div`
  cursor: pointer;
  display: inline-block;
  position: relative;
`;
export const StyledPopover = styled.div`
  cursor: pointer;
  background: #f3f6fa; // theme color: mist
  border-radius: 4px;
  color: #73747d; // theme color: stone
  width: max-content;
`;

export const StyledModalTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
`;

export const StyledModalDesc = styled.div`
  margin-top: 40px;
  > div:first-child {
    margin-bottom: 20px;
  }
`;

export const StyledButtonGroup = styled.div`
  margin-top: 60px;
  display: flex;
  margin-bottom: 30px;
  flex-direction: row;
  flex-wrap: no-wrap;
  justify-content: space-evenly;
  align-items: center;
`;

export const StyledModalContainer = styled.div`
  text-align: center;
`;

export const CancelButton = styled(StyledButtonOutlined)`
  padding: 20px;
`;
export const SubmitButton = styled(StyledButtonFilled)`
  padding: 20px;
`;
