import { useState, useEffect } from 'react';
import { User } from '../../../types';
import { Avatar } from '../../avatar/avatar';
import { roleTypes } from '@crimson-education/common-config';
import { Tooltip } from 'antd';
import {
  StyledUserAddOutlined,
  StyledCheckbox,
  StyledMemberContainer,
  StyledTeamsContainer,
  StyledAddMemberNumberIcon,
  StyledAddMemberIcon,
  StyledTeamsMemberContainer,
  StyledTeamsProfile,
  StyledTeamsDesc,
  StyledTeamsRole,
  StyledTeamTitle,
  StyledAntDropdown,
  StyledOneLine,
} from './style';
type Props = {
  teamMembers: { assigned: boolean; user: User }[];
  assignTeamMember: (user: User) => void;
  unassignTeamMember: (user: User) => void;
  editPermitted: boolean;
};

const SCTeamItem = ({
  assigned,
  onChange,
  user,
}: {
  assigned: boolean;
  onChange: (checked: boolean) => void;
  user: User;
}) => {
  const { profileImageUrl, firstName, lastName, userId, roles, tutorInfo } = user;
  const primaryRoleName =
    roles.filter(
      (role: {
        isPrimary: boolean;
        role: {
          name: string;
        };
      }) => role.isPrimary === true,
    )?.[0]?.role.name || roles[0].role.name;
  const [checked, setChecked] = useState(assigned);
  return (
    <StyledTeamsMemberContainer
      onClick={(e) => {
        e.stopPropagation();
        setChecked(!checked);
        onChange(!checked);
      }}
    >
      <StyledCheckbox checked={checked} />
      <StyledTeamsProfile>
        <Avatar
          key={userId}
          size={36}
          image={profileImageUrl}
          firstName={firstName}
          lastName={lastName}
          userId={userId}
        />
        <StyledTeamsDesc>
          <div>
            {firstName} {lastName}
          </div>
          <StyledTeamsRole>{primaryRoleName}</StyledTeamsRole>
          <Tooltip
            title={tutorInfo?.contractSubjects?.map((list: { name: string }, index) => {
              return (
                <div key={index}>
                  {list.name}
                  {index + 1 === tutorInfo?.contractSubjects?.length ? '' : ','}
                </div>
              );
            })}
          >
            <StyledOneLine>
              {tutorInfo?.contractSubjects?.map((list: { name: string }) => list.name).join(',')}
            </StyledOneLine>
          </Tooltip>
        </StyledTeamsDesc>
      </StyledTeamsProfile>
    </StyledTeamsMemberContainer>
  );
};
const TeamsLine = ({
  teamMembers,
  assignTeamMember,
  unassignTeamMember,
  editPermitted,
}: Props): JSX.Element => {
  const [members, setMembers] = useState<User[]>([]);
  useEffect(() => {
    if (teamMembers && teamMembers.length > 0) {
      const members: User[] = [];
      teamMembers.forEach(({ assigned, user }) => {
        if (assigned === true) {
          members.push(user);
        }
      });
      setMembers(members);
    }
  }, [teamMembers]);

  const sortTeamMembers =
    () =>
    (
      x1: {
        assigned: boolean;
        user: { roles: { roleId: string; isPrimary: boolean }[] };
      },
      x2: {
        assigned: boolean;
        user: { roles: { roleId: string; isPrimary: boolean }[] };
      },
    ): number => {
      const primaryRole1 = x1.user.roles.filter((r) => r.isPrimary);
      const primaryRole2 = x2.user.roles.filter((r) => r.isPrimary);
      const role1 = (primaryRole1.length > 0 && primaryRole1[0].roleId) || x1.user.roles[0].roleId;
      const role2 = (primaryRole2.length > 0 && primaryRole2[0].roleId) || x2.user.roles[0].roleId;
      if (x1.assigned && !x2.assigned) {
        return -1;
      } else if (!x1.assigned && x2.assigned) {
        return 1;
      } else if (role1 === roleTypes.STRATEGIST) {
        if (role2 === roleTypes.STRATEGIST) {
          return 0;
        } else {
          return -1;
        }
      } else if (role1 === roleTypes.CASE_MANAGER) {
        if (role2 === roleTypes.STRATEGIST) {
          return 1;
        } else if (role2 === roleTypes.CASE_MANAGER) {
          return 0;
        } else {
          return -1;
        }
      } else if (role1 === roleTypes.REVIEWER) {
        if (role2 === roleTypes.STRATEGIST || role2 === roleTypes.CASE_MANAGER) {
          return 1;
        } else if (role2 === roleTypes.REVIEWER) {
          return 0;
        } else {
          return -1;
        }
      }
      return 1;
    };
  const memberSorter = sortTeamMembers();
  const items = teamMembers.sort(memberSorter).map((item) => {
    const { assigned, user } = item;
    return {
      key: user.userId,
      label: (
        <SCTeamItem
          user={user}
          assigned={assigned}
          onChange={(checked: boolean) => {
            if (checked) {
              assignTeamMember(user);
            } else {
              unassignTeamMember(user);
            }
          }}
        />
      ),
    };
  });
  items.unshift({
    key: '0',
    label: <StyledTeamTitle>The Team</StyledTeamTitle>,
  });
  return (
    <StyledMemberContainer>
      <StyledMemberContainer>
        {members &&
          members.slice(0, 3).map((member) => {
            const { profileImageUrl, firstName, lastName, userId } = member;
            return (
              <Avatar
                key={userId}
                size={28}
                image={profileImageUrl}
                firstName={firstName}
                lastName={lastName}
                userId={userId}
              />
            );
          })}
        <StyledAntDropdown
          trigger={['click']}
          getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
          menu={{
            items,
          }}
          overlayStyle={{
            maxWidth: '278px',
            minWidth: '250px',
            maxHeight: '339px',
            background: '#ffffff',
            border: '1px solid #e3e7ed',
            boxShadow: '0px 5px 15px rgba(61, 64, 144, 0.2)',
            borderRadius: '8px',
            padding: '24px 0px',
            overflow: 'auto',
            color: '#1d1e2b',
          }}
          disabled={!editPermitted}
        >
          {members && members.length > 3 ? (
            <StyledAddMemberNumberIcon
              onClick={(e) => e.stopPropagation()}
              disabled={!editPermitted}
            >
              <span>+{members.length - 3}</span>
            </StyledAddMemberNumberIcon>
          ) : (
            <StyledAddMemberIcon onClick={(e) => e.stopPropagation()} disabled={!editPermitted}>
              <StyledUserAddOutlined />
            </StyledAddMemberIcon>
          )}
        </StyledAntDropdown>
      </StyledMemberContainer>
    </StyledMemberContainer>
  );
};
const TeamsPure = ({
  teamMembers,
  assignTeamMember,
  unassignTeamMember,
  editPermitted,
}: Props): JSX.Element => {
  return (
    <StyledTeamsContainer>
      <TeamsLine
        teamMembers={teamMembers}
        assignTeamMember={assignTeamMember}
        unassignTeamMember={unassignTeamMember}
        editPermitted={editPermitted}
      />
    </StyledTeamsContainer>
  );
};

export default TeamsPure;
