import {
  ExpandMore,
  ChevronRight,
  Close,
  DateRange,
} from '@styled-icons/material';
import styled from 'styled-components';
export const ExpandMoreIcon = styled(ExpandMore)<{
  width?: number;
  height?: number;
}>`
  width: ${(props) => (props?.width ? `${props.width}px` : 'auto')};
  height: ${(props) => (props?.height ? `${props.height}px` : 'auto')};
`;
export const StyledRightOutlined = styled(ChevronRight)`
  width: 20px;
  color: #a9acc0;
`;

export const StyledClose = styled(Close)<{ width?: number; height?: number }>`
  width: ${({ width = 20 }) => (width ? `${width}px` : 'auto')};
  height: ${({ height = 20 }) => (height ? `${height}px` : 'auto')};
  color: #1d1e2b;
  cursor: pointer;
`;

export const DateRangeIcon = styled(DateRange)<{
  width?: number;
  height?: number;
  color?: string;
}>`
  width: ${(props) => (props?.width ? `${props.width}px` : 'auto')};
  height: ${(props) => (props?.height ? `${props.height}px` : 'auto')};
  color: ${(props) => (props?.color ? `${props.color}` : 'auto')};
`;
