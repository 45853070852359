import { InternalNotesCategory } from '../types';
export const NOTE_INPUT_OPTIONS = [
  {
    value: InternalNotesCategory.General,
    label: 'General',
  },
  {
    value: InternalNotesCategory.Escalations,
    label: 'Escalations',
    children: [
      {
        value: 'Lack of progress',
        label: 'Lack of progress',
      },
      {
        value: 'Service team professionalism',
        label: 'Service team professionalism',
      },
      {
        value: 'Service team mistakes',
        label: 'Service team mistakes',
      },
      {
        value: 'Delay (allocation, responsiveness, start dates)',
        label: 'Delay (allocation, responsiveness, start dates)',
      },
      {
        value: "Doesn't like team member",
        label: "Doesn't like team member",
      },
      {
        value: 'Unclear roadmap',
        label: 'Unclear roadmap',
      },
      {
        value: 'Insufficient task follow-up',
        label: 'Insufficient task follow-up',
      },
      {
        value: 'Meeting frequency (strategy)',
        label: 'Meeting frequency (strategy)',
      },
      {
        value: "Service doesn't meet expectations",
        label: "Service doesn't meet expectations",
      },
      {
        value: 'Payment issue(delay, financial difficulties, debt collection)',
        label: 'Payment issue(delay, financial difficulties, debt collection)',
      },
      {
        value: 'Student engagement (off track, MIA, unresponsive)',
        label: 'Student engagement (off track, MIA, unresponsive)',
      },
      {
        value: 'Sales misalignment',
        label: 'Sales misalignment',
      },
      {
        value: 'Detractor NPS score',
        label: 'Detractor NPS score',
      },
      {
        value: 'General feedback',
        label: 'General feedback',
      },
      {
        value: 'None of the above',
        label: 'None of the above',
      },
    ],
  },
  {
    value: InternalNotesCategory.Handover,
    label: 'Handover',
  },
];
