import { useEffect, useState, useRef, useCallback } from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { Send } from '@styled-icons/material/Send';
import {
  ADD_ACTION_ITEM,
  LOAD_ACTION_ITEMS,
} from '@crimson-education/core-shared-graphql';
import {
  ActionItem as ActionItemType,
  MissionDetailObjectType,
  MissionChangeActions,
} from '../../types';
import { CR_COLORS } from '../../utils/theme';
import ActionItem from './action-item';
import {
  TaskTextArea,
  Container,
  ListContainer,
  Title,
  AddButton,
  InputContainer,
  InputStyle,
  CheckpointHeader,
  TaskCheckpointSelect,
} from './style';
import { PredefinedTaskToggle } from '../predefined-task-toggle/predefined-task-toggle';
import { useApiClient, useFeatureFlag } from '../../context';
import { ExpandMore } from '@styled-icons/material';
import {
  GET_INDIGO_TIMELINE,
  GET_INDIGO_CHECKPOINT_BY_TEMPLATE,
} from '../checkpoint-viewer/graphql';

export type ActionItemsProps = {
  mission: MissionDetailObjectType;
  editPermitted: boolean;
  deletePermitted: boolean;
  onMissionChange?: (action: MissionChangeActions) => void;
  visible: boolean;
};

export /* @__PURE__*/ const ActionItems: React.FC<ActionItemsProps> = ({
  mission,
  editPermitted,
  deletePermitted,
  onMissionChange,
  visible,
}) => {
  const [addValue, setAddValue] = useState('');
  const [addDate, setAddDate] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [addCheckpointOption, setAddCheckpointOption] = useState<any>();
  const { roadmapApiClient } = useApiClient();
  const [actionItems, setActionItems] = useState<ActionItemType[]>([]);
  const [pendingActionItems, setPendingActionItems] = useState<
    Omit<ActionItemType, 'id'>[]
  >([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const datePickerRef = useRef<any>(null);

  const updatePendingActionItems = useCallback(
    (actionItems: Omit<ActionItemType, 'id'>[]) => {
      setPendingActionItems(actionItems);
    },
    [],
  );
  const INDIGO_SOP = useFeatureFlag('INDIGO_SOP');
  const isIndigoMission = INDIGO_SOP && mission.integrationSource === 'cri';
  const { data, refetch: loadActionItems } = useQuery<{
    items: ActionItemType[];
  }>(LOAD_ACTION_ITEMS, {
    variables: {
      missionId: mission.id,
    },
    client: roadmapApiClient,
    fetchPolicy: 'network-only',
    skip: isIndigoMission,
  });
  const { data: getIndigoData, refetch: refetchIndigoSop } = useQuery(
    GET_INDIGO_TIMELINE,
    {
      variables: {
        missionId: mission.id,
      },
      client: roadmapApiClient,
      fetchPolicy: 'network-only',
      skip: !isIndigoMission,
    },
  );

  const { data: indigoCheckPointData } = useQuery(
    GET_INDIGO_CHECKPOINT_BY_TEMPLATE,
    {
      variables: {
        template: mission.linkId,
      },
      client: roadmapApiClient,
      fetchPolicy: 'network-only',
      skip: !isIndigoMission,
    },
  );

  const loadActionItemImpl = isIndigoMission
    ? refetchIndigoSop
    : loadActionItems;
  useEffect(() => {
    if (visible) {
      loadActionItemImpl();
    }
  }, [loadActionItemImpl, visible]);
  useEffect(() => {
    if (data?.items) {
      setActionItems(data?.items);
    }
  }, [data?.items]);
  const [addActionItem, { loading: submittingTask }] = useMutation(
    ADD_ACTION_ITEM,
    {
      client: roadmapApiClient,
    },
  );
  const onAdd: React.MouseEventHandler<HTMLElement> = async () => {
    if (!addValue) return;
    if (!addDate) {
      datePickerRef.current?.focus();
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const input: any = {
      missionId: mission.id,
      description: addValue,
      dueDate: new Date(addDate),
    };
    if (isIndigoMission && addCheckpointOption) {
      input['taskGroup'] = addCheckpointOption.value;
      input['attr'] = {
        title: addCheckpointOption.label,
      };
    }
    await addActionItem({
      variables: {
        input,
      },
    });
    setAddValue('');
    setAddDate('');
    setAddCheckpointOption(null);
    loadActionItemImpl();
    onMissionChange?.('AddActionItem');
  };

  const onNewActionItems = (items: ActionItemType[]) => {
    setActionItems(actionItems.concat(items));
    loadActionItemImpl();
  };

  const tasksGroups = getIndigoData?.getIndigoTimelineView?.groups;

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'relative',
        }}
      >
        <Title style={{ paddingLeft: 0 }}>
          Task{' '}
          <span>{`${
            actionItems.length
              ? Number(actionItems.length) > 1
                ? '(' + actionItems.length + ' tasks)'
                : '(1 task)'
              : ''
          }`}</span>
        </Title>
        {editPermitted && (
          <PredefinedTaskToggle
            mission={mission}
            updatePendingActionItems={updatePendingActionItems}
            onNewActionItems={onNewActionItems}
          />
        )}
      </div>
      <ListContainer>
        {isIndigoMission
          ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
            tasksGroups &&
            tasksGroups?.map((group: any) => {
              return (
                <div>
                  <CheckpointHeader>{group.title}</CheckpointHeader>
                  {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    group.tasks.map((item: any) => {
                      const newItem = {
                        ...item,
                        status:
                          item.status === 'COMPLETED' ||
                          item.status === 'COMPLETED_BUT_LATE'
                            ? 'DONE'
                            : item.status,
                      };
                      return (
                        <ActionItem
                          key={item.id}
                          item={newItem}
                          loadActionItems={loadActionItemImpl}
                          editPermitted={editPermitted}
                          deletePermitted={deletePermitted}
                        />
                      );
                    })
                  }
                </div>
              );
            })
          : actionItems
              ?.slice()
              .map((item) => (
                <ActionItem
                  key={item.id}
                  item={item}
                  loadActionItems={loadActionItemImpl}
                  editPermitted={editPermitted}
                  deletePermitted={deletePermitted}
                />
              ))}
        {pendingActionItems.map((item, index) => (
          <ActionItem
            pending
            key={'pendingActionItems' + index}
            item={item}
            loadActionItems={loadActionItemImpl}
            editPermitted={false}
            deletePermitted={false}
          />
        ))}
      </ListContainer>
      {editPermitted && (
        <div>
          <InputContainer>
            <InputStyle>
              <TaskTextArea
                autoSize={{ minRows: 1, maxRows: 8 }}
                bordered={false}
                placeholder="Add a task"
                value={addValue}
                onChange={(e) => {
                  setAddValue(e.target.value);
                }}
                disabled={!editPermitted}
              />
              <DatePicker
                ref={datePickerRef}
                bordered={false}
                placeholder={'Due date'}
                suffixIcon={
                  !addDate && (
                    <img src="/static/eventFill.svg" alt={'eventFill'} />
                  )
                }
                autoFocus={false}
                value={addDate ? moment(addDate) : null}
                getPopupContainer={(trigger) =>
                  trigger.parentElement as HTMLElement
                }
                onChange={(val) => {
                  val ? setAddDate(val.toLocaleString()) : setAddDate('');
                }}
                style={{ width: '95px', padding: 0 }}
                disabled={!editPermitted}
              />
            </InputStyle>
            <AddButton
              type="link"
              onClick={onAdd}
              loading={submittingTask}
              disabled={
                submittingTask ||
                !addValue ||
                !addDate ||
                (isIndigoMission && !addCheckpointOption)
              }
            >
              <Send
                height={24}
                color={CR_COLORS.LIGHT_PURPLE}
                opacity={
                  submittingTask ||
                  !addValue ||
                  !addDate ||
                  (isIndigoMission && !addCheckpointOption)
                    ? 0.4
                    : 1
                }
              />
            </AddButton>
          </InputContainer>
          {isIndigoMission && (
            <TaskCheckpointSelect
              dropdownStyle={{ minWidth: '390px' }}
              value={addCheckpointOption?.value}
              onChange={(value, option) => {
                setAddCheckpointOption(option);
              }}
              placeholder="Select a checkpoint"
              options={indigoCheckPointData?.getIndigoCheckpointsByTemplate?.map(
                (item: { id: string; title: string }) => {
                  return {
                    label: item.title,
                    value: item.id,
                  };
                },
              )}
              bordered={false}
              suffixIcon={<ExpandMore width={20} />}
            />
          )}
        </div>
      )}
    </Container>
  );
};
