import { useEffect, useState } from 'react';
import {
  StyledDropdownOverlayItem,
  StyledMissionStatus,
  StyledMissionStatusText,
} from './style';
import { StyledRightOutlined } from '../icons';
import { missionStatusObject, missionLegacyStatusObject } from '../../types';
import { useIsStaff } from '../hooks';
import { notification } from 'antd';
import { useFeatureFlag, useUserInfo } from '../../context';
import { HeadlessDropdown } from '../headless-antd/dropdown';

export /* @__PURE__*/ const MissionStatus = ({
  status,
  onSelectValue,
  editPermitted,
}: {
  status: keyof typeof missionStatusObject | undefined;
  onSelectValue: (value: keyof typeof missionStatusObject) => void;
  editPermitted: boolean;
}): JSX.Element => {
  const STRATEGIST_KPI_FF = useFeatureFlag('STRATEGIST_KPI');

  const user = useUserInfo();
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedKey, setSelectedKey] =
    useState<keyof typeof missionStatusObject>();
  useEffect(() => {
    status && setSelectedKey(status);
  }, [status]);
  const toggleShowDropdown = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    editPermitted && setShowDropdown(!showDropdown);
  };

  const isStaff = useIsStaff(user);

  const selectValue = (value: keyof typeof missionStatusObject) => {
    if (value === 'DONE') {
      if (!isStaff) {
        notification.info({
          message:
            'Only the Strategist has the permission to mark it as completed',
        });
        return;
      }
    }
    if (value === 'EXPIRED' && !isStaff) {
      notification.info({
        message: 'Only the Strategist has the permission to mark it as expired',
      });
      return;
    }
    if (value === 'POSTPONED' && !isStaff) {
      notification.info({
        message:
          'Only the Strategist has the permission to mark it as postponed',
      });
      return;
    }
    setSelectedKey(value);
    onSelectValue(value);
    setShowDropdown(false);
  };
  return (
    <HeadlessDropdown
      open={showDropdown}
      onOpenChange={(open) => setShowDropdown(open)}
      trigger={['click']}
      disabled={!editPermitted}
      overlayStyle={{
        border: '1px solid #e3e7ed',
        borderRadius: '8px',
        overflow: 'hidden',
      }}
      menu={{
        items: (
          Object.keys(
            STRATEGIST_KPI_FF ? missionStatusObject : missionLegacyStatusObject,
          ) as Array<keyof typeof missionStatusObject>
        ).map((key, index) => {
          return {
            key: 'mission-status' + index,
            label: (
              <StyledDropdownOverlayItem
                key={index}
                onClick={(e) => {
                  selectValue(key);
                  e.stopPropagation();
                }}
              >
                <span
                  style={{
                    color:
                      ['DONE', 'EXPIRED', 'POSTPONED'].includes(key) && !isStaff
                        ? 'var(--color-cool-grey)'
                        : '73747d',
                  }}
                >
                  {missionStatusObject[key]}
                </span>
              </StyledDropdownOverlayItem>
            ),
          };
        }),
      }}
    >
      <StyledMissionStatus
        status={selectedKey}
        onClick={(e) => toggleShowDropdown(e)}
        disabled={!editPermitted}
      >
        <StyledMissionStatusText disabled={!editPermitted}>
          {missionStatusObject[selectedKey as keyof typeof missionStatusObject]}
          <StyledRightOutlined />
        </StyledMissionStatusText>
      </StyledMissionStatus>
    </HeadlessDropdown>
  );
};
