import type { UploadFile } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { UploadFileSystem } from './upload-file';
import {
  TaskTextArea,
  StyledReworkDueDatePicker,
  StyledSaveButton,
  StyledCancelButton,
} from './style';
import { RichTextEditor } from '../rich-text-editor/rich-text-editor';

export const AddFromScratch = ({
  isAddingActionItem,
  editPermitted,
  addActionItem,
  isSubmitting,
  setIsAddingActionItem,
  from,
}: {
  editPermitted: boolean;
  addActionItem: ({
    description,
    dueDate,
    content,
    fileList,
  }: {
    description: string;
    dueDate: string;
    content?: string;
    fileList?: UploadFile[];
  }) => void;
  isSubmitting: boolean;
  isAddingActionItem: boolean;
  setIsAddingActionItem: React.Dispatch<React.SetStateAction<boolean>>;
  from?: string;
}) => {
  useEffect(() => {
    setDueDate('');
    setDescription('');
    setContent('');
    setFileList([]);
  }, [isAddingActionItem]);
  const [dueDate, setDueDate] = useState('');
  const [description, setDescription] = useState('');
  const [content, setContent] = useState('');
  const canSubmit =
    description !== '' &&
    (from === 'library' || dueDate !== '') &&
    !isSubmitting;
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const onChangeFileList = (fileList: UploadFile[]) => {
    setFileList(fileList);
  };

  return (
    <div
      style={{
        paddingLeft: '38px',
        borderTop: '1px solid #E3E7ED',
        paddingTop: '15px',
      }}
    >
      <div>
        <div style={{ display: 'flex' }}>
          <TaskTextArea
            autoSize={{ minRows: 1, maxRows: 7 }}
            autoFocus
            disabled={!editPermitted}
            value={description}
            placeholder="Add a task title"
            bordered={false}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
          {from !== 'library' && (
            <StyledReworkDueDatePicker
              bordered={false}
              placeholder={'Due date'}
              suffixIcon={null}
              autoFocus={false}
              value={dueDate ? moment(dueDate) : null}
              getPopupContainer={(trigger) =>
                trigger.parentElement as HTMLElement
              }
              onChange={(val) => {
                val ? setDueDate(val.toLocaleString()) : setDueDate('');
              }}
              style={{ width: '95px', padding: 0 }}
              disabled={!editPermitted}
            />
          )}
        </div>
        <div>
          <RichTextEditor
            content={content}
            onChange={(e: string) => {
              setContent(e);
            }}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <UploadFileSystem onChangeFileList={onChangeFileList} mode="new" />
          <div style={{ display: 'flex', gap: '8px' }}>
            <StyledCancelButton
              onClick={() => {
                setIsAddingActionItem(false);
              }}
            >
              Cancel
            </StyledCancelButton>
            <StyledSaveButton
              loading={isSubmitting}
              disabled={!canSubmit}
              onClick={() =>
                addActionItem({
                  description,
                  dueDate:
                    from === 'library' ? moment().toLocaleString() : dueDate,
                  content,
                  fileList,
                })
              }
            >
              Save
            </StyledSaveButton>
          </div>
        </div>
      </div>
    </div>
  );
};
