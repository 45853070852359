import type { UploadFile } from 'antd';
import { UploadFileSystem } from './upload-file';
import {
  ApolloQueryResult,
  OperationVariables,
  useMutation,
  useQuery,
} from '@apollo/client';
import { NavigateNext } from '@styled-icons/material';
import { theme } from '../../utils/theme';
import moment from 'moment';
import { useEffect, useState, useRef } from 'react';
import {
  DELETE_ACTION_ITEM,
  CHANGE_ACTION_ITEM_STATUS,
  CHANGE_ACTION_ITEM,
} from '@crimson-education/core-shared-graphql';
import sanitize from 'sanitize-filename';

import {
  ActionItem as ActionItemType,
  ActionItemStatus,
  MissionChangeActions,
} from '../../types';
import { RemoveButton } from '../remove-button/remove-button';
import { FileRefType } from '@crimson-education/common-config';

import {
  Checkbox,
  RangePickerPopover,
  RangePicker,
  ChangeDate,
  DescSpan,
  RemoveButtonContainer,
  TaskTextArea,
  ContentSpan,
  StyledSaveButton,
} from './style';
import { useApiClient } from '../../context';
import { notification } from 'antd';
import {
  GET_SC_UPLOAD_URL,
  SAVE_FILE,
} from '@crimson-education/core-shared-graphql';
import { FileType } from './action-items-rework';
import { RichTextViewer } from '../rich-text-editor/rich-text-viewer';
import { RichTextEditor } from '../rich-text-editor/rich-text-editor';
import CWEditAuthProvider from '../mission-drawer/CWEditAuthProvider';

export const ActionItemRework = ({
  editPermitted,
  item,
  deletePermitted,
  loadActionItems,
  onMissionChange,
  isCWStudent,
  isMissionCreatedByLoginUser,
}: {
  editPermitted: boolean;
  item: ActionItemType;
  deletePermitted: boolean;
  loadActionItems: (
    variables?: Partial<OperationVariables> | undefined,
  ) => Promise<
    ApolloQueryResult<{
      items: ActionItemType[];
    }>
  >;
  onMissionChange?: (action: MissionChangeActions) => void;
  isCWStudent: boolean;
  isMissionCreatedByLoginUser: boolean;
}) => {
  const [startAt, setStartAt] = useState<string | null>(null);
  const [dueDate, setDueDate] = useState('');
  const [description, setDescription] = useState('');
  const [content, setContent] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [checked, setChecked] = useState(item.status === ActionItemStatus.DONE);
  const { roadmapApiClient, coreApiClient } = useApiClient();
  const [deleteActionItem] = useMutation(DELETE_ACTION_ITEM, {
    client: roadmapApiClient,
  });
  const [changeActionItemStatus] = useMutation(CHANGE_ACTION_ITEM_STATUS, {
    client: roadmapApiClient,
  });
  const [changeActionItem] = useMutation(CHANGE_ACTION_ITEM, {
    client: roadmapApiClient,
  });
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const canSubmit = description !== '' && dueDate !== '' && !isSubmitting;
  const onChangeFileList = (fileList: UploadFile[]) => {
    setFileList(fileList);
  };
  useEffect(() => {
    if (item) {
      setDescription(item.description);
      if (item.content) {
        setContent(item.content);
      }
      setDueDate(item.dueDate as string);
      if (item.startAt) {
        setStartAt(item.startAt as string);
      }
    }
  }, [item]);

  const { refetch: getStudentCenterUploadUrl } = useQuery(GET_SC_UPLOAD_URL, {
    client: coreApiClient,
    skip: true,
  });
  const [saveFile] = useMutation(SAVE_FILE, {
    client: coreApiClient,
  });
  const saveActionItem = async () => {
    const finalList = [];
    const resources = item.resources;
    if (fileList && fileList.length > 0) {
      let addedItems;
      if (resources && resources.length > 0) {
        //compare with resources stored in DB. find what has been added. if the added file is upload, need to do a manual upload
        addedItems = fileList.filter(
          (file) => !resources.some((resource) => resource.id === file.uid),
        );
      } else {
        //there is no resources in DB. all files are added
        addedItems = fileList;
      }
      for (let i = 0; i < fileList.length; i++) {
        // files here all need to be added to API
        const file = fileList[i];
        if (file.type === 'Link') {
          // no need to upload, just add to finalList
          finalList.push({
            type: 'Link',
            url: file.url,
            title: file.name,
            mediaType: 'link',
            orderIndex: i,
          });
        } else {
          if (
            addedItems &&
            addedItems.length > 0 &&
            addedItems.some((addedItem) => addedItem.uid === file.uid)
          ) {
            //prepare to upload
            const { name, type, size } = file;
            const sanitized = sanitize(name);
            const encoded = encodeURIComponent(sanitized);
            const formData = new FormData();
            formData.append('files[]', file as FileType);
            try {
              const res = await getStudentCenterUploadUrl({
                refId: item.id,
                fileName: encoded,
                contentType: type,
              });
              const { putUrl, key } = res.data.getStudentCenterUploadUrl;
              await fetch(putUrl, {
                method: 'PUT',
                body: formData,
              });
              const result = await saveFile({
                variables: {
                  location: key,
                  sha512: ' ',
                  name: name,
                  size: `${size}`,
                  type: type,
                  refId: item.id,
                  refType: FileRefType.ACADEMIC,
                },
              });
              if (result?.data?.saveFileUplRef?.id) {
                finalList.push({
                  type: 'Upload',
                  url: result?.data?.saveFileUplRef?.id, //putUrl cannot be used directly since it's a temporary url. need to save the location info to DB. store id in DB. and then get the download url by id
                  title: file.name,
                  mediaType: file.type,
                  orderIndex: i,
                });
              }
            } catch {
              notification.error({
                message: 'Upload File Error',
              });
            }
          } else {
            finalList.push({
              type: 'Upload',
              url: file.url,
              title: file.name,
              mediaType: file.linkProps ? file.linkProps : file.type,
              orderIndex: i,
            });
          }
        }
      }
    }
    const input: any = {
      actionItemId: item.id,
      description,
      content,
      resources: finalList,
      startAt: startAt ? moment(startAt) : moment(),
      dueDate: moment(dueDate),
    };
    await changeActionItem({
      variables: {
        input,
      },
    });
    loadActionItems();
    notification.success({
      message: 'Edit Successfully!',
    });
  };
  return (
    <div style={{ borderTop: '1px solid #E3E7ED', paddingTop: '15px' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        <Checkbox
          onChange={async (e) => {
            const value = e.target.checked;
            setChecked(value);
            let status = ActionItemStatus.PLANNED;
            if (value === true) {
              status = ActionItemStatus.DONE;
            }
            await changeActionItemStatus({
              variables: {
                actionItemId: item.id,
                status: status,
              },
            });
            loadActionItems();
            onMissionChange?.('ChangeActionItemStatus');
          }}
          checked={checked}
          disabled={!editPermitted}
        />
        <CWEditAuthProvider
          isCWStudent={isCWStudent}
          isMissionCreatedByLoginUser={isMissionCreatedByLoginUser}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'flex-start',
            }}
          >
            <div style={{ flex: '1 1 auto', marginRight: 20 }}>
              {isEditing ? (
                <TaskTextArea
                  autoSize={{ minRows: 1, maxRows: 7 }}
                  autoFocus
                  disabled={!editPermitted}
                  value={description}
                  placeholder="Add a task title"
                  bordered={false}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              ) : (
                <DescSpan checked={item.status === ActionItemStatus.DONE}>
                  {item.description}
                </DescSpan>
              )}
            </div>

            <RangePickerPopover
              trigger="hover"
              content={
                <RangePicker
                  allowEmpty={[true, true]}
                  bordered={false}
                  format="MMM DD, YYYY"
                  allowClear={false}
                  value={[
                    startAt ? moment(startAt as string) : moment(),
                    dueDate ? moment(dueDate as string) : null,
                  ]}
                  onChange={async (dates) => {
                    if (dates) {
                      if (dates[0]) {
                        setStartAt(dates[0].toLocaleString());
                      }
                      if (dates[1]) {
                        setDueDate(dates[1].toLocaleString());
                      }
                    }
                  }}
                  disabled={!isEditing || !editPermitted}
                />
              }
            >
              <ChangeDate>
                <span>
                  {dueDate
                    ? `${moment(dueDate).format('MMM DD, YYYY')}`
                    : editPermitted && (
                        <span style={{ color: `${theme.common.salmon}` }}>
                          Set date
                        </span>
                      )}
                </span>
                {editPermitted && <NavigateNext height={20} />}
              </ChangeDate>
            </RangePickerPopover>

            {deletePermitted && (
              <RemoveButtonContainer>
                <RemoveButton
                  onConfirm={async () => {
                    await deleteActionItem({
                      variables: {
                        actionItemId: item.id,
                      },
                    });
                    loadActionItems();
                    onMissionChange?.('DeleteActionItem');
                  }}
                  removeButtonText="Remove this task"
                  removeModalText="Are you sure you want to remove this task?"
                  setEditMode={() => {
                    setIsEditing(true);
                  }}
                  isEditing={isEditing}
                />
              </RemoveButtonContainer>
            )}
          </div>
        </CWEditAuthProvider>
      </div>

      <div style={{ paddingLeft: '38px' }}>
        <div>
          {isEditing ? (
            <RichTextEditor content={content || ''} onChange={setContent} />
          ) : content ? (
            <ContentSpan checked={item.status === ActionItemStatus.DONE}>
              <RichTextViewer content={content || ''} />
            </ContentSpan>
          ) : null}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingRight: '30px',
          }}
        >
          <UploadFileSystem
            mode={isEditing ? 'edit' : 'show'}
            resources={item.resources}
            onChangeFileList={onChangeFileList}
          />
          {isEditing && (
            <StyledSaveButton
              loading={isSubmitting}
              disabled={!canSubmit}
              onClick={async () => {
                setIsSubmitting(true);
                await saveActionItem();
                setIsSubmitting(false);
                setIsEditing(false);
              }}
            >
              Save
            </StyledSaveButton>
          )}
        </div>
      </div>
    </div>
  );
};
