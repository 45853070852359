import styled from 'styled-components';

export const StyledDropdownOverlayItem = styled.div`
  padding: 5px 12px;
  color: #73747d;
  &:hover {
    cursor: pointer;
    background: #f3f6fa;
  }
`;
export const StyledMissionStatusText = styled.div<{
  status?: string;
  disabled?: boolean;
}>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  align-items: center;
`;
export const StyledMissionStatus = styled.div<{
  status?: string;
  disabled?: boolean;
}>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  background: ${(props) => {
    if (props.status) {
      switch (props.status) {
        case 'PLANNED':
          return '#e9e8ff';
        case 'DONE':
          return '#E0F9F2';
        case 'IN_PROGRESS':
          return '#E8F1FF';
        case 'EXPIRED':
          return '#ffeae4';
        case 'POSTPONED':
          return '#fff2d9';
      }
    }
  }};
  border-radius: 8px;
  padding: 5px 13px;
  color: ${(props) => {
    if (props.status) {
      switch (props.status) {
        case 'PLANNED':
          return '#6c63ff';
        case 'DONE':
          return '#12C39A';
        case 'IN_PROGRESS':
          return '#3F8CFF';
        case 'EXPIRED':
          return '#ff764c';
        case 'POSTPONED':
          return '#fda903';
      }
    }
  }};
`;
