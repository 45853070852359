import styled from 'styled-components';

export const StyledActivityLogItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const StyledActivityLogDesc = styled.div`
  color: #73747d;
  width: 80%;
  font-size: 12px;
`;

export const StyledActivityLogDate = styled.div`
  color: #a9acc0;
  font-size: 10px;
`;

export const StyledTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 18px;
`;

export const StyledViewMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const StyledViewMoreButton = styled.div`
  color: #a9acc0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e3e7ed;
  border-radius: 20px;
  padding: 2px 16px;
`;

export const HighlightedSpan = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: rgba(29, 30, 43, 0.9);
`;
