import {
  FunctionComponent,
  MouseEventHandler,
  useMemo,
  useState,
} from 'react';
import * as Styled from './style';
import { Avatar } from '../avatar/avatar';
import { Button, Input } from 'antd';
import { Send } from '@styled-icons/material/Send';
import { CR_COLORS } from '../../utils/theme';
import { TReflection } from './types';
import { useMutation } from '@apollo/client';
import {
  CREATE_REFLECTION,
  UPDATE_REFLECTION,
} from '@crimson-education/core-shared-graphql';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useApiClient, useUserInfo } from '../../context';
const loadingIcon = <LoadingOutlined style={{ fontSize: 12 }} spin />;

type InputMode = 'edit' | 'new';
interface ReflectionInputPros {
  reflection?: TReflection;
  missionId: string;
  onInputSaved?: (reflection: TReflection) => void;
}

const ReflectionInput: FunctionComponent<ReflectionInputPros> = ({
  reflection,
  missionId,
  onInputSaved,
}) => {
  const { firstName, lastName, profileImageUrl, userId } =
    useUserInfo();
  const { roadmapApiClient } = useApiClient();
  const [createReflection] = useMutation(CREATE_REFLECTION, {
    client: roadmapApiClient,
  });
  const [updateReflection] = useMutation(UPDATE_REFLECTION, {
    client: roadmapApiClient,
  });

  const [description, setDescription] = useState(reflection?.description || '');
  const canSubmit = useMemo(() => !!description, [description]);
  const [submitting, setSubmitting] = useState(false);
  const mode: InputMode = useMemo(
    () => (reflection ? 'edit' : 'new'),
    [reflection]
  );
  const submit: MouseEventHandler = async (e) => {
    e.stopPropagation();
    const input: Partial<TReflection> = {
      description: description.trim(),
      missionId,
    };
    if (description) {
      setSubmitting(true);
      const { data, errors } = reflection?.id
        ? await updateReflection({
            variables: { reflectionId: reflection.id, input },
          })
        : await createReflection({ variables: { input } });
      setSubmitting(false);
      if (errors?.length) return;
      setDescription('');
      !!onInputSaved &&
        onInputSaved({
          ...data.reflection,
          createdAt: new Date(data.reflection.createdAt),
          updatedAt: new Date(data.reflection.updatedAt),
          creator: {
            firstName,
            lastName,
            userId,
            profileImageUrl,
          },
        });
    }
  };

  if (!userId) {
    return null;
  }
  return (
    <Styled.NoteInputContainer>
      {mode === 'new' ? (
        <Avatar
          key={userId}
          image={profileImageUrl || undefined}
          userId={userId}
          firstName={firstName}
          lastName={lastName}
          size={24}
        />
      ) : (
        <div style={{ width: 30 }}></div> // Spacer
      )}
      <>
        <Input.TextArea
          value={description}
          disabled={submitting}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Add a reflection"
          autoSize
        />
        <Button
          disabled={!canSubmit || submitting || !description.trim()}
          onClick={submit}
          shape="circle"
        >
          {submitting ? (
            <Spin indicator={loadingIcon} />
          ) : (
            <Send
              height={24}
              color={CR_COLORS.LIGHT_PURPLE}
              opacity={canSubmit && !submitting && description.trim() ? 1 : 0.4}
            />
          )}
        </Button>
      </>
    </Styled.NoteInputContainer>
  );
};

export default ReflectionInput;
