import { useMemo } from 'react';
import { User } from '../../types';
import roles from '@crimson-education/common-config/lib/authorization/legacy/roles';

export const useIsStrategistOrSA = <U extends Pick<User, 'roles'>>(
  user?: U
): boolean => {
  return useMemo(() => {
    const ACL = [roles.STRATEGIST, roles.SUPER_ADMIN];
    if (!user?.roles || user.roles.length < 1) return false;
    return user?.roles.filter(({ roleId }) => ACL.includes(roleId))?.length > 0;
  }, [user?.roles]);
};

export const useIsStaff = <U extends Pick<User, 'roles'>>(user?: U): boolean => {
  return useMemo(() => {
    const ACL = [roles.CASE_MANAGER, roles.TUTOR, roles.SUPER_ADMIN, roles.STRATEGIST, roles.ACADEMIC_ADVISOR];
    if (!user?.roles || user.roles.length < 1) return false;
    return user?.roles.filter(({ roleId }) => ACL.includes(roleId))?.length > 0;
  }, [user?.roles]);
};
