import React from 'react';
export /* @__PURE__*/ const getProvider = <Ctx,>(context: React.Context<Ctx>) => {
  const Provider: React.FC<{ value: Ctx; children: React.ReactNode }> = ({
    value,
    children,
  }) => {
    return <context.Provider value={value}>{children}</context.Provider>;
  };
  return Provider;
};
