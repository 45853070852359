import { gql } from '@apollo/client';

export const GET_TEMPLATE_ACTION_ITEMS = gql`
  query GetTemplateActionItems($categories: [String!], $subcategories: [String!], $keyword: String, $groups: [String!]) {
    getTemplateActionItems(categories: $categories, subcategories: $subcategories, keyword: $keyword, groups: $groups) {
      content
      description
      id
      missionId
      resources {
        id
        orderIndex
        title
        type
        url
        mediaType
        createdAt
        updatedAt
      }
      mission {
        isShared
      }
      startAt
    }
  }
`

export const GET_TEMPLATE_MISSIONS = gql`
  query GetTemplateMissions($categories: [String!], $subcategories: [String!], $keyword: String, $groups: [String!]) {
    getTemplateMissions(categories: $categories, subcategories: $subcategories, keyword: $keyword, groups: $groups) {
      id
      title
      isShared
    }
  }
`
