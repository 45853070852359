import styled from 'styled-components';
import { theme } from '../../utils/theme';
import { Button } from 'antd';

export const StyledButtonFilled = styled(Button)<{
  width?: number;
  fontSize?: string;
}>`
  width: ${(props) => (props?.width ? `${props.width}px` : 'auto')};
  font-family: Montserrat;
  font-size: ${(props) => props?.fontSize || '16px'};
  font-weight: 600;
  border-radius: 32px;
  padding: 10px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.2;
  transition: box-shadow 0.2s ease-in-out 0s;
  background-color: rgb(70, 74, 201);
  border: 1px solid rgb(64, 68, 184);
  color: rgb(255, 255, 255);
  &:active {
    background: transparent;
    border: 1px solid rgb(70, 74, 201);
    color: rgb(70, 74, 201);
  }
  &:focus {
    background: transparent;
    border: 1px solid rgb(70, 74, 201);
    color: rgb(70, 74, 201);
  }
  &:hover {
    background: #2d30b4;
    box-shadow: 0px 3px 6px rgba(61, 64, 144, 0.38);
    border: 1px solid rgb(70, 74, 201);
    color: white;
  }
`;

export const StyledButtonOutlined = styled(Button)<{
  width?: number;
  fontSize?: string;
}>`
  width: ${(props) => (props?.width ? `${props.width}px` : 'auto')};
  background: transparent;
  border: 2px solid rgb(70, 74, 201);
  color: rgb(70, 74, 201);
  font-family: Montserrat;
  font-size: ${(props) => props.fontSize || '16px'};
  font-weight: 600;
  border-radius: 32px;
  padding: 10px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.2;
  transition: box-shadow 0.2s ease-in-out 0s;
  &:active {
    background: transparent;
    border: 1px solid rgb(70, 74, 201);
    color: rgb(70, 74, 201);
  }
  &:focus {
    background: transparent;
    border: 1px solid rgb(70, 74, 201);
    color: rgb(70, 74, 201);
  }
  &:hover {
    box-shadow: 0px 3px 6px rgba(61, 64, 144, 0.38);
    color: rgb(70, 74, 201);
  }
  &.block-add {
    border: 1px solid ${theme.common.paleGrey};
    background: ${theme.common.lightGray};
    color: ${theme.common.coolGrey};
  }
`;
