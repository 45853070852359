import { gql } from '@apollo/client';

export const GET_INDIGO_TIMELINE = gql`
  query GetIndigoTimelineView($missionId: String!, $withSkipped: Boolean) {
    getIndigoTimelineView(missionId: $missionId, withSkipped: $withSkipped) {
      dueDate
      groups {
        title
        expand
        status
        tasks {
          id
          description
          startAt
          dueDate
          status
          type
          content
          attr
          resources {
            title
            url
          }
        }
      }
      linkId
      id
      startAt
      status
      userId
    }
  }
`;

export const GET_INDIGO_CHECKPOINT_BY_TEMPLATE = gql`
  query GetIndigoCheckpointsByTemplate($template: String!) {
    getIndigoCheckpointsByTemplate(template: $template) {
      id
      title
      order
    }
  }
`;
