import styled from 'styled-components';
import {
  Checkbox as AntCheckbox,
  Input as AntInput,
  Popover,
  DatePicker,
  Button,
  Select,
} from 'antd';
const { RangePicker: AntRangePicker } = DatePicker;
const { TextArea } = AntInput;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 14px;
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  padding-left: 4px;

  & > span {
    font-size: 14px;
    font-weight: 400;
    color: #a9acc0; /* cool grrey */
  }
`;

export const ListContainer = styled.div`
  max-width: 520px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const MissionLibListContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const Desc = styled.div`
  display: 'flex';
  align-items: 'flex-start';
  &:hover,
  &:focus {
    border: 1px solid rgba(70, 74, 201, 0.8);
    box-shadow: 0px 6px 26px rgba(158, 158, 158, 0.1);
    border-radius: 4px;
  }
`;

export const Checkbox = styled(AntCheckbox)`
  margin: 0 11px;
`;

// substituded with TaskTextArea
export const DescInput = styled(AntInput)`
  font-size: 14px;
  width: 380px;
  padding: 0;
  box-shadow: 0px 6px 20px rgba(169, 172, 192, 0.3);
  border-radius: 2px;
  height: 25px;
  z-index: 1;
  margin-right: 30px;
  &:hover,
  &:focus {
    box-shadow: 0px 6px 20px rgba(169, 172, 192, 0.3);
  }
`;

export const DescSpan = styled.span<{ checked: boolean }>`
  flex: 1 0 auto;
  display: block;
  min-height: 21px;
  max-width: 380px;
  font-size: 14px;
  text-decoration: ${(props) => (props.checked ? 'line-through' : 'none')};
  overflow-wrap: break-word;
`;

export const ContentSpan = styled.span<{ checked: boolean }>`
  flex: 1 0 auto;
  display: block;
  min-height: 21px;
  font-size: 14px;
  text-decoration: ${(props) => (props.checked ? 'line-through' : 'none')};
  overflow-wrap: break-word;
  font-size: 12px;
  color: #73747D;
`;

export const InputContainer = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: flex-end;
`;

export const TaskTextArea = styled(TextArea)`
  min-width: 80%;
  border: 1px solid white !important;
  border-radius: 4px;
  width: auto !important;
  padding: 0;
  .ant-input {
    padding-left: 12px;
  }
  .ant-input::placeholder {
    color: var(--color-cool-grey);
  }
`;

export const TaskCheckpointSelect = styled(Select)`
  /* border: 1px solid white !important;
  border-radius: 4px;
  width: auto !important;
  .ant-input {
    padding-left: 12px;
  }
  .ant-input::placeholder {
    color: var(--color-cool-grey);
  } */
`;


export const AddButton = styled(Button)`
  padding: 3px;
`;

export const RangePicker = styled(AntRangePicker)`
  padding: 0 !important;
  width: 260px;
`;

export const RangePickerPopover = styled(Popover)``;

export const ChangeDate = styled.span`
  flex: 0 0 auto;
  margin-right: 8px;
  margin-top: 1px;
  height: 22px;
  line-height: 24px;
  font-size: 12px;
  color: var(--color-cool-grey);
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
    color: var(--color-indigo);
  }
`;

export const RemoveButtonContainer = styled.div`
  height: 20px;
  svg {
    height: 20px;
  }
`;

export const InputStyle = styled.div`
  display: flex;
  align-items: center;
  width: 500px;
  justify-content: space-between;
  box-shadow: 0px 6px 26px rgba(158, 158, 158, 0.1);
  border: 1px solid transparent;
  &:hover,
  &:focus {
    border: 1px solid rgba(70, 74, 201, 0.8) !important;
    box-shadow: 0px 6px 26px rgba(158, 158, 158, 0.1) !important;
    border-radius: 4px;
  }
  .ant-picker-input > input {
    font-size: 12px;
    color: var(--color-cool-grey);
  }
`;

export const CheckpointHeader = styled.div`
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  margin-bottom: 10px;
`;



export const StyledUploadActionContainer = styled.div``;

export const StyledPopover = styled(Popover)``;

export const StyledUploadButton = styled.div`
  border: none;
  color: #73747d;
  cursor: pointer;
  &:hover {
    color: #464ac9;
  }
`;

export const StyledBottomContainer = styled.div`
  margin-top: 8px;
  display: flex;
`;

export const StyledAddFileContainer = styled.div`
  display: flex;
  width: 368px;
  gap: 8px;
  flex-wrap: wrap;
`;
export const StyledUploadedFile = styled.div`
  display: flex;
  background: #f3f6fa;
  height: 28px;
  line-height: 28px;
  border-radius: 4px;
  font-size: 12px;
  color: #73747d;
  padding: 0 6px 0 8px;
`;

export const StyledFileName = styled.a`
  width: auto;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 5px;
  color: #73747d!important;
`;

export const StyledAddResourceButton = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  color: #a9acc0;
  font-size: 12px;
  cursor: pointer;
`;

export const StyledCancelButton = styled(Button)`
  width: 60px;
  font-size: 11px;
  border: 1px solid #E3E7ED;
  color: #73747D;
  padding: 0;
  border-radius: 4px;
`;

export const StyledSaveButton = styled(Button)`
  width: 60px;
  font-size: 11px;
  background: #464ac9;
  color: white;
  border: none;
  padding: 0;
  border-radius: 4px;
`;

export const StyledOperationButton = styled.div`
  cursor: pointer;
  &:hover{
    color: #464AC9;
  }
`

export const StyledReworkDueDatePicker = styled(DatePicker)`
  .ant-picker-input > input{
    font-size: 12px;
  }
`
