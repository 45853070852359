import styled from "styled-components";

export const StyledModalDesc = styled.div`
  margin-top: 32px;
`;

export const StyledButtonGroup = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  justify-content: space-evenly;
  align-items: center;
`;

export const StyledModalDescContainer = styled.div`
  margin-top: 12px;
`;
