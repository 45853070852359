import { createContext } from 'react';
import { Role } from '../types';
export type Tenant = {
  id: string;
  name: string;
  level: number;
};
export type UserInfoContextType = {
  userId: string;
  fullName: string;
  firstName: string;
  lastName: string;
  email?: string;
  profileImageUrl?: string;
  roles: Role[];
  tenant?: Tenant;
};

export /* @__PURE__*/ const UserInfoContext =
  createContext<UserInfoContextType>(null as unknown as UserInfoContextType);
UserInfoContext.displayName = 'Shared Ui User Info Context';
