
import { Modal, DatePicker } from 'antd';
import { Today } from '@styled-icons/material';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { CHANGE_MISSION_DUE_DATE, CHANGE_MISSION_START_DATE } from '@crimson-education/core-shared-graphql';
import { Container, LeftContainer, StyleIndigoDot, StyledAddTaskButtonContainer, StyledSectionTitle, StyledTaskContainer, StyledTaskItem, StyledTaskItemContent, StyledTitle, Title, StyledDatePicker } from './style';
import {
  MissionDetailObjectType,
  ActionItem as ActionItemType,
} from '../../types';
import { useApiClient } from '../../context';
import { CancelButton, SubmitButton } from '../remove-button/style';
const { RangePicker } = DatePicker;

export const Task = ({ task, onChangeTaskDueDate }: {
  task: ActionItemType;
  onChangeTaskDueDate: (taskDueDate: string) => void;
}) => {
  const [taskDueDate, setTaskDueDate] = useState<string>('');
  useEffect(()=>{
    setTaskDueDate(task.dueDate ? task.dueDate : '');
  }, [task.dueDate]);
  return <StyledTaskItem>
    <LeftContainer>
      <StyleIndigoDot />
      <StyledTaskItemContent title={task.description}>
        {task.description}
      </StyledTaskItemContent>
    </LeftContainer>
    <StyledDatePicker
      bordered={false}
      placeholder={'Due date'}
      suffixIcon={null}
      autoFocus={false}
      value={taskDueDate ? moment(taskDueDate) : null}
      getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
      onChange={(val) => {
        const value = val ? val.toISOString() : '';
        setTaskDueDate(value);
        onChangeTaskDueDate(value);
      }}
      style={{ width: '95px', padding: 0, textAlign: 'right' }}
    />
  </StyledTaskItem>
}

export const AddActionItemModal = ({ addTaskModalOpen, closeTaskModal, mission, tasksFromLib, confirmAddTaskToMission }: {
  addTaskModalOpen: boolean;
  closeTaskModal: () => void;
  mission: MissionDetailObjectType;
  tasksFromLib: ActionItemType[] | undefined;
  confirmAddTaskToMission: (tasks: ActionItemType[] | undefined) => void;
}) =>{
  const [dateParams, setDateParams] = useState<{ startDate: string; endDate: string }>({
    startDate: '',
    endDate: '',
  });
  useEffect(()=>{
    if (mission.dueDate) {
      setDateParams({
        startDate: '',
        endDate: mission.dueDate
      })
    }
  }, [mission])
  const { roadmapApiClient } = useApiClient();

  const [changeMissionStartDate] = useMutation(CHANGE_MISSION_START_DATE, {
    ignoreResults: true,
    client: roadmapApiClient,
  });
  const [changeMissionEndDate] = useMutation(CHANGE_MISSION_DUE_DATE, {
    ignoreResults: true,
    client: roadmapApiClient,
  });
  const [tasks, setTasks] = useState<ActionItemType[] | undefined>();
  useEffect(()=>{
    setTasks(tasksFromLib?.map((task)=>{
      return {
        ...task,
        dueDate: task.dueDate ? task.dueDate : mission.dueDate ? mission.dueDate : '',
      }
    }));
  }, [tasksFromLib]);

  return (
    <Modal
      visible={addTaskModalOpen}
      onCancel={closeTaskModal}
      footer={null}
      width={580}
      bodyStyle={{ padding: 0, display: 'flex' }}>
      <Container>
        <Title>{'Add Tasks to Mission'}</Title>
        <StyledTitle>{mission?.title}</StyledTitle>
        {!mission.dueDate &&
          <StyledTaskContainer>
            <StyledSectionTitle>Timeline</StyledSectionTitle>
            <RangePicker
              style={{width: '100%'}}
              allowClear={false}
              onChange={(dates) =>{
                  setDateParams({
                    startDate: `${dates?.[0]?.toISOString()}`,
                    endDate: `${dates?.[1]?.toISOString()}`,
                  })
                  setTasks(tasksFromLib?.map((task) => {
                    return {
                      ...task,
                      dueDate: dates?.[1]?.toISOString(),
                    }
                  }));
                }
              }
              suffixIcon={<Today style={{ height: '20px', color: 'var(--color-dark-navy)' }} />}
            />
          </StyledTaskContainer>
        }
        <StyledTaskContainer>
          <StyledSectionTitle>Tasks</StyledSectionTitle>
          <div>
            {
              tasks && tasks.map((task, index)=>{
                return <Task task={task} key={index} onChangeTaskDueDate={(taskDueDate)=>{
                  const updatedTasks = tasks ? [...tasks] : [];

                  if (updatedTasks[index]) {
                    updatedTasks[index] = {
                      ...updatedTasks[index],
                      dueDate: taskDueDate,
                    }
                  }

                  setTasks(updatedTasks);
                }}/>
              })
            }
          </div>
          <StyledAddTaskButtonContainer>
            <CancelButton onClick={closeTaskModal}>Cancel</CancelButton>
            <SubmitButton onClick={async () => {
              if (!mission.dueDate && dateParams.endDate !== '' && dateParams.startDate !== '') {
                await changeMissionStartDate({
                  variables: {
                    input: {
                      missionId: mission.id,
                      startAt: dateParams.startDate,
                    },
                  },
                });
                await changeMissionEndDate({
                  variables: {
                    input: {
                      missionId: mission.id,
                      dueDate: dateParams.endDate,
                    },
                  },
                });
              }
              confirmAddTaskToMission(tasks);
            }}>Confirm</SubmitButton>
          </StyledAddTaskButtonContainer>
        </StyledTaskContainer>
      </Container>
    </Modal>
  )
}
