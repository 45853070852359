import { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  GET_USERS_BY_IDS,
  GET_TEAM_MEMBERS_BY_STUDENT,
} from '@crimson-education/core-shared-graphql';
import { ASSIGN_MEMBER, UNASSIGN_MEMBER } from '@crimson-education/core-shared-graphql';
import { useApiClient } from '../../context';

import TeamsPure from './teams-pure';
import { User } from '../../types';
export type TeamsProps = {
  members: string[] | undefined;
  missionId?: string;
  onAddMission?: () => Promise<string>;
  storeDB?: boolean;
  addMember?: (data: string) => void;
  removeMember?: (data: string) => void;
  editPermitted: boolean;
  studentUserId: string;
};

export /* @__PURE__*/ const Teams = ({
  members,
  missionId,
  onAddMission,
  storeDB,
  addMember,
  removeMember,
  editPermitted,
  studentUserId,
}: TeamsProps): JSX.Element => {
  const { coreApiClient, roadmapApiClient } = useApiClient();
  const { refetch: refetchGetUserByIds } = useQuery(GET_USERS_BY_IDS, {
    client: coreApiClient,
    skip: true,
  });
  const { data } = useQuery(GET_TEAM_MEMBERS_BY_STUDENT, {
    client: coreApiClient,
    variables: {
      userId: studentUserId,
    },
  });
  const [teamMembers, setTeamMembers] = useState<
    { assigned: boolean; user: User }[]
  >([]);

  const [assignMember] = useMutation(ASSIGN_MEMBER, {
    client: roadmapApiClient,
  });
  const [unassignMember] = useMutation(UNASSIGN_MEMBER, {
    client: roadmapApiClient,
  });

  useEffect(() => {
    async function fetchData() {
      const fetchMembersInfo = async (members: string[]) => {
        const response = await refetchGetUserByIds({ userIds: members });
        const { data: usersBasicInfoData } = response;
        const missionMembers = usersBasicInfoData?.usersBasicInfo;
        return missionMembers;
      };
      const teamMembers: { assigned: boolean; user: User }[] = [];
      let membersInfo = [];
      if (members && members.length > 0) {
        membersInfo = await fetchMembersInfo(members);
        membersInfo.forEach((member: User) => {
          teamMembers.push({ assigned: true, user: member });
        });
      }
      if (data) {
        const { getTeamMembersByStudent } = data;
        getTeamMembersByStudent.forEach((member: User) => {
          const { userId } = member;
          if (!members || members.indexOf(userId) <= -1) {
            teamMembers.push({ assigned: false, user: member });
          }
        });
      }
      setTeamMembers(teamMembers);
    }
    fetchData();
  }, [members, data, refetchGetUserByIds]);
  const onAssignMember = async ({ userId }: User) => {
    if (!missionId && onAddMission) {
      missionId = await onAddMission();
    }
    addMember && addMember(userId);
    storeDB !== false &&
      (await assignMember({
        variables: {
          input: {
            missionId,
            userId,
          },
        },
      }));
    setTeamMembers(
      teamMembers.map((t) => {
        if (t.user.userId === userId) {
          t.assigned = true;
        }
        return t;
      })
    );
  };
  const onUnAssignMember = async ({ userId }: User) => {
    removeMember && removeMember(userId);
    storeDB !== false &&
      missionId &&
      (await unassignMember({
        variables: {
          input: {
            missionId,
            userId,
          },
        },
      }));
    setTeamMembers(
      teamMembers.map((t) => {
        if (t.user.userId === userId) {
          t.assigned = false;
        }
        return t;
      })
    );
  };
  return (
    <TeamsPure
      teamMembers={teamMembers}
      assignTeamMember={onAssignMember}
      unassignTeamMember={onUnAssignMember}
      editPermitted={editPermitted}
    />
  );
};
