import { MoreHoriz } from '@styled-icons/material';
import { FunctionComponent, useState, useEffect } from 'react';

import { Avatar } from '../avatar/avatar';
import * as Styled from './style';
import { TReflection, TComment } from './types';
import { CRMenu } from '../cr-menu/cr-menu';
import ReflectionInput from './reflection-input';
import CommentInput from './comment-input';
import CommentCard from './comment-card';
import { StyledClose } from '../icons';
import { useQuery, useMutation } from '@apollo/client';
import {
  GET_COMMENTS_BY_ACTION_ITEM,
  BATCH_DELETE_COMMENTS_BY_ACTION_ITEM,
  GET_USERS_BY_IDS,
  DELETE_REFLECTION,
} from '@crimson-education/core-shared-graphql';
import { motion } from 'framer-motion';
import { AnimCompShow } from '../animations';
import { User } from '../../types';
import _ from 'lodash';
import { useApiClient, useUserInfo } from '../../context';
import moment from 'moment';

export type CardMode = 'show' | 'edit' | 'removed';
interface ReflectionCardProps {
  missionId: string;
  reflection: TReflection;
  onRemove?: (reflection: TReflection) => void;
  onEdit?: (newReflection: TReflection) => void;
  editPermitted: boolean;
}
const ReflectionCard: FunctionComponent<ReflectionCardProps> = ({
  missionId,
  reflection,
  onRemove,
  onEdit,
  editPermitted
}) => {
  const { userId } = useUserInfo();
  const [hideCommentInput, SetHideCommentInput] = useState(true);
  const [comments, setComments] = useState<TComment[]>([]);

  const { coreApiClient, roadmapApiClient, studentCenterApiClient } =
    useApiClient();
  const [localReflection, setLocalReflection] = useState(reflection);
  const [mode, setMode] = useState<CardMode>('show');
  const [deleteReflection] = useMutation(DELETE_REFLECTION, {
    client: roadmapApiClient,
  });
  const [deleteCommentsByReflection] = useMutation(
    BATCH_DELETE_COMMENTS_BY_ACTION_ITEM,
    {
      client: studentCenterApiClient,
    }
  );
  const { data, refetch: fetchComments } = useQuery(
    GET_COMMENTS_BY_ACTION_ITEM,
    {
      variables: { actionItemId: reflection.id },
      client: studentCenterApiClient,
      fetchPolicy: 'network-only',
    }
  );
  const { refetch: fetchUsersByIds } = useQuery(GET_USERS_BY_IDS, {
    client: coreApiClient,
    skip: true,
  });

  const handleEdit = (reflection: TReflection) => {
    setMode('show');
    setLocalReflection(reflection);
    if (onEdit) {
      onEdit(reflection);
    }
  };

  const handleRemove = async () => {
    const { errors: delCommentErrors } = await deleteCommentsByReflection({
      variables: { actionItemId: localReflection.id },
    });

    if (!delCommentErrors?.length) {
      const { errors } = await deleteReflection({
        variables: { reflectionId: localReflection.id },
      });
      if (!errors?.length) {
        setMode('removed');
        if (onRemove) {
          onRemove(localReflection);
        }
      }
    }
  };

  useEffect(() => {
    const setCommentWithCreators = async () => {
      const userIds = _.uniq(
        data.comments.map((comment: TComment) => comment.creatorUid)
      );
      const { data: userData } = await fetchUsersByIds({ userIds });
      const userMap = userData.usersBasicInfo.reduce(
        (acc: Map<string, User>, user: User) => {
          return acc.set(user.userId, user);
        },
        new Map<string, User>()
      );
      const commentsWithCreators = data.comments.map((comment: TComment) => ({
        ...comment,
        creator: userMap.has(comment.creatorUid)
          ? userMap.get(comment.creatorUid)
          : undefined,
      }));
      const orderedComments = [
        ..._.orderBy(commentsWithCreators, ['createdAt'], ['asc']),
      ];
      setComments(orderedComments);
    };

    if (data?.comments && data?.comments.length > 0) {
      setCommentWithCreators();
    }
  }, [data?.comments, fetchUsersByIds]);

  const onCommentRemoved = (del: TComment) => {
    const items = comments.filter((item) => item.id !== del.id);
    setComments(items);
  };

  if (mode === 'removed') {
    return null;
  }

  const createdDuration = moment(new Date(localReflection.createdAt)).fromNow();
  const isUpdated =
    localReflection.descriptionUpdatedAt &&
    localReflection.createdAt !== localReflection.descriptionUpdatedAt;
  const canAccessOptions = reflection.creatorId === userId;

  return (
    <Styled.NoteContainer>
      <div style={{ display: 'flex' }}>
        <Styled.NoteCreatorContainer>
          <Avatar
            size={24}
            firstName={localReflection.creator?.firstName}
            lastName={localReflection.creator?.lastName}
            userId={localReflection.creatorId}
            image={localReflection.creator?.profileImageUrl}
          />
          <Styled.NoteCreatorName>
            {localReflection.creator?.firstName +
              (localReflection.creator?.lastName
                ? ` ${localReflection.creator.lastName}`
                : '')}
          </Styled.NoteCreatorName>
          <Styled.NoteCreatedDate>
            {createdDuration}{' '}
            {isUpdated ? (
              <span style={{ marginLeft: '4px', opacity: 0.5 }}>Edited</span>
            ) : null}
          </Styled.NoteCreatedDate>
        </Styled.NoteCreatorContainer>
        {mode === 'show' && canAccessOptions ? (
          <Styled.NoteOptionsContainer>
            <CRMenu Icon={<MoreHoriz width={16} color="#A9ACC0" />}>
              <CRMenu.Option onClick={() => setMode('edit')}>
                Edit
              </CRMenu.Option>
              <CRMenu.Option onClick={handleRemove}>Remove</CRMenu.Option>
            </CRMenu>
          </Styled.NoteOptionsContainer>
        ) : null}
        {mode === 'edit' ? (
          <motion.div animate={{ rotate: 90 }} exit={{ rotate: 0 }}>
            <StyledClose width={12} onClick={() => setMode('show')} />
          </motion.div>
        ) : null}
      </div>
      <div>
        {mode === 'show' ? (
          <Styled.NoteContent>{localReflection.description}</Styled.NoteContent>
        ) : null}
        {mode === 'edit' ? (
          <ReflectionInput
            missionId={missionId}
            reflection={localReflection}
            onInputSaved={handleEdit}
          />
        ) : null}
      </div>
      <div style={{ marginLeft: '32px', marginTop: '5px' }}>
        {comments?.length > 0 ? (
          <Styled.NoteListContainer>
            {comments.map((comment: TComment) => (
              <AnimCompShow key={comment.id}>
                <CommentCard
                  key={comment.id}
                  reflectionId={reflection.id}
                  comment={comment}
                  onRemove={onCommentRemoved}
                />
              </AnimCompShow>
            ))}
          </Styled.NoteListContainer>
        ) : null}
        {hideCommentInput
          ? editPermitted && (
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => SetHideCommentInput(false)}
              >
                Comment
              </span>
            )
          : editPermitted && (
              <CommentInput
                reflectionId={localReflection.id}
                onInputSaved={() => {
                  fetchComments();
                  SetHideCommentInput(true);
                }}
              />
            )}
      </div>
    </Styled.NoteContainer>
  );
};

export default ReflectionCard;
