export enum Categories {
  ACADEMICS = 'ACADEMICS',
  SAT = 'SAT',
  ACTIVITIES = 'ACTIVITIES',
  COMPETITIONS_HONORS = 'COMPETITIONS_HONORS',
  PERSONAL_DEVELOPMENT = 'PERSONAL_DEVELOPMENT',
  CAREERS = 'CAREERS',
  TEST = 'TEST',
}
export enum Subcategories {
  School_Academic = 'School Academics',
  University_Programs = 'University Courses & Summer Programs',
  Research = 'Academic Research & Publications',
  Portfolios = 'Creative Portfolios',
  Internship_Experience = 'Internship & Work Experience',
  Extracurricular_Activities = 'Extracurricular Activities',
  Competitions_Honor = 'Competitions Honor',
  Reading = 'Readings',
  Writing = 'Writings',
  Essential = 'Essential Skills & Experiences',
  Relationship = 'Relationship Building',
  College_Admissions_101 = 'College Admissions 101',
  Preparation = 'School Research & Preparation',
  Major = 'Major, Career and Interest Exploration',
  Networking = 'Networking',
  Honor = 'Competitions & Honors',
  Academic_Skill = 'Academic Skills & Essential Experiences',
  Subject_Interest = 'Subject Interest Development',
  NAY = 'UK Non-Application Year (NAY) Curriculum',
  NAY_UK = 'NAY Curriculum/UK Candidacy Building',
}
export type ActivityLogTemplate = {
  code: string;
  template: string;
};
export type ActivityLogType = {
  id: string;
  userId: string;
  exist: string;
  update: string;
  createdAt: string;
  template: ActivityLogTemplate;
  values: string;
};

export type MissionDetailObjectType = {
  category?: string;
  description?: string;
  dueDate?: string;
  id: string;
  members?: string[];
  startAt?: string;
  status?: string;
  subcategory?: string;
  title?: string;
  userId: string;
  activityLogs: ActivityLogType[];
  resources: MissionResource[];
  integrationSource?: string;
  linkId?: string;
  actionItems?: ActionItem[];
  isShared?: boolean;
};

export enum MissionResourceType {
  Link = 'Link',
  Video = 'Video',
}

export type MissionResource = {
  title: string;
  type: MissionResourceType;
  url: string;
};

export const missionStatusObject = {
  PLANNED: 'Planned',
  IN_PROGRESS: 'In Progress',
  DONE: 'Completed',
  EXPIRED: 'Expired',
  POSTPONED: 'Postponed',
};

export const missionLegacyStatusObject = {
  PLANNED: 'Planned',
  IN_PROGRESS: 'In Progress',
  DONE: 'Completed',
};

export const MissionCategoryNameMapping = {
  ACADEMICS: 'Academics',
  ACTIVITIES: 'Activities',
  COMPETITIONS_HONORS: 'Honors',
  PERSONAL_DEVELOPMENT: 'Personal Development',
  CAREERS: 'Majors & Careers',
  TEST: 'Testing',
};

export const MissionCategorySubcategoryMapping = {
  ACADEMICS: [
    Subcategories.School_Academic,
    Subcategories.University_Programs,
    Subcategories.Research,
    Subcategories.Portfolios,
  ],
  TEST: ['Testing'],
  ACTIVITIES: [
    Subcategories.Extracurricular_Activities,
    Subcategories.University_Programs,
    Subcategories.Research,
    Subcategories.Internship_Experience,
  ],
  COMPETITIONS_HONORS: [Subcategories.Competitions_Honor],
  PERSONAL_DEVELOPMENT: [
    Subcategories.Reading,
    Subcategories.Writing,
    Subcategories.Essential,
    Subcategories.Relationship,
    Subcategories.NAY,
    Subcategories.Academic_Skill,
    Subcategories.College_Admissions_101,
  ],
  CAREERS: [
    Subcategories.Preparation,
    Subcategories.Major,
    Subcategories.Networking,
  ],
};

export const MissionCategoryOptions = [
  {
    value: 'ACADEMICS',
    label: 'Academics',
  },
  {
    value: 'TEST',
    label: 'Testing',
  },
  {
    value: 'ACTIVITIES',
    label: 'Activities',
  },
  {
    value: 'COMPETITIONS_HONORS',
    label: 'Honors',
  },
  {
    value: 'PERSONAL_DEVELOPMENT',
    label: 'Personal Development',
  },
  {
    value: 'CAREERS',
    label: 'Majors & Careers',
  },
];

export const MissionSubCategoryMapping = {
  'University Courses & Summer Programs': 'program',
  'Academic Research & Publications': 'research',
  'Creative Portfolios': 'project',
};
export enum ActionItemStatus {
  PLANNED = 'PLANNED',
  DONE = 'DONE',
}

export type MissionObjectType = {
  actionItems?: ActionItem[];
  attr?: string;
  badge?: string;
  category?: string;
  description?: string;
  dueDate?: string;
  // experience?: Experience;
  // extracurricular?: ExtracurricularActivity;
  // honor?: Honor;
  id: string;
  integrationSource?: string;
  members?: string[];
  // otherSubcategory?: OtherSubcategories;
  // portfolios?: StudentPortfolios;
  // program?: StudentPrograms;
  // reading?: Reading;
  // research?: StudentResearch;
  // schoolAcademic?: StudentAcademic;
  secondaryCategory?: string;
  startAt?: string;
  status?: string;
  subcategory?: string;
  // subjects?: Subjects[];
  title?: string;
  userId: string;
};
export type ActionItemResource = {
  createdAt: string;
  id: string;
  mediaType?: string;
  orderIndex: number;
  title: string;
  type: string;
  url: string;
  updatedAt: string;
};
export type ActionItem = {
  id: string;
  description: string;
  finishedAt?: string;
  creatorId: string;
  createdAt: string;
  dueDate?: string;
  startAt?: string;
  status?: ActionItemStatus;
  mission?: MissionObjectType;
  type?: string;
  taskGroup?: string;
  attr?: {
    title: string;
  };
  content?: string;
  resources?: ActionItemResource[];
};

export type MissionChangeActions =
  | 'ChangeActionItemStatus'
  | 'DeleteActionItem'
  | 'AddActionItem'
  | 'ChangeMissionStatus';
