import { useState, MouseEvent } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import {
  StyledContainer,
  StyledPopover,
  StyledModalTitle,
  StyledModalDesc,
  StyledButtonGroup,
  StyledModalContainer,
  SubmitButton,
  CancelButton,
} from './style';
import { Modal, Popover } from 'antd';
export /* @__PURE__*/ const RemoveConfirmModal = ({
  onClose,
  onConfirm,
  removeModalText,
  removeModalDesc = 'All the related data will be removed once done.',
  title = 'Are you sure',
}: {
  onClose: () => void;
  onConfirm: () => void;
  removeModalText: string;
  removeModalDesc?: string | null;
  title?: string;
}): JSX.Element => {
  return (
    <StyledModalContainer>
      <StyledModalTitle>{title}</StyledModalTitle>
      <StyledModalDesc>
        <div>{removeModalText}</div>
        {removeModalDesc && <div>{removeModalDesc}</div>}
      </StyledModalDesc>
      <StyledButtonGroup>
        <CancelButton width={150} onClick={onClose}>
          Cancel
        </CancelButton>
        <SubmitButton
          width={150}
          type="primary"
          htmlType="submit"
          onClick={onConfirm}
        >
          Confirm
        </SubmitButton>
      </StyledButtonGroup>
    </StyledModalContainer>
  );
};
export /* @__PURE__*/ const RemoveButton = ({
  removeButtonText,
  removeModalText,
  onConfirm,
  Icon = <MoreOutlined style={{ color: '#A9ACC0', fontSize: '24px' }} />,
  setEditMode,
  removeModalDesc,
  isEditing,
}: {
  removeButtonText: string;
  removeModalText: string;
  onConfirm: () => void;
  Icon?: React.ReactNode;
  setEditMode?: () => void;
  removeModalDesc?: string | null;
  isEditing?: boolean;
}): JSX.Element => {
  const [showPopover, setShowPopover] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const openModal = () => setConfirmModalOpen(true);
  const closeModal = () => setConfirmModalOpen(false);
  const togglePopover = (e: MouseEvent) => {
    setShowPopover(!showPopover);
    e.stopPropagation();
  };
  const onModalConfirm = () => {
    onConfirm();
    closeModal();
  };
  return (
    <StyledContainer>
      <Popover
        showArrow={false}
        open={showPopover}
        onOpenChange={setShowPopover}
        trigger={['click']}
        overlayInnerStyle={{
          padding: 0,
          background: '#f3f6fa',
          border: '1px solid #e3e7ed',
        }}
        content={
          <div>
            {!isEditing && setEditMode && <div onClick={()=>{
              setEditMode?.();
              setShowPopover(false);
            }} style={{ cursor: 'pointer', color:'#73747D'}}>Edit</div>}
            <StyledPopover onClick={openModal} >
              {removeButtonText}
            </StyledPopover>
          </div>
        }
      >
        <div onClick={togglePopover}>{Icon}</div>
      </Popover>
      <Modal
        footer={null}
        width={500}
        open={confirmModalOpen}
        onCancel={closeModal}
      >
        <RemoveConfirmModal
          onClose={closeModal}
          removeModalText={removeModalText}
          onConfirm={onModalConfirm}
          removeModalDesc={removeModalDesc}
        />
      </Modal>
    </StyledContainer>
  );
};
