import styled from 'styled-components';

export const Text = styled.div`
  font-size: 12px;
  line-height: 16px;
  text-decoration: underline;
`;

export const Container = styled.a`
  align-items: center;
  display: flex;
  gap: 8px;

  &:hover ${Text} {
    text-decoration: none;
  }
`;
