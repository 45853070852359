import { FunctionComponent, MouseEventHandler, useMemo, useState } from 'react';
import * as Styled from './style';
import { Avatar } from '../avatar/avatar';
import { Button, Input } from 'antd';
import { Send } from '@styled-icons/material/Send';
import { CR_COLORS } from '../../utils/theme';
import { TInternalNote } from './type';
import { useMutation } from '@apollo/client';
import {
  CREATE_INTERNAL_NOTE,
  UPDATE_INTERNAL_NOTE,
} from '@crimson-education/core-shared-graphql';
import { Cascader } from 'antd';
import { NOTE_INPUT_OPTIONS } from '../../constants';
import { ArrowDropDown } from '@styled-icons/material';
import { InternalNotesCategory } from '../../types';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useApiClient, useUserInfo } from '../../context';
const loadingIcon = <LoadingOutlined style={{ fontSize: 12 }} spin />;

type InputNoteMode = 'edit' | 'new';
interface NoteInputPros {
  note?: TInternalNote;
  missionId?: string;
  studentId: string;
  onNoteSaved: (note: TInternalNote) => void;
  type?: 'Cascader' | undefined;
  editPermitted: boolean;
}
const NoteInput: FunctionComponent<NoteInputPros> = ({
  onNoteSaved,
  note,
  studentId,
  missionId,
  type,
  editPermitted
}) => {
  const { firstName, lastName, profileImageUrl, userId } = useUserInfo();
  const { studentCenterApiClient } = useApiClient();
  const [createInternalNote] = useMutation(CREATE_INTERNAL_NOTE, {
    client: studentCenterApiClient,
  });
  const [updateInternalNote] = useMutation(UPDATE_INTERNAL_NOTE, {
    client: studentCenterApiClient,
  });

  const [noteValue, setNoteValue] = useState(note?.content || '');
  const canSubmitNote = useMemo(() => !!noteValue, [noteValue]);
  const [submittingNote, setSubmittingNote] = useState(false);
  const mode: InputNoteMode = useMemo(() => (note ? 'edit' : 'new'), [note]);
  const [eventType, setEventType] = useState<(string | number)[]>([
    InternalNotesCategory.General,
  ]);
  const submitNote: MouseEventHandler = async (e) => {
    e.stopPropagation();
    const operation = note?.id ? updateInternalNote : createInternalNote;
    const input: Partial<TInternalNote> = {
      studentUid: studentId,
      content: noteValue.trim(),
      missionId,
    };
    if (note) {
      input.id = note.id;
    }
    if (type && type === 'Cascader' && eventType && eventType.length > 0) {
      input.category = eventType[0] as keyof typeof InternalNotesCategory;
      if (eventType.length > 1) {
        input.subCategory = eventType[1] as string;
      }
    }
    if (noteValue) {
      setSubmittingNote(true);
      const { data, errors } = await operation({ variables: { input } });
      setSubmittingNote(false);
      if (errors?.length) return;
      setNoteValue('');
      onNoteSaved({
        ...data.note,
        createdAt: new Date(data.note.createdAt),
        updatedAt: new Date(data.note.updatedAt),
        creator: {
          firstName,
          lastName,
          userId,
          profileImageUrl,
        },
      });
    }
  };

  if (!userId) {
    return null;
  }
  return (
    <Styled.NoteInputContainer>
      {mode === 'new' ? (
        <Avatar
          key={userId}
          image={profileImageUrl || undefined}
          userId={userId}
          firstName={firstName}
          lastName={lastName}
          size={24}
        />
      ) : (
        <div style={{ width: 30 }}></div> // Spacer
      )}
      {type && type === 'Cascader' ? (
        <Styled.EventNoteInput>
          <Cascader
            options={NOTE_INPUT_OPTIONS}
            value={eventType}
            defaultValue={[InternalNotesCategory.General]}
            onChange={(value) => {
              setEventType(value);
            }}
            allowClear={false}
            suffixIcon={<ArrowDropDown width={20} />}
          />
          <Input.TextArea
            value={noteValue}
            disabled={editPermitted ? submittingNote : !editPermitted}
            onChange={(e) => setNoteValue(e.target.value)}
            placeholder="Write a comment"
            autoSize
          />
          <Button
            disabled={!canSubmitNote || submittingNote || !noteValue.trim()}
            onClick={submitNote}
            shape="circle"
          >
            {submittingNote ? (
              <Spin indicator={loadingIcon} />
            ) : (
              <Send
                height={24}
                color={CR_COLORS.LIGHT_PURPLE}
                opacity={
                  canSubmitNote && !submittingNote && noteValue.trim() ? 1 : 0.4
                }
              />
            )}
          </Button>
        </Styled.EventNoteInput>
      ) : (
        <>
          <Input.TextArea
            value={noteValue}
            disabled={editPermitted ? submittingNote : !editPermitted}
            onChange={(e) => setNoteValue(e.target.value)}
            placeholder="Add an internal note"
            autoSize
          />
          <Button
            disabled={!canSubmitNote || submittingNote || !noteValue.trim()}
            onClick={submitNote}
            shape="circle"
          >
            {submittingNote ? (
              <Spin indicator={loadingIcon} />
            ) : (
              <Send
                height={24}
                color={CR_COLORS.LIGHT_PURPLE}
                opacity={
                  canSubmitNote && !submittingNote && noteValue.trim() ? 1 : 0.4
                }
              />
            )}
          </Button>
        </>
      )}
    </Styled.NoteInputContainer>
  );
};

export default NoteInput;
