import { gql } from '@apollo/client';

export const GET_MISSION_BY_ID_FOR_LIB = gql`
  query mission($missionId: String!) {
    mission(missionId: $missionId) {
      id
      title
      description
      category
      subcategory
      isShared
      actionItems {
        id
        description
        content
        resources {
          id
          mediaType
          orderIndex
          title
          type
          url
        }
      }
    }
  }
`;
