import { NavigateNext } from '@styled-icons/material';
import { FunctionComponent, useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  GET_REFLECTIONS_BY_MISSION,
  BATCH_UPDATE_REFLECTIONS_BY_MISSION,
  GET_USERS_BY_IDS,
} from '@crimson-education/core-shared-graphql';
import moment from 'moment';
import * as Styled from './style';
import ReflectionInput from './reflection-input';
import { AnimCompShow } from '../animations';
import { ExpandMoreIcon } from '../icons';
import { TReflection } from './types';
import { User } from '../../types';
import ReflectionCard from './reflection-card';
import _ from 'lodash';
import { useApiClient } from '../../context';

interface ReflectionsProps {
  missionId: string;
  editPermitted: boolean;
}

export /* @__PURE__*/ const Reflections: FunctionComponent<ReflectionsProps> = ({
  missionId,
  editPermitted,
}) => {
  const [checked, setChecked] = useState(false);
  const [startAt, setStartAt] = useState<moment.Moment | null>();
  const [dueDate, setDueDate] = useState<moment.Moment | null>();
  const [reflections, setReflections] = useState<TReflection[]>([]);
  const [visibleCount, setVisibleCount] = useState(1);

  const { coreApiClient, roadmapApiClient } = useApiClient();
  const { data, refetch: fetchReflections } = useQuery(
    GET_REFLECTIONS_BY_MISSION,
    {
      variables: { missionId },
      client: roadmapApiClient,
      fetchPolicy: 'network-only',
    }
  );
  const { refetch: fetchUsersByIds } = useQuery(GET_USERS_BY_IDS, {
    client: coreApiClient,
    skip: true,
  });
  const [batchUpdateReflectionsByMission] = useMutation(
    BATCH_UPDATE_REFLECTIONS_BY_MISSION,
    {
      client: roadmapApiClient,
    }
  );

  useEffect(() => {
    const setReflectionsWithCreators = async () => {
      const userIds = _.uniq(
        data.reflections.map((reflection: TReflection) => reflection.creatorId)
      );
      const { data: userData } = await fetchUsersByIds({ userIds });
      const userMap = userData.usersBasicInfo.reduce(
        (acc: Map<string, User>, user: User) => {
          return acc.set(user.userId, user);
        },
        new Map<string, User>()
      );
      const reflectionsWithCreators = data.reflections.map(
        (reflection: TReflection) => ({
          ...reflection,
          creator: userMap.has(reflection.creatorId)
            ? userMap.get(reflection.creatorId)
            : undefined,
        })
      );
      const orderedReflections = [
        ..._.orderBy(reflectionsWithCreators, ['createdAt'], ['desc']),
      ];
      setReflections(orderedReflections);

      const latest = orderedReflections[0] as TReflection;
      setChecked(latest.status === 'DONE');
      setStartAt(latest.startAt ? moment(latest.startAt) : null);
      setDueDate(latest.dueDate ? moment(latest.dueDate) : null);
    };

    if (data?.reflections && data?.reflections.length > 0) {
      setReflectionsWithCreators();
    } else {
      setReflections([]);
    }
  }, [data?.reflections, fetchUsersByIds]);

  const onClickViewMore = () => {
    setVisibleCount(visibleCount + 1);
  };

  return (
    <div>
      <Styled.TitleContainer>
        <Styled.TitleLeft>Reflections</Styled.TitleLeft>
        <Styled.TitleRight>
          {reflections?.length > 0 && editPermitted && (
            <Styled.Checkbox
              onChange={(e) => {
                const value = e.target.checked;
                setChecked(value);
                batchUpdateReflectionsByMission({
                  variables: { missionId, status: value ? 'DONE' : 'PLANNED' },
                });
              }}
              checked={checked}
            >
              <span
                style={{
                  fontSize: '14px',
                  color: checked ? '#464ac9' : '#a9acc0',
                }}
              >
                Completed
              </span>
            </Styled.Checkbox>
          )}
          {reflections?.length > 0 && editPermitted && (
            <Styled.RangePickerPopover
              trigger="hover"
              content={
                <Styled.RangePicker
                  allowEmpty={[true, true]}
                  bordered={false}
                  format="MMM DD, YYYY"
                  allowClear={false}
                  value={[startAt ? startAt : null, dueDate ? dueDate : null]}
                  onChange={async (dates) => {
                    if (dates) {
                      setStartAt(dates[0] || null);
                      setDueDate(dates[1] || null);
                      batchUpdateReflectionsByMission({
                        variables: {
                          missionId,
                          startAt: dates[0]?.toISOString(),
                          dueDate: dates[1]?.toISOString(),
                        },
                      });
                    }
                  }}
                />
              }
            >
              <Styled.ChangeDate>
                {dueDate ? (
                  `${moment(dueDate).format('MMM DD, YYYY')}`
                ) : (
                  <span style={{ color: '#a9acc0' }}>Set date</span>
                )}
                <NavigateNext height={20} />
              </Styled.ChangeDate>
            </Styled.RangePickerPopover>
          )}
        </Styled.TitleRight>
      </Styled.TitleContainer>
      {editPermitted && (
        <ReflectionInput
          missionId={missionId}
          onInputSaved={() => fetchReflections()}
        />
      )}
      {reflections?.length > 0 ? (
        <Styled.NoteListContainer>
          {reflections.slice(0, visibleCount).map((reflection: TReflection) => (
            <AnimCompShow key={reflection.id}>
              <ReflectionCard
                editPermitted={editPermitted}
                missionId={missionId}
                key={reflection.id}
                reflection={reflection}
                onRemove={() => fetchReflections()}
              />
            </AnimCompShow>
          ))}
          {reflections?.length > visibleCount ? (
            <Styled.ViewMoreContainer>
              <Styled.ViewMoreButton onClick={onClickViewMore}>
                View More
                <ExpandMoreIcon width={20} />
              </Styled.ViewMoreButton>
            </Styled.ViewMoreContainer>
          ) : null}
        </Styled.NoteListContainer>
      ) : null}
    </div>
  );
};
