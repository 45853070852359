import type { UploadFile } from 'antd';
import { UploadFileSystem } from './upload-file';
import {
  ApolloQueryResult,
  OperationVariables,
  useMutation,
  useQuery,
} from '@apollo/client';
import { useEffect, useState } from 'react';
import {
  CHANGE_ACTION_ITEM,
  DELETE_ACTION_ITEM,
  GET_SC_UPLOAD_URL,
  SAVE_FILE,
} from '@crimson-education/core-shared-graphql';
import sanitize from 'sanitize-filename';
import { FileRefType } from '@crimson-education/common-config';
import { notification } from 'antd';
import { RemoveButton } from '../remove-button/remove-button';

import {
  ActionItem as ActionItemType,
  ActionItemStatus,
  MissionChangeActions,
} from '../../types';
import { useApiClient } from '../../context';

import {
  Checkbox,
  DescSpan,
  ContentSpan,
  TaskTextArea,
  RemoveButtonContainer,
  StyledSaveButton,
} from './style';
import { FileType } from './action-items-rework';
import moment from 'moment';
import { RichTextEditor } from '../rich-text-editor/rich-text-editor';
import { RichTextViewer } from '../rich-text-editor/rich-text-viewer';

export const ActionItemRework = ({
  item,
  onCheckActionItem,
  checkedProps,
  editPermitted,
  loadActionItems,
}: {
  item: ActionItemType;
  loadActionItems?: () => void;
  onMissionChange?: (action: MissionChangeActions) => void;
  onCheckActionItem: (id: string, checked: boolean) => void;
  checkedProps: boolean;
  editPermitted?: boolean;
}) => {
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    setChecked(checkedProps);
  }, [checkedProps]);

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const onChangeFileList = (fileList: UploadFile[]) => {
    setFileList(fileList);
  };
  const [isEditing, setIsEditing] = useState(false);
  const [description, setDescription] = useState('');
  const [content, setContent] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const canSubmit = description !== '' && !isSubmitting;
  const { roadmapApiClient, coreApiClient } = useApiClient();

  const { refetch: getStudentCenterUploadUrl } = useQuery(GET_SC_UPLOAD_URL, {
    client: coreApiClient,
    skip: true,
  });
  const [saveFile] = useMutation(SAVE_FILE, {
    client: coreApiClient,
  });
  const [changeActionItem] = useMutation(CHANGE_ACTION_ITEM, {
    client: roadmapApiClient,
  });
  const [deleteActionItem] = useMutation(DELETE_ACTION_ITEM, {
    client: roadmapApiClient,
  });
  useEffect(() => {
    if (item) {
      setDescription(item.description);
      if (item.content) {
        setContent(item.content);
      }
    }
  }, [item]);
  const saveActionItem = async () => {
    const finalList = [];
    const resources = item.resources;
    if (fileList && fileList.length > 0) {
      let addedItems;
      if (resources && resources.length > 0) {
        //compare with resources stored in DB. find what has been added. if the added file is upload, need to do a manual upload
        addedItems = fileList.filter(
          (file) => !resources.some((resource) => resource.id === file.uid),
        );
      } else {
        //there is no resources in DB. all files are added
        addedItems = fileList;
      }
      for (let i = 0; i < fileList.length; i++) {
        // files here all need to be added to API
        const file = fileList[i];
        if (file.type === 'Link') {
          // no need to upload, just add to finalList
          finalList.push({
            type: 'Link',
            url: file.url,
            title: file.name,
            mediaType: 'link',
            orderIndex: i,
          });
        } else {
          if (
            addedItems &&
            addedItems.length > 0 &&
            addedItems.some((addedItem) => addedItem.uid === file.uid)
          ) {
            //prepare to upload
            const { name, type, size } = file;
            const sanitized = sanitize(name);
            const encoded = encodeURIComponent(sanitized);
            const formData = new FormData();
            formData.append('files[]', file as FileType);
            try {
              const res = await getStudentCenterUploadUrl({
                refId: item.id,
                fileName: encoded,
                contentType: type,
              });
              const { putUrl, key } = res.data.getStudentCenterUploadUrl;
              await fetch(putUrl, {
                method: 'PUT',
                body: formData,
              });
              const result = await saveFile({
                variables: {
                  location: key,
                  sha512: ' ',
                  name: name,
                  size: `${size}`,
                  type: type,
                  refId: item.id,
                  refType: FileRefType.ACADEMIC,
                },
              });
              if (result?.data?.saveFileUplRef?.id) {
                finalList.push({
                  type: 'Upload',
                  url: result?.data?.saveFileUplRef?.id, //putUrl cannot be used directly since it's a temporary url. need to save the location info to DB. store id in DB. and then get the download url by id
                  title: file.name,
                  mediaType: file.type,
                  orderIndex: i,
                });
              }
            } catch {
              notification.error({
                message: 'Upload File Error',
              });
            }
          } else {
            finalList.push({
              type: 'Upload',
              url: file.url,
              title: file.name,
              mediaType: file.linkProps ? file.linkProps : file.type,
              orderIndex: i,
            });
          }
        }
      }
    }
    const input: any = {
      actionItemId: item.id,
      description,
      content,
      resources: finalList,
      dueDate: moment(),
      startAt: moment(),
    };
    await changeActionItem({
      variables: {
        input,
      },
    });
    loadActionItems?.();
    notification.success({
      message: 'Edit Successfully!',
    });
  };
  return (
    <div style={{ borderTop: '1px solid #E3E7ED', paddingTop: '16px' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        <Checkbox
          onChange={async (e) => {
            const value = e.target.checked;
            setChecked(value);
            onCheckActionItem(item.id, value);
          }}
          checked={checked}
        />
        <div style={{ flex: '1 1 auto', marginRight: 20 }}>
          {isEditing ? (
            <TaskTextArea
              autoSize={{ minRows: 1, maxRows: 7 }}
              autoFocus
              disabled={!editPermitted}
              value={description}
              placeholder="Add a task title"
              bordered={false}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          ) : (
            <DescSpan checked={false}>{item.description}</DescSpan>
          )}
        </div>
        {editPermitted && (
          <RemoveButtonContainer>
            <RemoveButton
              isEditing={isEditing}
              onConfirm={async () => {
                await deleteActionItem({
                  variables: {
                    actionItemId: item.id,
                  },
                });
                loadActionItems?.();
              }}
              removeButtonText="Remove"
              removeModalText="Are you sure you want to remove this task from the mission?"
              removeModalDesc="Once removed, it won't be available in the mission & task library."
              setEditMode={() => {
                setIsEditing(true);
              }}
            />
          </RemoveButtonContainer>
        )}
      </div>
      <div style={{ paddingLeft: '38px' }}>
        <div>
          {isEditing ? (
            <RichTextEditor
              content={content}
              onChange={(e) => {
                setContent(e);
              }}
            />
          ) : item.content ? (
            <RichTextViewer content={item.content} />
          ) : null}
        </div>
        {(isEditing || (item.resources && item.resources.length > 0)) && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingRight: '30px',
            }}
          >
            <UploadFileSystem
              mode={isEditing ? 'edit' : 'show'}
              resources={item.resources}
              onChangeFileList={onChangeFileList}
            />
            {isEditing && (
              <StyledSaveButton
                loading={isSubmitting}
                disabled={!canSubmit}
                onClick={async () => {
                  setIsSubmitting(true);
                  await saveActionItem();
                  setIsSubmitting(false);
                  setIsEditing(false);
                }}
              >
                Save
              </StyledSaveButton>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
