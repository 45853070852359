import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  display: inline-block;
  position: relative;
`;

export const Button = styled(motion.button)`
  background: none;
  cursor: pointer;
  border: none;
  padding: 0;
`;

export const Menu = styled.div`
  cursor: pointer;
  position: absolute;
  transform-origin: top right;
  background: #f3f6fa; // theme color: mist
  border: 1px solid #e3e7ed; // theme color: pale grey
  border-radius: 4px;
  color: #73747d; // theme color: stone
  z-index: 1;
  width: max-content;
  top: 25px;
  left: -60px;
`;

export const MenuOption = styled.div`
  cursor: pointer;
  padding: 4px 16px;
  font-size: var(--font-size-small);
  :hover {
    background: rgba(0, 0, 0, 0.03);
  }
`;
