import { createContext } from 'react';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
export type ApiClientContextType = {
  coreApiClient: ApolloClient<NormalizedCacheObject>;
  roadmapApiClient: ApolloClient<NormalizedCacheObject>;
  studentCenterApiClient: ApolloClient<NormalizedCacheObject>;
  pathfinderApiClient: ApolloClient<NormalizedCacheObject>;
};

export /* @__PURE__*/ const ApiClientContext = createContext<ApiClientContextType>(
  null as unknown as ApiClientContextType
);
ApiClientContext.displayName = 'Shared Ui Api Client Context';
