export const EmptyPlaceHolder = (): JSX.Element => (
  <svg width={380} height={250} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M193.179 246.296h67.38a7.32 7.32 0 0 0 4.998-1.959 57.042 57.042 0 0 0 13.343-18.805 57.331 57.331 0 0 0 4.837-22.586c.29-31.641-25.277-57.677-56.73-57.752C195.536 145.12 170 170.763 170 202.407a57.35 57.35 0 0 0 4.734 22.886 57.053 57.053 0 0 0 13.461 19.055 7.299 7.299 0 0 0 4.984 1.948Z"
      fill="#F4F5FF"
    />
    <path
      d="M242.5 145.692h-4.562a4.963 4.963 0 0 1-4.459-2.879 6.692 6.692 0 0 0-6.043-3.813h-1.133v.098a6.695 6.695 0 0 0-4.929 3.758 4.908 4.908 0 0 1-4.438 2.836h-4.564a2.446 2.446 0 0 0-2.445 2.445v3.952a2.441 2.441 0 0 0 2.445 2.445H242.5a2.441 2.441 0 0 0 2.445-2.445v-3.952a2.441 2.441 0 0 0-2.445-2.445Z"
      fill="#417A93"
    />
    <path
      opacity={0.15}
      d="M242.5 145.692h-4.562a4.963 4.963 0 0 1-4.459-2.879 6.692 6.692 0 0 0-6.043-3.813h-1.133v.098a6.695 6.695 0 0 0-4.929 3.758 4.908 4.908 0 0 1-4.438 2.836h-4.564a2.446 2.446 0 0 0-2.445 2.445v3.952a2.441 2.441 0 0 0 2.445 2.445H242.5a2.441 2.441 0 0 0 2.445-2.445v-3.952a2.441 2.441 0 0 0-2.445-2.445Z"
      fill="#000"
    />
    <path
      d="M259.186 150.347h-62.823a3.393 3.393 0 0 0-3.394 3.394v89.173a3.393 3.393 0 0 0 3.394 3.394h62.823a3.394 3.394 0 0 0 3.394-3.394v-89.173a3.394 3.394 0 0 0-3.394-3.394Z"
      fill="#D4D9E3"
    />
    <path
      d="M257.28 150.347h-62.823a3.393 3.393 0 0 0-3.394 3.394v89.173a3.393 3.393 0 0 0 3.394 3.394h62.823a3.394 3.394 0 0 0 3.394-3.394v-89.173a3.394 3.394 0 0 0-3.394-3.394Z"
      fill="#E5E9F1"
    />
    <path
      d="M250.394 240.76H201.63a4.511 4.511 0 0 1-4.512-4.512v-75.989a4.513 4.513 0 0 1 4.512-4.511h38.703l14.572 14.572v65.928a4.509 4.509 0 0 1-4.511 4.512Z"
      fill="#fff"
    />
    <path d="M255.087 170.32h-11.254a3.317 3.317 0 0 1-3.318-3.317v-11.255l14.572 14.572Z" fill="#D4D9E3" />
    <path
      d="M207.179 169.799h18.88a1.73 1.73 0 1 1 0 3.464h-18.88a1.733 1.733 0 0 1 0-3.464ZM207.179 181.161h38.125a1.733 1.733 0 0 1 0 3.464h-38.125a1.728 1.728 0 0 1-1.732-1.732 1.735 1.735 0 0 1 1.732-1.732ZM245.304 192.524h-38.125a1.733 1.733 0 0 0 0 3.464h38.125a1.731 1.731 0 1 0 0-3.464ZM245.304 203.887h-38.125a1.732 1.732 0 0 0 0 3.464h38.125a1.732 1.732 0 0 0 0-3.464ZM245.443 215.249h-38.264a1.732 1.732 0 0 0 0 3.464h38.264a1.731 1.731 0 1 0 0-3.464ZM207.179 226.612h20.613a1.73 1.73 0 0 1 1.6 1.07 1.73 1.73 0 0 1-1.6 2.394h-20.613a1.72 1.72 0 0 1-1.224-.507 1.734 1.734 0 0 1-.508-1.225c0-.459.183-.9.508-1.224a1.73 1.73 0 0 1 1.224-.508Z"
      fill="#E5E9F1"
    />
    <path
      d="M241.276 145.692h-4.562a4.963 4.963 0 0 1-4.459-2.879 6.698 6.698 0 0 0-6.043-3.813 6.691 6.691 0 0 0-6.042 3.813 4.965 4.965 0 0 1-4.46 2.879h-4.562a2.446 2.446 0 0 0-2.445 2.445v9.319a2.446 2.446 0 0 0 2.445 2.445h30.128a2.446 2.446 0 0 0 2.446-2.445v-9.319a2.442 2.442 0 0 0-2.446-2.445Z"
      fill="#8B8FFF"
    />
    <path
      opacity={0.2}
      d="M239.869 156.288h-27.043a.965.965 0 0 0 0 1.928h27.043a.964.964 0 0 0 0-1.928Z"
      fill="#fff"
    />
    <path
      d="m248.285 241.727-1.556 4.932a19.648 19.648 0 0 1-1.06 2.668l-.211.436a.375.375 0 0 1-.652.044.374.374 0 0 1-.061-.252c1.707-14.055 10.424-31.042 23.01-49.189l.636 2.776-2.585 5.703"
      fill="#D4D9E3"
    />
    <path
      d="M252.055 211.337a17.865 17.865 0 0 0 1.559 3.998c-1.256-5.449-.126-10.761 3.434-15.934a1.405 1.405 0 0 1 2.461.254c.308.795.754 1.53 1.317 2.17 3.906-7.716 8.915-12.796 16.015-12.869a1.405 1.405 0 0 1 1.088.496 1.407 1.407 0 0 1 .317 1.152c-1.206 7.163-4.8 13.349-10.572 18.647a19.676 19.676 0 0 0 6.785-2.349.65.65 0 0 1 .852.928c-5.598 8.385-12.261 15.007-20.553 18.928 1.645.181 3.361-.305 5.131-1.295a.932.932 0 0 1 1.349.584.931.931 0 0 1-.03.555c-2.632 6.93-7.145 11.811-12.927 15.31 2.342-8.304 7.094-19.121 14.35-31.217a57.013 57.013 0 0 0 3.585-7.035.287.287 0 0 0 .006-.208.287.287 0 0 0-.342-.186.288.288 0 0 0-.172.119 171.968 171.968 0 0 0-19.728 39.816c-3.252-4.259-4.665-9.629-3.198-16.741a1.408 1.408 0 0 1 .962-1.043 1.4 1.4 0 0 1 1.38.33 8.427 8.427 0 0 0 1.912 1.353c-1.542-5.926-1.084-11.425 2.54-16.252a1.405 1.405 0 0 1 2.479.489Z"
      fill="#6A62EA"
    />
    <path
      d="M7.846 160.761c-2.434 5.034-5.565 11.879-7.091 17.171m0 0c-.177.61 4.59.42 4.81.393 4.813-.591 9.414-1.789 14.079-3.108m-18.89 2.715c18.892-23.929 48.73-31.498 67.321-20.946 1.843 1.046 3.847 2.187 5.737 3.501m0 0c3.01 2.094 5.728 4.63 7.04 7.931 1.211 3.047 1.485 7.549-.71 10.27-4.103 5.083-12.221 2.396-13.703-3.611-1.302-5.278 2.283-12.577 7.373-14.59Zm0 0c.107-.042.215-.082.324-.12 28.401-9.85 52.4 3.356 60.4 24.356M379.395 11.202c-1.78-1.163-9.777-9.855-10.379-9.99m0 0c-.515-.116-1.435 5.922-2.193 11.968m2.193-11.968c13.587 24.33-9.079 54.11-22.967 67.014C329.25 83.836 299.674 91 288.674 111"
      stroke="#A9ACC0"
      strokeMiterlimit={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="2 2"
    />
  </svg>
);

export const GoldMedal = ({ style }: { style: React.CSSProperties }): JSX.Element => (
  <div style={style}>
    <svg width={22} height={22} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 11c0 5.523-4.477 10-10 10S1 16.523 1 11 5.477 1 11 1s10 4.477 10 10Z" fill="#FFBB0D" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.74 11c0 5.933-4.808 10.741-10.74 10.741S.26 16.933.26 11.001 5.067.26 11 .26 21.74 5.069 21.74 11ZM11 21c5.523 0 10-4.477 10-10 0-5.522-4.477-10-10-10S1 5.479 1 11c0 5.523 4.477 10 10 10Z"
        fill="#E89B05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.74 11.371a.37.37 0 0 1-.37-.37A9.63 9.63 0 0 1 11 1.37a.37.37 0 0 1 0 .74 8.889 8.889 0 0 0-8.889 8.89.37.37 0 0 1-.37.37ZM20.26 10.63a.37.37 0 0 1 .37.37A9.63 9.63 0 0 1 11 20.63a.37.37 0 0 1 0-.74c4.91 0 8.889-3.98 8.889-8.89a.37.37 0 0 1 .37-.37Z"
        fill="#E8AA0A"
      />
      <path d="M18.407 11a7.407 7.407 0 1 1-14.814 0 7.407 7.407 0 0 1 14.814 0Z" fill="#FFDD28" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.148 11a8.148 8.148 0 1 1-16.296 0 8.148 8.148 0 0 1 16.296 0ZM11 18.409a7.407 7.407 0 1 0 0-14.815 7.407 7.407 0 0 0 0 14.815Z"
        fill="#E89B05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 17.296A6.296 6.296 0 0 0 17.296 11a.37.37 0 1 1 .741 0A7.037 7.037 0 0 1 11 18.037a.37.37 0 1 1 0-.74Z"
        fill="#ECC704"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 4.705A6.296 6.296 0 0 0 4.704 11a.37.37 0 1 1-.741 0A7.037 7.037 0 0 1 11 3.964a.37.37 0 1 1 0 .74Z"
        fill="#FFEF9C"
      />
      <path
        d="m7.64 10.328 2.2-.448.66-1.12.66-1.12 1.32 2.24 2.2.448-1.76 1.792.44 2.24-2.2-1.12-2.2 1.12.44-2.24-1.76-1.792Z"
        fill="#FFEF9C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m9.84 9.88 1.32-2.24 1.32 2.24 2.2.448-1.76 1.792.44 2.24-2.2-1.12-2.2 1.12.44-2.24-1.76-1.792 2.2-.448Zm-1.133 2.448-1.524-1.551a.64.64 0 0 1 .33-1.076l1.92-.391 1.176-1.995a.64.64 0 0 1 1.102 0l1.176 1.995 1.92.39a.64.64 0 0 1 .33 1.077l-1.524 1.55.375 1.91a.64.64 0 0 1-.918.693l-1.91-.972-1.91.972a.64.64 0 0 1-.918-.693l.375-1.91Z"
        fill="#E89B05"
      />
    </svg>
  </div>
);

export const CheckCircle = ({ style }: { style: React.CSSProperties }): JSX.Element => (
  <div style={style}>
    <svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.77 10.896 5.73 8.854a.623.623 0 0 0-.46-.188.623.623 0 0 0-.457.188.67.67 0 0 0-.209.49c0 .187.063.343.188.468l2.541 2.542a.592.592 0 0 0 .438.167c.18 0 .326-.056.437-.167l5-5a.659.659 0 0 0-.02-.938.636.636 0 0 0-.47-.187.67.67 0 0 0-.489.208l-4.458 4.459ZM9 17.333c-1.18 0-2.278-.212-3.292-.635a8.135 8.135 0 0 1-2.645-1.76 8.133 8.133 0 0 1-1.76-2.647C.878 11.278.666 10.181.666 9c0-1.167.212-2.257.635-3.271a8.133 8.133 0 0 1 1.76-2.646 8.272 8.272 0 0 1 2.646-1.77C6.722.881 7.82.666 9 .666a8.28 8.28 0 0 1 3.27.645 8.272 8.272 0 0 1 2.647 1.771c.75.75 1.34 1.632 1.77 2.646a8.281 8.281 0 0 1 .646 3.27c0 1.181-.215 2.279-.645 3.292a8.273 8.273 0 0 1-1.771 2.646 8.135 8.135 0 0 1-2.646 1.76 8.398 8.398 0 0 1-3.27.636Z"
        fill="#12C39A"
      />
    </svg>
  </div>
);

export const LightbulbCircle = ({ style }: { style?: React.CSSProperties }): JSX.Element => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      ...style,
    }}
  >
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.52 11.667h4.96c.513-.375.923-.85 1.228-1.427a4.005 4.005 0 0 0 .459-1.906c0-1.153-.406-2.136-1.219-2.948-.813-.813-1.795-1.219-2.948-1.219s-2.135.406-2.948 1.219c-.812.812-1.219 1.795-1.219 2.948 0 .694.153 1.33.459 1.906a4.142 4.142 0 0 0 1.229 1.427Zm.293-.833a3.867 3.867 0 0 1-.844-1.177 3.138 3.138 0 0 1-.302-1.323c0-.917.326-1.702.979-2.355A3.21 3.21 0 0 1 10 5a3.21 3.21 0 0 1 2.354.98 3.21 3.21 0 0 1 .98 2.354c0 .444-.101.885-.303 1.323-.201.437-.482.83-.843 1.177H7.813Zm.104 2.708h4.166a.4.4 0 0 0 .292-.125.4.4 0 0 0 0-.583.4.4 0 0 0-.292-.125H7.917a.4.4 0 0 0-.292.125.4.4 0 0 0 0 .583.4.4 0 0 0 .292.125ZM10 15.834c.25 0 .472-.08.667-.24A.967.967 0 0 0 11.02 15H8.979c.056.236.177.434.365.594.187.16.406.24.656.24Zm0 2.5a8.115 8.115 0 0 1-3.25-.657 8.415 8.415 0 0 1-2.646-1.78 8.416 8.416 0 0 1-1.781-2.647A8.115 8.115 0 0 1 1.667 10c0-1.152.218-2.236.656-3.25a8.415 8.415 0 0 1 1.781-2.646 8.415 8.415 0 0 1 2.646-1.78A8.115 8.115 0 0 1 10 1.666c1.153 0 2.236.219 3.25.656a8.415 8.415 0 0 1 2.646 1.781 8.416 8.416 0 0 1 1.781 2.646 8.115 8.115 0 0 1 .656 3.25 8.115 8.115 0 0 1-.656 3.25 8.416 8.416 0 0 1-1.781 2.646 8.415 8.415 0 0 1-2.646 1.781 8.115 8.115 0 0 1-3.25.657Z"
        fill="#12C39A"
      />
    </svg>
  </div>
);
