import React from 'react';
const CWEditAuthProvider: React.FC<{
  children: React.ReactNode;
  isCWStudent: boolean;
  isMissionCreatedByLoginUser: boolean;
}> = ({ children, isMissionCreatedByLoginUser, isCWStudent }) => {
  return (
    <div
      style={{
        flex: 1,
        cursor:
          isCWStudent && !isMissionCreatedByLoginUser
            ? 'not-allowed'
            : 'default',
      }}
    >
      <div
        style={{
          width: '100%',
          pointerEvents:
            isCWStudent && !isMissionCreatedByLoginUser ? 'none' : 'auto',
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default CWEditAuthProvider;
