import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import sanitize from 'sanitize-filename';
import {
  ADD_ACTION_ITEM,
  ADD_ACTION_ITEM_RESOURCE,
  GET_SC_UPLOAD_URL,
  LOAD_ACTION_ITEMS,
  SAVE_FILE,
} from '@crimson-education/core-shared-graphql';
import { FileRefType } from '@crimson-education/common-config';

import {
  ActionItem as ActionItemType,
  MissionDetailObjectType,
  MissionChangeActions,
} from '../../types';
import {
  Container,
  ListContainer,
  StyledOperationButton,
  Title,
} from './style';
import { useApiClient, useUserInfo } from '../../context';
import { AddCircle } from '@styled-icons/material';
import { StyledPopover } from '../predefined-task-toggle/style';
import { AddFromScratch } from './add-from-scratch';
import { UploadFile, notification } from 'antd';
import { ActionItemRework } from './action-item-rework';
import type { UploadProps } from 'antd';
import { TaskLibrary } from '../task-library/task-library';
import { useIsStaff } from '../hooks';
import CWEditAuthProvider from '../mission-drawer/CWEditAuthProvider';
export type FileType = Parameters<NonNullable<UploadProps['beforeUpload']>>[0];

export type ActionItemsProps = {
  mission: MissionDetailObjectType;
  editPermitted: boolean;
  deletePermitted: boolean;
  onMissionChange?: (action: MissionChangeActions) => void;
  visible: boolean;
  reloadMission: () => void;
  isCWStudent: boolean;
  isMissionCreatedByLoginUser: boolean;
};

export const ActionItemsRework: React.FC<ActionItemsProps> = ({
  mission,
  editPermitted,
  deletePermitted,
  onMissionChange,
  visible,
  reloadMission,
  isCWStudent,
  isMissionCreatedByLoginUser,
}) => {
  const { roadmapApiClient, coreApiClient } = useApiClient();
  const [actionItems, setActionItems] = useState<ActionItemType[]>([]);
  const [isAddingActionItem, setIsAddingActionItem] = useState(false);
  const [taskLibraryVisible, setTaskLibraryVisible] = useState(false);
  const { data, refetch: loadActionItems } = useQuery<{
    items: ActionItemType[];
  }>(LOAD_ACTION_ITEMS, {
    variables: {
      missionId: mission.id,
    },
    client: roadmapApiClient,
    fetchPolicy: 'network-only',
  });

  const { refetch: getStudentCenterUploadUrl } = useQuery(GET_SC_UPLOAD_URL, {
    client: coreApiClient,
    skip: true,
  });
  const [saveFile] = useMutation(SAVE_FILE, {
    client: coreApiClient,
  });
  useEffect(() => {
    if (visible) {
      loadActionItems();
    }
  }, [loadActionItems, visible]);
  useEffect(() => {
    if (data?.items) {
      setActionItems(data?.items);
    }
  }, [data?.items]);
  const [addActionItem] = useMutation(ADD_ACTION_ITEM, {
    client: roadmapApiClient,
  });
  const [addActionItemResource] = useMutation(ADD_ACTION_ITEM_RESOURCE, {
    client: roadmapApiClient,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const addActionItemFromScratch = async ({
    description,
    dueDate,
    content,
    fileList,
  }: {
    description: string;
    dueDate: string;
    content?: string;
    fileList?: UploadFile[];
  }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const input: any = {
      missionId: mission.id,
      description,
      dueDate: new Date(dueDate),
    };

    if (content) {
      input['content'] = content;
    }
    const { data } = await addActionItem({
      variables: {
        input,
      },
    });
    const actionItemId = data?.addActionItem?.id;

    if (fileList && fileList.length > 0) {
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        if (file.type === 'Link') {
          const variables = {
            actionItemId,
            url: file.url,
            title: file.name,
            type: 'Link',
          };
          await addActionItemResource({
            variables,
          });
        } else {
          const { name, type, size } = file;
          const sanitized = sanitize(name);
          const encoded = encodeURIComponent(sanitized);
          try {
            const formData = new FormData();
            formData.append('files[]', file as FileType);
            const res = await getStudentCenterUploadUrl({
              refId: actionItemId,
              fileName: encoded,
              contentType: type,
            });
            const { putUrl, key } = res.data.getStudentCenterUploadUrl;
            await fetch(putUrl, {
              method: 'PUT',
              body: formData,
            });
            const result = await saveFile({
              variables: {
                location: key,
                sha512: ' ',
                name: name,
                size: `${size}`,
                type: type,
                refId: actionItemId,
                refType: FileRefType.ACADEMIC,
              },
            });
            if (result?.data?.saveFileUplRef?.id) {
              await addActionItemResource({
                variables: {
                  actionItemId,
                  type: 'Upload',
                  url: result?.data?.saveFileUplRef?.id,
                  title: file.name,
                  mediaType: file.type,
                },
              });
            }
          } catch {
            notification.error({
              message: 'Upload File Error',
            });
          }
        }
      }
    }
    notification.success({
      message: 'Add Successfully',
    });
    loadActionItems();
    setIsAddingActionItem(false);
    onMissionChange?.('AddActionItem');
  };
  const user = useUserInfo();
  const isStaff = useIsStaff(user);

  return (
    <Container>
      <CWEditAuthProvider
        isCWStudent={isCWStudent}
        isMissionCreatedByLoginUser={isMissionCreatedByLoginUser}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'relative',
          }}
        >
          <Title style={{ paddingLeft: 0 }}>
            Tasks{' '}
            <span>{`${
              actionItems.length
                ? Number(actionItems.length) > 1
                  ? '(' + actionItems.length + ' tasks)'
                  : '(1 task)'
                : ''
            }`}</span>
          </Title>
          {editPermitted && (
            <StyledPopover
              placement={'bottomRight'}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              getPopupContainer={(triggerNode: any) =>
                triggerNode.parentNode as HTMLElement
              }
              showArrow={false}
              content={
                <div style={{ color: '#73747D', textAlign: 'right' }}>
                  {mission.category !== 'APPLICATION_COMMON' && isStaff && (
                    <StyledOperationButton
                      onClick={() => {
                        setTaskLibraryVisible(true);
                      }}
                    >
                      Add from task library
                    </StyledOperationButton>
                  )}
                  <StyledOperationButton
                    onClick={() => {
                      setIsAddingActionItem(true);
                    }}
                  >
                    Add from scratch
                  </StyledOperationButton>
                </div>
              }
              trigger={'click'}
            >
              <AddCircle
                style={{
                  color: '#464AC9',
                  width: 20,
                  height: 20,
                  cursor: 'pointer',
                  marginRight: 20,
                }}
              />
            </StyledPopover>
          )}
        </div>
      </CWEditAuthProvider>

      <ListContainer>
        {isAddingActionItem && (
          <AddFromScratch
            setIsAddingActionItem={setIsAddingActionItem}
            isAddingActionItem={isAddingActionItem}
            isSubmitting={isSubmitting}
            editPermitted={editPermitted}
            addActionItem={async (...args) => {
              setIsSubmitting(true);
              await addActionItemFromScratch(...args);
              setIsSubmitting(false);
            }}
          />
        )}

        {actionItems?.slice().map((item) => (
          <ActionItemRework
            key={item.id}
            item={item}
            loadActionItems={loadActionItems}
            editPermitted={editPermitted}
            deletePermitted={deletePermitted}
            isCWStudent={isCWStudent}
            isMissionCreatedByLoginUser={isMissionCreatedByLoginUser}
          />
        ))}
      </ListContainer>
      {taskLibraryVisible && (
        <TaskLibrary
          visible={taskLibraryVisible}
          setDrawerVisible={setTaskLibraryVisible}
          mission={mission}
          loadActionItems={loadActionItems}
          reloadMission={reloadMission}
        />
      )}
    </Container>
  );
};
