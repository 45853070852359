import { createContext, useContext } from 'react';

interface MenuContextInterface {
  visible: boolean;
  setVisibility: (visibility: boolean) => void;
}
export const MenuContext = createContext<MenuContextInterface | null>(null);
MenuContext.displayName = 'MenuContext';

export const useMenuContext = (component: string): MenuContextInterface => {
  const context = useContext(MenuContext);
  if (context === null) {
    const err = new Error(
      `<${component} /> is missing a parent <Menu /> component.`
    );
    throw err;
  }
  return context;
};
