import { MoreHoriz } from '@styled-icons/material';
import { FunctionComponent, useState } from 'react';

import { Avatar } from '../avatar/avatar';
import * as Styled from './style';
import { TInternalNote } from './type';
import { CRMenu } from '../cr-menu/cr-menu';
import NoteInput from './note-input';
import { StyledClose } from '../icons';
import { useMutation } from '@apollo/client';
import { DELETE_INTERNAL_NOTE } from '@crimson-education/core-shared-graphql';
import { motion } from 'framer-motion';
import moment from 'moment';
import { useApiClient, useUserInfo } from '../../context';

export type NoteMode = 'show' | 'edit' | 'removed';
interface InternalNoteCardProps {
  missionId?: string;
  studentId: string;
  note: TInternalNote;
  onRemove?: (note: TInternalNote) => void;
  onEdit?: (newNote: TInternalNote) => void;
}
const InternalNoteCard: FunctionComponent<InternalNoteCardProps> = ({
  studentId,
  missionId,
  note,
  onRemove,
  onEdit,
}) => {
  const { userId } = useUserInfo();

  const [localNote, setLocalNote] = useState(note);
  const [mode, setMode] = useState<NoteMode>('show');
  const { studentCenterApiClient } = useApiClient();
  const [deleteInternalNote] = useMutation(DELETE_INTERNAL_NOTE, {
    client: studentCenterApiClient,
  });
  const handleEdit = (note: TInternalNote) => {
    setMode('show');
    setLocalNote(note);
    if (onEdit) {
      onEdit(note);
    }
  };
  const handleRemove = async () => {
    const { errors } = await deleteInternalNote({
      variables: { noteId: localNote.id },
    });
    if (!errors?.length) {
      setMode('removed');
      if (onRemove) {
        onRemove(localNote);
      }
    }
  };
  if (mode === 'removed') {
    return null;
  }
  const createdDuration = moment(localNote.createdAt || new Date()).fromNow();
  const isUpdated = localNote.createdAt !== localNote.updatedAt;
  const canAccessOptions = note.creatorUid === userId;
  return (
    <Styled.NoteContainer>
      <div style={{ display: 'flex' }}>
        <Styled.NoteCreatorContainer>
          <Avatar
            size={24}
            firstName={localNote.creator?.firstName}
            lastName={localNote.creator?.lastName}
            userId={localNote.creatorUid}
            image={localNote.creator?.profileImageUrl}
          />
          <Styled.NoteCreatorName>
            {localNote.creator?.firstName +
              (localNote.creator?.lastName
                ? ` ${localNote.creator.lastName}`
                : '')}
          </Styled.NoteCreatorName>
          <Styled.NoteCreatedDate>
            {createdDuration}{' '}
            {isUpdated ? (
              <span style={{ marginLeft: '4px', opacity: 0.5 }}>Edited</span>
            ) : null}
          </Styled.NoteCreatedDate>
        </Styled.NoteCreatorContainer>
        {mode === 'show' && canAccessOptions ? (
          <Styled.NoteOptionsContainer>
            <CRMenu Icon={<MoreHoriz width={16} color="#A9ACC0" />}>
              <CRMenu.Option onClick={() => setMode('edit')}>
                Edit
              </CRMenu.Option>
              <CRMenu.Option onClick={handleRemove}>Remove</CRMenu.Option>
            </CRMenu>
          </Styled.NoteOptionsContainer>
        ) : null}
        {mode === 'edit' ? (
          <motion.div animate={{ rotate: 90 }} exit={{ rotate: 0 }}>
            <StyledClose width={12} onClick={() => setMode('show')} />
          </motion.div>
        ) : null}
      </div>
      <div>
        {mode === 'show' ? (
          <Styled.NoteContent>{localNote.content}</Styled.NoteContent>
        ) : null}
        {mode === 'edit' ? (
          <NoteInput
            studentId={studentId}
            missionId={missionId}
            note={localNote}
            onNoteSaved={handleEdit}
          />
        ) : null}
      </div>
    </Styled.NoteContainer>
  );
};

export default InternalNoteCard;
