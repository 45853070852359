import { CR_COLORS } from '../../utils/theme';
import styled, { css } from 'styled-components';
import {
  Checkbox as AntCheckbox,
  DatePicker as AntDatePicker,
  Popover,
} from 'antd';
const { RangePicker: AntRangePicker } = AntDatePicker;

const HideScrollBar = css`
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-track {
    background: white;
  }
  &::-webkit-scrollbar-thumb {
    background: white;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: white;
  }
  &::-webkit-scrollbar-corner {
    background: white;
  }
`;
export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TitleLeft = styled.h3`
  span {
    margin-left: 4px;
    font-size: var(--font-size-small);
    color: var(--color-yellow);
  }
`;

export const TitleRight = styled.div``;

export const Checkbox = styled(AntCheckbox)`
  margin: 0 5px;
`;

export const RangePicker = styled(AntRangePicker)`
  width: 260px;
  padding: 0 !important;
`;

export const RangePickerPopover = styled(Popover)``;

export const ChangeDate = styled.span`
  margin-left: 5px;
  height: 22px;
  line-height: 24px;
  font-size: 12px;
  color: var(--color-cool-grey);
  &:hover {
    cursor: pointer;
    color: var(--color-indigo);
  }
`;

export const NoteInputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-top: 16px;

  textarea {
    border-radius: 30px;
    flex-grow: 1;
    padding: 10px 16px;
    background-color: var(--color-mist);
    border: none;
    margin: 0 4px;
    font-size: var(--font-size-small);
    &:focus {
      border: 1px solid rgba(0, 0, 0, 0.05);
      box-shadow: 0px 3px 6px rgba(158, 158, 158, 0.1);
      padding: 10px 16px;
    }
    ${HideScrollBar}
  }
  button {
    border: none;
    box-shadow: none;
    padding: 0;
    cursor: pointer;
    background: none !important;
    ::selection {
      background: none !important;
      border: none !important;
    }
  }
`;

export const NoteListContainer = styled.div`
  margin: 34px 0;
`;

export const ViewMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ViewMoreButton = styled.button`
  background: none;
  color: #a9acc0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e3e7ed;
  border-radius: 20px;
  padding: 2px 16px;
  cursor: pointer;
`;

export const NoteContainer = styled.div`
  margin: 16px 0;
  padding: 0 16px 0 0;
`;

export const NoteCreatorContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

export const NoteCreatorName = styled.span`
  margin: 0 8px;
  font-weight: 600;
  color: ${CR_COLORS.DARK_NAVY};
`;
export const NoteCreatedDate = styled.span`
  margin: 0 8px;
  color: ${CR_COLORS.STONE};
  font-size: var(--font-size-xs);
`;

export const NoteOptionsContainer = styled.div`
  flex-grow: 0;
`;

export const NoteContent = styled.p`
  margin: 4px 16px 0 32px;
  white-space: pre-wrap;
`;
