export const GoldMedal = ({ style }: { style?: React.CSSProperties }): JSX.Element => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      ...style,
    }}
  >
    <svg width={22} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.002 9a8 8 0 1 1-16 0 8 8 0 0 1 16 0Z" fill="#FFBB0D" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.595 9A8.593 8.593 0 1 1 2.41 9a8.593 8.593 0 0 1 17.185 0Zm-8.593 8a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z"
        fill="#E89B05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.595 9.296A.296.296 0 0 1 3.298 9a7.704 7.704 0 0 1 7.704-7.704.296.296 0 1 1 0 .592A7.111 7.111 0 0 0 3.891 9a.296.296 0 0 1-.296.296ZM18.41 8.704c.163 0 .296.132.296.296a7.704 7.704 0 0 1-7.704 7.704.296.296 0 1 1 0-.593A7.111 7.111 0 0 0 18.113 9c0-.164.133-.296.296-.296Z"
        fill="#E8AA0A"
      />
      <path d="M16.928 9A5.926 5.926 0 1 1 5.076 9a5.926 5.926 0 0 1 11.852 0Z" fill="#FFDD28" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.52 9A6.519 6.519 0 1 1 4.484 9a6.519 6.519 0 0 1 13.038 0Zm-6.518 5.926a5.926 5.926 0 1 0 0-11.852 5.926 5.926 0 0 0 0 11.852Z"
        fill="#E89B05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.002 14.037A5.037 5.037 0 0 0 16.039 9a.296.296 0 0 1 .593 0 5.63 5.63 0 0 1-5.63 5.63.296.296 0 1 1 0-.593Z"
        fill="#ECC704"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.002 3.963A5.037 5.037 0 0 0 5.965 9a.296.296 0 0 1-.593 0 5.63 5.63 0 0 1 5.63-5.63.296.296 0 1 1 0 .593Z"
        fill="#FFEF9C"
      />
      <path
        d="m1.946 16.888-.763-1.526A.25.25 0 0 1 1.406 15h3.346a.25.25 0 0 1 .25.25v3.5a.25.25 0 0 1-.25.25H1.406a.25.25 0 0 1-.223-.362l.763-1.526a.25.25 0 0 0 0-.224ZM20.058 16.888l.763-1.526a.25.25 0 0 0-.224-.362h-3.345a.25.25 0 0 0-.25.25v3.5c0 .138.112.25.25.25h3.346a.25.25 0 0 0 .223-.362l-.763-1.526a.25.25 0 0 1 0-.224Z"
        fill="#E16104"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m1.443 17-.707-1.415a.75.75 0 0 1 .67-1.085h3.346a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-.75.75H1.406a.75.75 0 0 1-.67-1.085L1.443 17Zm.056.111L1.722 17l-.223.112ZM20.56 17l.707-1.415a.75.75 0 0 0-.67-1.085h-3.346a.75.75 0 0 0-.75.75v3.5c0 .414.336.75.75.75h3.345a.75.75 0 0 0 .671-1.085L20.561 17Zm.26-1.638-.763 1.526a.25.25 0 0 0 0 .224l.763 1.526a.25.25 0 0 1-.224.362h-3.345a.25.25 0 0 1-.25-.25v-3.5a.25.25 0 0 1 .25-.25h3.345a.25.25 0 0 1 .224.362Zm-19.638 0 .763 1.526a.25.25 0 0 1 0 .224l-.763 1.526a.25.25 0 0 0 .223.362h3.346a.25.25 0 0 0 .25-.25v-3.5a.25.25 0 0 0-.25-.25H1.406a.25.25 0 0 0-.223.362Z"
        fill="#BD4501"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.54 15.226a.25.25 0 0 1 .212-.117h1.062a.25.25 0 1 1 0 .5H2.15l.516 1.08a.25.25 0 1 1-.451.216l-.688-1.438a.25.25 0 0 1 .014-.24Z"
        fill="#FA7616"
      />
      <path
        d="M18.002 17.75v-3.5a.25.25 0 0 0-.25-.25h-13.5a.25.25 0 0 0-.25.25v3.5c0 .138.112.25.25.25h13.5a.25.25 0 0 0 .25-.25Z"
        fill="#FF8B04"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.502 14.25v3.5a.75.75 0 0 1-.75.75h-13.5a.75.75 0 0 1-.75-.75v-3.5a.75.75 0 0 1 .75-.75h13.5a.75.75 0 0 1 .75.75Zm-.5 0v3.5a.25.25 0 0 1-.25.25h-13.5a.25.25 0 0 1-.25-.25v-3.5a.25.25 0 0 1 .25-.25h13.5a.25.25 0 0 1 .25.25Z"
        fill="#BD4501"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.158 14.36a.25.25 0 0 1 .25-.25h9.375a.25.25 0 1 1 0 .5H4.658v1.75a.25.25 0 0 1-.5 0v-2Z"
        fill="#FFB053"
      />
      <path
        d="M17.311 16.095a.253.253 0 0 1-.256-.25c0-.138.115-.25.256-.25.142 0 .257.112.257.25s-.115.25-.257.25ZM16.336 15.995a.315.315 0 0 1-.319-.31c0-.173.143-.312.319-.312.176 0 .319.14.319.311a.315.315 0 0 1-.32.311ZM16.77 16.706a.157.157 0 0 1-.159-.155c0-.086.072-.156.16-.156.088 0 .159.07.159.156a.157.157 0 0 1-.16.155ZM16.495 15.061a.158.158 0 0 1-.16-.155c0-.086.072-.156.16-.156.088 0 .16.07.16.155a.158.158 0 0 1-.16.156ZM15.658 16.55a.157.157 0 0 1-.16-.155c0-.086.072-.155.16-.155.088 0 .16.07.16.155a.157.157 0 0 1-.16.156ZM15.498 15.75a.158.158 0 0 1-.16-.155c0-.086.072-.155.16-.155.088 0 .16.07.16.155a.157.157 0 0 1-.16.155ZM14.411 17.555a.157.157 0 0 1-.159-.156c0-.086.071-.155.16-.155.087 0 .159.07.159.155a.157.157 0 0 1-.16.156ZM17.583 16.96a.158.158 0 0 1-.16-.155c0-.086.072-.156.16-.156.088 0 .16.07.16.156a.158.158 0 0 1-.16.155ZM16.077 17.301a.256.256 0 0 1-.26-.253c0-.14.116-.253.26-.253.143 0 .26.113.26.253s-.117.253-.26.253ZM17.19 17.555a.256.256 0 0 1-.26-.253c0-.14.116-.254.26-.254.143 0 .259.114.259.254s-.116.253-.26.253ZM14.92 17.057a.256.256 0 0 1-.26-.253c0-.14.117-.253.26-.253.143 0 .26.113.26.253s-.117.253-.26.253ZM15.339 17.631a.158.158 0 0 1-.16-.155c0-.086.072-.156.16-.156.088 0 .16.07.16.156a.158.158 0 0 1-.16.155Z"
        fill="#E77D02"
      />
    </svg>
  </div>
);

export const BG_IMAGE_1 = ({ style }: { style?: React.CSSProperties }): JSX.Element => (
  <div
    style={{
      position: 'absolute',
      right: '186px',
      bottom: '0px',
      height: '127px',
      ...style,
    }}
  >
    <svg width={169} height={127} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M59.332 3.284 20.95 93.204l1.592.777 38.384-89.92-1.593-.777Z" fill="#00664F" />
      <path
        d="M20.65 96.106c-1.3-.603-1.864-2.294-1.3-3.683.565-1.389 2.146-1.993 3.445-1.389 21.912 10.688 47.833.362 57.829-23.066 9.995-23.428.338-51.144-21.573-61.832-1.299-.604-1.864-2.294-1.299-3.683.565-1.389 2.146-1.993 3.445-1.389C85.65 13.02 96.492 43.996 85.31 70.202c-11.182 26.327-40.153 37.86-64.662 25.904Z"
        fill="#00664F"
      />
      <path
        d="M40.917 92.363c22.581 0 40.886-19.573 40.886-43.717 0-24.145-18.305-43.717-40.886-43.717C18.337 4.929.031 24.5.031 48.646c0 24.144 18.305 43.717 40.886 43.717Z"
        fill="#B0DDC9"
      />
      <path
        d="M78.135 66.82a45.532 45.532 0 0 1-5.196 9.118c-4.857 2.174-10.278 2.899-15.473 1.933-.678-.121-1.356-.302-1.864-.785-1.525-1.45-.226-4.046-.226-6.22 0-1.811-1.017-3.442-1.976-4.951-2.146-3.382-4.292-7.548-2.994-11.473 1.3-3.925 5.648-6.038 6.777-10.023 1.017-3.804-1.299-7.73-1.355-11.715-.057-3.08 1.355-6.038 3.332-8.212 2.033-2.174 4.63-3.623 7.341-4.65 1.412-.543 2.993-.905 4.518-.905 10.786 12.62 14.175 31.4 7.116 47.883Zm-49.64 22.644c2.09-1.087 3.84-3.14 4.235-5.555.396-2.657-.847-5.254-2.371-7.367-1.582-2.113-3.445-3.925-4.744-6.22-.565-.965-.96-2.052-.96-3.2.056-3.14 3.219-5.313 3.557-8.393.34-2.717-1.694-5.193-3.953-6.4-2.315-1.208-4.913-1.51-7.398-2.235-2.484-.724-4.97-2.173-5.986-4.71-1.242-3.2.34-7.004-1.355-10.023-1.073-1.932-3.897-3.2-6.325-3.623-8.527 21.678.565 46.857 20.726 56.7 1.186.543 2.315 1.086 3.5 1.51.396-.122.735-.303 1.074-.484ZM17.144 13.08c.113.483.282.966.508 1.389 1.977 4.468 6.212 7.85 6.833 12.74.17 1.329.113 2.838.904 3.865.395.543 1.016.845 1.638 1.147 5.139 2.295 11.577.665 15.134-3.925 3.445-4.468 4.066-10.687 6.777-15.7.79-1.448 1.75-2.837 1.75-4.528 0-.724-.225-1.389-.564-1.992-11.633-2.899-23.606-.182-32.98 7.004Z"
        fill="#2EA889"
      />
      <path d="M44.929 99.79h-8.02v20.409h8.02v-20.41Z" fill="#00664F" />
      <path
        d="M72.598 127.505H9.236c-2.09 0-3.727-1.811-3.727-3.985 0-2.234 1.694-3.985 3.727-3.985h63.306c2.09 0 3.727 1.811 3.727 3.985.057 2.174-1.638 3.985-3.67 3.985Z"
        fill="#00664F"
      />
      <path
        d="M159.458 84.392c4.01 0 7.285-3.502 7.285-7.79 0-4.286-3.275-7.788-7.285-7.788H74.184c2.824 5.192 2.824 10.385 0 15.578h85.274Z"
        fill="#EDEDED"
      />
      <path
        d="M159.458 84.392c4.123 0 7.455-3.683 7.285-8.151-.169-4.167-3.557-7.367-7.454-7.367H73.055c-.622 0-1.13-.544-1.13-1.208 0-.664.508-1.207 1.13-1.207h86.403c5.252 0 9.488 4.528 9.488 10.144 0 5.615-4.236 10.144-9.488 10.144H73.055c-.622 0-1.13-.543-1.13-1.208 0-.664.508-1.207 1.13-1.207h86.403v.06Z"
        fill="#FFC278"
      />
      <path
        d="m163.695 70.323-6.89.06-12.932.121-25.921.06-25.921-.06-12.933-.06-4.179-.06c-.056-.061-.056-.121-.113-.182l4.236-.06 12.932-.06 25.921-.061 25.921.06 12.933.06 6.663.061c.17 0 .226.06.283.12Zm1.694 1.811c0-.06-.056-.06-.056-.12l-8.471-.121-12.933-.06-25.921-.06-25.92.06-12.933.06-3.558.06c0 .06.056.121.056.242l3.502.06 12.932.06 25.921.06 25.865-.06 12.932-.06 8.584-.12Zm.904 1.812s0-.06-.057-.06l-9.431-.121-12.932-.06-25.921-.061-25.921.06-12.933.06-3.05.061c0 .06 0 .181.057.242l3.05.06 12.932.06 25.921.06 25.865-.12 12.932-.06 9.488-.121Zm-91.487 9.178 4.236.06 12.932.06 25.921.061 25.921-.06 12.933-.06 6.663-.061c.057-.06.113-.12.17-.12l-6.89-.061-12.932-.06-25.921-.06-25.921.06-12.933.06-4.179.06c.057-.06.057.06 0 .121Zm90.527-1.872c0-.06.056-.06.056-.12l-8.527-.121-12.933-.06-25.921-.061-25.92.06-12.933.06-3.502.061c0 .06-.056.12-.056.242l3.558.06 12.932.06 25.921.06 25.921-.06 12.933-.06 8.471-.12Zm.903-1.872s0-.06.057-.06l-9.431-.12-12.933-.061-25.921-.06-25.92.06-12.933.06-3.05.06c0 .06 0 .182-.056.242l3.05.06 12.932.06 25.921.061 25.921-.06 12.932-.06 9.431-.182Zm.452-1.811v-.06l-9.883-.121-12.932-.06-25.921-.061-25.921.06-12.933.06-2.823.061v.242l2.823.06 12.933.06 25.921.06 25.921-.12 12.932-.06 9.883-.121Zm0-1.812v-.06l-9.826-.12-12.933-.061-25.921-.06-25.92.06-12.933.06-2.824.06v.242l2.824.06 12.932.06 25.921.061 25.865-.12 12.932-.06 9.883-.122Z"
        fill="#E1E3EF"
      />
      <path d="m83.388 83.728 3.728-2.294 3.727 2.294V73.705h-7.455v10.023Z" fill="#FFC278" />
      <path
        d="M81.47 104.741c-4.01 0-7.286-3.502-7.286-7.79 0-4.287 3.276-7.789 7.285-7.789h85.274c-2.823 5.193-2.823 10.386 0 15.579H81.469Z"
        fill="#EDEDED"
      />
      <path
        d="M81.47 104.741c-4.123 0-7.455-3.684-7.286-8.152.17-4.166 3.558-7.367 7.455-7.367h86.177c.622 0 1.13-.543 1.13-1.207s-.508-1.208-1.13-1.208H81.413c-5.252 0-9.488 4.529-9.488 10.144 0 5.616 4.236 10.145 9.488 10.145h86.403c.622 0 1.13-.544 1.13-1.208 0-.664-.508-1.208-1.13-1.208H81.469v.061Z"
        fill="#FF7648"
      />
      <path
        d="m77.402 90.49 6.664-.06 12.932-.06 25.922-.06 25.921.06 12.932.06 4.235.06c-.056.061-.056.122-.113.182l-4.179.06-12.932.06-25.921.061-25.921-.06-12.932-.06-6.89-.061c.17-.06.226-.12.282-.181Zm6.664 2.054 12.932.06 25.922.06 25.921-.06 12.932-.06 3.501-.06c0-.061.057-.121.057-.242l-3.558-.06-12.932-.061-25.921-.06-25.922.06-12.932.06-8.47.121c0 .06-.057.06-.057.121l8.527.12Zm0 1.811 12.932.06 25.922.061 25.921-.06 12.932-.06 3.049-.061c0-.06 0-.181.057-.242l-3.05-.06-12.932-.06-25.921-.06-25.921.06-12.932.06-9.431.12s0 .061-.057.061l9.431.181Zm81.942 8.876-4.179-.06-12.932-.06-25.921-.061-25.921.061-12.932.06-6.89.06c.056.061.113.121.17.121l6.663.061 12.932.06 25.922.06 25.921-.06 12.932-.06 4.235-.061c.057.061.057-.06 0-.121Zm-81.942-1.569 12.932.06 25.922.06 25.921-.06 12.932-.06 3.558-.061c0-.06-.057-.121-.057-.241l-3.501-.061-12.932-.06-25.921-.061-25.922.061-12.932.06-8.527.121c0 .06.056.06.056.121l8.471.121Zm0-1.812 12.932.06 25.922.06 25.921-.06 12.932-.06 3.049-.06c0-.06 0-.181-.056-.242l-3.05-.06-12.932-.06-25.921-.061-25.921.06-12.932.06-9.431.122s0 .06.056.06l9.431.181Zm0-1.811 12.932.06 25.922.06 25.921-.06 12.932-.06 2.824-.06v-.242l-2.824-.06-12.932-.061-25.921-.06-25.922.06-12.932.06-9.883.121v.06l9.883.181Zm0-1.812 12.932.06 25.922.06 25.921-.06 12.932-.06 2.824-.06v-.242l-2.824-.06-12.932-.06-25.921-.061-25.922.06-12.932.06-9.826.121v.06l9.826.182Z"
        fill="#E1E3EF"
      />
      <path d="m159.854 100.514-3.728-2.295-3.67 2.295V90.43h7.398v10.084Z" fill="#FE9946" />
      <path
        d="M159.453 125.09c4.01 0 7.285-3.502 7.285-7.789 0-4.288-3.275-7.79-7.285-7.79H74.179c2.824 5.193 2.824 10.386 0 15.579h85.274Z"
        fill="#EDEDED"
      />
      <path
        d="M159.456 125.09c4.123 0 7.455-3.683 7.285-8.152-.169-4.166-3.557-7.366-7.454-7.366H73.109c-.62 0-1.13-.544-1.13-1.208 0-.664.51-1.208 1.13-1.208h86.404c5.252 0 9.487 4.529 9.487 10.145 0 5.615-4.235 10.144-9.487 10.144H73.109c-.62 0-1.13-.544-1.13-1.208 0-.664.51-1.208 1.13-1.208h86.347v.061Z"
        fill="#8F98FF"
      />
      <path
        d="m163.693 111.021-6.89.06-12.932.061-25.922.06-25.92-.06-12.933-.061-4.179-.06c-.056-.06-.056-.121-.113-.181l4.236-.061 12.932-.06 25.921-.06 25.921.06 12.932.06 6.664.061c.17.06.226.121.283.181Zm1.694 1.811c0-.06-.057-.06-.057-.12l-8.471-.121-12.932-.06-25.921-.061-25.921.061-12.932.06-3.558.06c0 .061.056.121.056.242l3.502.06 12.932.061 25.921.06 25.921-.06 12.932-.061 8.528-.121Zm.903 1.812s0-.06-.056-.06l-9.431-.121-12.932-.061-25.922-.06-25.92.06-12.933.061-3.05.06c0 .061 0 .181.057.242l3.05.06 12.932.06 25.921.061 25.921-.061 12.932-.06 9.431-.181Zm-.96 7.306c0-.06.057-.06.057-.121l-8.528-.12-12.932-.061-25.921-.06-25.921.06-12.932.061-3.502.06c0 .06-.056.121-.056.242l3.558.06 12.932.06 25.921.061 25.921-.061 12.932-.06 8.471-.121Zm.904-1.872s0-.06.056-.06l-9.431-.121-12.932-.06-25.921-.061-25.921.061-12.932.06-3.05.061c0 .06 0 .181-.056.241l3.049.061 12.932.06 25.921.06 25.922-.06 12.932-.06 9.431-.182Zm.452-1.811v-.06l-9.883-.121-12.932-.061-25.922-.06-25.92.06-12.933.061-2.824.06v.242l2.824.06 12.932.06 25.921.061 25.922-.061 12.932-.06 9.883-.181Zm0-1.812v-.06l-9.827-.121-12.932-.06-25.921-.06-25.921.06-12.932.06-2.824.061v.241l2.824.061 12.932.06 25.921.06 25.921-.06 12.932-.06 9.827-.182Z"
        fill="#E1E3EF"
      />
      <path d="m103.886 124.426 3.727-2.295 3.671 2.295v-10.084h-7.398v10.084Z" fill="#8F98FF" />
    </svg>
  </div>
);

export const BG_IMAGE_2 = ({ style }: { style?: React.CSSProperties }): JSX.Element => (
  <div
    style={{
      position: 'absolute',
      right: '0px',
      bottom: '0px',
      ...style,
    }}
  >
    <svg width={416} height={125} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity={0.08}
        d="M57.175 320.032c-19.5.214-48.6-11.334-43.7-37.209 3.3-17.536-10.5-34.002-13-51.644-2.9-20.316 7.7-41.914 25.8-52.5 6.4-3.742 14-6.415 18.4-12.403 13.7-18.177-9.3-40.844-.5-65.223 5.1-14.007 20-21.705 39.9-20.422 20.4 1.39 40-2.673 42.5-22.454 2.1-15.931 1.9-27.158 17.7-40.844 26.2-22.775 91.7-25.769 105 17 3.9 12.403 14.9 39.562 53.2 16.573 29.8-17.963 100.4 2.78 77.8 54.531-8.9 20.422 8.6 26.945 35.7 37.53 20.3 7.912 37.3 47.367-4.3 81.262-6 4.811 8 18.604 13.8 23.63 33.4 28.869 2 71.424-29.4 72.173 0 0-319.4-.214-338.9 0Zm46.2-276.824c-11.1-2.673-14.6 9.623-14.2 18.498.1 1.71.2 3.528.9 5.025 1.1 2.138 3.3 3.528 5.5 4.17 3.6 1.07 7.6.855 11.3 0 2.4-.535 4.8-1.39 6.5-3.208 6.8-7.27-1.7-22.56-10-24.485Zm189.5-5.24c-.2 3.101-2.5 5.774-5.2 7.058-2.7 1.39-5.8 1.71-8.8 1.924-4.3.321-9.3.321-12.2-2.994-2-2.245-2.5-5.667-2.1-8.66 1.2-7.699 5.8-15.29 14.2-14.328 6.4.855 14.4 10.478 14.1 17Zm10.5-18.497c-1 .748-2.4.962-3.6.642a4.04 4.04 0 0 1-1.8-.856c-3.6-2.78-2.2-8.02 2.4-7.485 3.6.428 7.1 4.812 3 7.699Zm108.8 96.231c1.2 1.817 2.1 4.063 1.4 6.094-.5 1.497-1.8 2.567-3.1 3.208-2.8 1.39-6.2 1.069-9.1-.214-2.9-1.176-5.4-3.207-7.8-5.346-1.1-.962-2.1-1.925-2.7-3.208-.4-.962-.5-2.031-.5-2.993-.1-1.818.1-3.636.6-5.347.7-2.245 2-4.277 3.3-6.094 2-2.78 4.9-6.095 8.7-5.453 2.3.427 2.8 1.817 2.9 4.063 0 2.459-.5 4.811.4 7.271 1 3.207 4.1 5.346 5.9 8.019Zm-393.4 52.606c-3.7 3.315-10 2.78-13.2-1.176-11.2-13.9-1.3-48.116 19.6-43.197 7.8 1.818 13.7 11.12 10.2 19.246-2 4.598-7.3 6.95-9.8 11.227-2.6 4.491-2.8 10.371-6.8 13.9Z"
        fill="#000"
      />
    </svg>
  </div>
);

export const BG_IMAGE_3 = ({ style }: { style?: React.CSSProperties }): JSX.Element => (
  <div
    style={{
      position: 'absolute',
      right: '0px',
      top: '0px',
      ...style,
    }}
  >
    <svg width={143} height={105} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.346-59.64c17.35-5.1 36.043 5.009 50.468 15.933 14.25 10.79 22.317 27.112 28.535 43.893 6.472 17.466 15.314 36.963 7.73 53.973-7.482 16.781-29.089 20.158-44.919 29.437-13.849 8.118-25.771 21.301-41.814 21.403-16.079.103-31.506-8.833-42.202-20.867-9.593-10.794-6.566-27.206-12.309-40.466C18.111 28.141-4.675 16.344.858.353c5.571-16.099 32.231-9.27 45.75-19.603 14.107-10.782 15.714-35.386 32.738-40.39Z"
        fill="#00664F"
      />
    </svg>
  </div>
);

export const EmptyIcon = (): JSX.Element => (
  <svg width={120} height={87} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.821 16 7.123 17.3a.422.422 0 0 1-.596.596l-1.302-1.302-1.302 1.302a.422.422 0 0 1-.596-.596l1.302-1.302-1.302-1.302a.422.422 0 0 1 .596-.596l1.302 1.302L6.527 14.1a.422.422 0 0 1 .596.596L5.821 16Z"
      fill="#ED4B53"
    />
    <mask
      id="a"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x={3}
      y={13}
      width={5}
      height={6}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.821 16 7.123 17.3a.422.422 0 0 1-.596.596l-1.302-1.302-1.302 1.302a.422.422 0 0 1-.596-.596l1.302-1.302-1.302-1.302a.422.422 0 0 1 .596-.596l1.302 1.302L6.527 14.1a.422.422 0 0 1 .596.596L5.821 16Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#a)">
      <path fill="#ED4B54" d="M-75.575-76.801H85.61v184.889H-75.575z" />
    </g>
    <path
      d="M9.841 31.247c-14.8 23.6-8.933 40.133-4 46.8 22.4 24 32-8.8 54-12.8s37.161 11.03 54.8-10c10.4-12.4 4.4-39.6-18.4-50.4-25.2-11.937-69.007-1.334-86.4 26.4Z"
      fill="#F3F6FA"
    />
    <path
      d="M84.386 41.573c0-.638-.059-1.362-.813-1.512a3.528 3.528 0 0 0-1.677.466l-1.655.637c0-1.433-.08-2.709-.139-3.081 0-12.196-10.1-22.083-22.56-22.083-12.459 0-22.56 9.887-22.56 22.083v.093c0 1.039.25 11.467-1.002 13.754-.967 1.763-5.404 9.217-6.29 13.31-.308 1.433.49 3.297 2.13 3.332 3.127.072 3.332-1.39 5.683-1.433 2.35-.043 3.478 2.293 7.029 2.293 3.837 0 5.52-2.286 9.123-2.286 1.67 0 4.657 2.702 8.7 2.702 2.577 0 4.473-2.573 7.922-2.573 3.046 0 3.09 2.236 6.077 2.15 2.007-.064 2.336-1.483 2.988-3.72 1.135-3.884 2.196-11.56 2.709-17.667 2.358-1.44 4.364-3.605 4.335-6.465Z"
      fill="#BABCC5"
    />
    <ellipse cx={61.079} cy={38.333} rx={1.538} ry={1.505} fill="#323456" />
    <ellipse cx={73.227} cy={38.333} rx={1.538} ry={1.505} fill="#323456" />
    <path d="M66.002 43.237h2.586a3.189 3.189 0 0 1-2.585 0Z" stroke="#222950" strokeWidth={0.72} />
    <path
      d="M55.301 56.625c.169-2.35-.183-4.845-.27-7.167 0-.46-.733-.48-.733 0 .044 1.275.096 2.558.147 3.834 0 .925.659 4.107-.732 4.415-1.062.23-2.234-.573-2.878-1.276a9.2 9.2 0 0 1-1.208-1.82 18.628 18.628 0 0 1-1.465-4.035.377.377 0 0 0-.454-.273.368.368 0 0 0-.278.445c.666 2.602 2.329 7.884 5.858 7.726a1.914 1.914 0 0 0 1.417-.496c.382-.351.598-.84.596-1.353Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.962 34.399h-.154a.717.717 0 0 1-.564-.839c.38-1.648.979-3.24 1.78-4.738a25.417 25.417 0 0 1 3.5-4.486.741.741 0 0 1 .723-.24c.268.06.48.26.548.521a.707.707 0 0 1-.225.715 24.37 24.37 0 0 0-3.273 4.2 17.83 17.83 0 0 0-1.625 4.3.728.728 0 0 1-.71.567ZM47.599 23.21a.73.73 0 0 1-.697-.502.709.709 0 0 1 .28-.801l.556-.38a.747.747 0 0 1 .73-.064.719.719 0 0 1 .421.588.712.712 0 0 1-.31.651l-.563.38a.744.744 0 0 1-.417.129Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m100.221 24.8 1.302 1.302a.42.42 0 1 1-.596.596l-1.302-1.302-1.302 1.302a.421.421 0 1 1-.596-.596l1.302-1.302-1.302-1.302a.421.421 0 1 1 .596-.596l1.302 1.302 1.302-1.302a.421.421 0 1 1 .596.596l-1.302 1.302Z"
      fill="#ED4B53"
    />
    <mask
      id="b"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x={97}
      y={22}
      width={5}
      height={5}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m100.221 24.8 1.302 1.302a.42.42 0 1 1-.596.596l-1.302-1.302-1.302 1.302a.421.421 0 1 1-.596-.596l1.302-1.302-1.302-1.302a.421.421 0 1 1 .596-.596l1.302 1.302 1.302-1.302a.421.421 0 1 1 .596.596l-1.302 1.302Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#b)">
      <path fill="#ED4B54" d="M18.825-68H180.01v184.889H18.825z" />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M94.825 73.999a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0-3.2a1.2 1.2 0 1 1 0 2.4 1.2 1.2 0 0 1 0-2.4Z"
      fill="#F5991F"
    />
    <mask
      id="c"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x={92}
      y={69}
      width={5}
      height={5}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M94.825 73.999a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0-3.2a1.2 1.2 0 1 1 0 2.4 1.2 1.2 0 0 1 0-2.4Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#c)">
      <path fill="#5053A3" d="M14.025-20.801H175.21v184.889H14.025z" />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.225 69.2a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0-3.2a1.2 1.2 0 1 1 0 2.399 1.2 1.2 0 0 1 0-2.4Z"
      fill="#F5991F"
    />
    <mask
      id="d"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x={3}
      y={65}
      width={5}
      height={5}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.225 69.2a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0-3.2a1.2 1.2 0 1 1 0 2.399 1.2 1.2 0 0 1 0-2.4Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#d)">
      <path fill="#7D7F88" d="M-75.575-25.601H85.61v184.889H-75.575z" />
    </g>
  </svg>
);
