import React from 'react';

export /* @__PURE__*/ const getEnhancer =
  <Ctx,>(context: React.Context<Ctx>) =>
  (ctxValue: Ctx) =>
  <P extends JSX.IntrinsicAttributes>(Comp: React.ComponentType<P>) => {
    const Enhancer = (props: P) => {
      return (
        <context.Provider value={ctxValue}>
          <Comp {...props} />
        </context.Provider>
      );
    };
    return Enhancer;
  };
