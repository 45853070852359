import { ActionItem, PredefineTask } from '../../types';

export const transformPredefinedTask = (
  predefinedTask: PredefineTask
): Omit<ActionItem, 'id'> => {
  return {
    creatorId: 'Predefined Task Temp Id',
    description: predefinedTask.item,
    dueDate: undefined,
    startAt: undefined,
    type: 'pre/' + predefinedTask.type,
    createdAt: new Date().toISOString(),
  };
};
