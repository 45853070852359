import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { FunctionComponent, useEffect, useState } from 'react';
import {
  QUERY_INTERNAL_NOTES,
  GET_USERS_BY_IDS,
} from '@crimson-education/core-shared-graphql';
import { User } from '../../types';
import { ExpandMoreIcon } from '../icons';
import { AnimCompShow } from '../animations';
import InternalNoteCard from './note-card';
import NoteInput from './note-input';
import * as Styled from './style';
import { TInternalNote } from './type';
import { useApiClient, useUserInfo } from '../../context';

interface InternalNotesProps {
  missionId?: string;
  studentId: string;
  editPermitted: boolean;
  missionRework?: boolean;
}
export /* @__PURE__*/ const InternalNotes: FunctionComponent<InternalNotesProps> = ({
  missionId,
  studentId,
  editPermitted,
  missionRework
}) => {
  const [notes, setNotes] = useState<TInternalNote[]>([]);
  const [visibleCount, setVisibleCount] = useState(4);

  const { coreApiClient, studentCenterApiClient } = useApiClient();
  const { data, refetch: fetchInternalNotes } = useQuery(QUERY_INTERNAL_NOTES, {
    variables: { missionId },
    client: studentCenterApiClient,
  });
  const { refetch: fetchUsersByIds } = useQuery(GET_USERS_BY_IDS, {
    client: coreApiClient,
    skip: true,
  });

  useEffect(() => {
    const setNotesWithCreators = async () => {
      const userIds = _.uniq(
        data.notes.map((note: TInternalNote) => note.creatorUid)
      );
      const { data: userData } = await fetchUsersByIds({ userIds });
      const userMap: Map<string, User> = userData.usersBasicInfo.reduce(
        (acc: Map<string, User>, user: User) => {
          return acc.set(user.userId, user);
        },
        new Map<string, User>()
      );
      const notesWithCreators = data.notes.map((note: TInternalNote) => ({
        ...note,
        creator: userMap.has(note.creatorUid)
          ? userMap.get(note.creatorUid)
          : undefined,
      }));
      const orderedNotes = [
        ..._.orderBy(notesWithCreators, ['createdAt'], ['desc']),
      ];
      setNotes(orderedNotes);
    };
    if (data?.notes) {
      setNotesWithCreators();
    }
  }, [data?.notes, fetchUsersByIds]);

  const onClickViewMore = () => {
    setVisibleCount(visibleCount + 4);
  };

  const currentUser = useUserInfo();
  const isLevel3 = currentUser.tenant?.level === 3;
  return (
    <div>
      <Styled.Title>
        {missionRework ? null : <span>Internal Notes</span>} <span>Visible and editable by {isLevel3 && <span>Crimson </span>}staff only</span>
      </Styled.Title>
      <NoteInput
        studentId={studentId}
        missionId={missionId}
        onNoteSaved={() => fetchInternalNotes()}
        editPermitted={editPermitted}
      />
      {notes?.length > 0 ? (
        <Styled.NoteListContainer>
          {notes.slice(0, visibleCount).map((note: TInternalNote) => (
            <AnimCompShow key={note.id}>
              <InternalNoteCard
                studentId={studentId}
                missionId={missionId}
                key={note.id}
                note={note}
              />
            </AnimCompShow>
          ))}
          {notes?.length > visibleCount ? (
            <Styled.ViewMoreContainer>
              <Styled.ViewMoreButton onClick={onClickViewMore}>
                View Older
                <ExpandMoreIcon width={20} />
              </Styled.ViewMoreButton>
            </Styled.ViewMoreContainer>
          ) : null}
        </Styled.NoteListContainer>
      ) : null}
    </div>
  );
};
