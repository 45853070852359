import {
  ApolloQueryResult,
  OperationVariables,
  useMutation,
} from '@apollo/client';
import { NavigateNext } from '@styled-icons/material';
import { theme } from '../../utils/theme';
import moment from 'moment';
import { useEffect, useState } from 'react';
import {
  DELETE_ACTION_ITEM,
  CHANGE_ACTION_ITEM_STATUS,
  CHANGE_ACTION_ITEM_DESC,
  CHANGE_ACTION_ITEM_DATE,
} from '@crimson-education/core-shared-graphql';
import {
  ActionItem as ActionItemType,
  ActionItemStatus,
  MissionChangeActions,
} from '../../types';
import { RemoveButton } from '../remove-button/remove-button';
import {
  Checkbox,
  RangePickerPopover,
  RangePicker,
  ChangeDate,
  DescSpan,
  RemoveButtonContainer,
  TaskTextArea,
} from './style';
import { useApiClient, useFeatureFlag } from '../../context';
import { MoreOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

type Props = {
  loadActionItems: (
    variables?: Partial<OperationVariables> | undefined,
  ) => Promise<
    ApolloQueryResult<{
      items: ActionItemType[];
    }>
  >;
  editPermitted: boolean;
  deletePermitted: boolean;
  onMissionChange?: (action: MissionChangeActions) => void;
} & (
  | {
      item: Omit<ActionItemType, 'id'> & { id?: string };
      pending: true; // determing if the item is pending or not, if it is pending, it should not be able to be edited or deleted
    }
  | {
      item: ActionItemType;
      pending?: undefined;
    }
);

const ActionItem: React.FC<Props> = ({
  item,
  loadActionItems,
  editPermitted,
  deletePermitted,
  pending,
  onMissionChange,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const { roadmapApiClient } = useApiClient();
  const [deleteActionItem] = useMutation(DELETE_ACTION_ITEM, {
    client: roadmapApiClient,
  });
  const [changeActionItemStatus] = useMutation(CHANGE_ACTION_ITEM_STATUS, {
    client: roadmapApiClient,
  });
  const [changeActionItemDescription] = useMutation(CHANGE_ACTION_ITEM_DESC, {
    client: roadmapApiClient,
  });
  const [changeActionItemDueDate] = useMutation(CHANGE_ACTION_ITEM_DATE, {
    client: roadmapApiClient,
  });
  const [checked, setChecked] = useState(item.status === ActionItemStatus.DONE);
  useEffect(()=> {
    setChecked(item.status === ActionItemStatus.DONE);
  }, [item.status]);
  const INDIGO_SOP = useFeatureFlag('INDIGO_SOP');

  const submitDesc = async (e: { target: { value: string } }) => {
    const value = e.target?.value;
    await changeActionItemDescription({
      variables: {
        actionItemId: item.id,
        description: value || '',
      },
    });
    loadActionItems();
    setIsEditing(false);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        opacity: pending ? 0.7 : 1,
      }}
    >
      <Checkbox
        onChange={async (e) => {
          if (pending) return;
          const value = e.target.checked;
          setChecked(value);
          let status = ActionItemStatus.PLANNED;
          if (value === true) {
            status = ActionItemStatus.DONE;
          }
          await changeActionItemStatus({
            variables: {
              actionItemId: item.id,
              status: status,
            },
          });
          loadActionItems();
          onMissionChange?.('ChangeActionItemStatus');
        }}
        checked={checked}
        disabled={pending ?? !editPermitted}
      />

      <div style={{ flex: '1 1 auto', marginRight: 20 }}>
        {isEditing ? (
          <TaskTextArea
            autoSize={{ minRows: 1, maxRows: 7 }}
            bordered={false}
            defaultValue={item.description as string}
            onBlur={submitDesc}
            autoFocus
            disabled={(pending ?? !editPermitted)}
          />
        ) : (
          <DescSpan
            checked={item.status === ActionItemStatus.DONE}
            onClick={() => {
              if (pending) return;
              editPermitted && setIsEditing(true);
            }}
          >
            {item.description}
          </DescSpan>
        )}
      </div>

      <RangePickerPopover
        trigger="hover"
        content={
          <RangePicker
            allowEmpty={[true, true]}
            bordered={false}
            format="MMM DD, YYYY"
            allowClear={false}
            value={[
              item.startAt ? moment(item.startAt as string) : moment(),
              item.dueDate ? moment(item.dueDate as string) : null,
            ]}
            onChange={async (dates) => {
              if (pending) return;
              if (dates) {
                await changeActionItemDueDate({
                  variables: {
                    actionItemId: item.id,
                    startAt: dates[0] || null,
                    dueDate: dates[1] || null,
                  },
                });
                loadActionItems();
              }
            }}
            disabled={(pending ?? !editPermitted)}
          />
        }
      >
        <ChangeDate>
          <span>
            {item.dueDate
              ? `${moment(item.dueDate).format('MMM DD, YYYY')}`
              : editPermitted && (
                  <span style={{ color: `${theme.common.salmon}` }}>
                    Set date
                  </span>
                )}
          </span>
          {editPermitted && <NavigateNext height={20} />}
        </ChangeDate>
      </RangePickerPopover>

      {!INDIGO_SOP && !pending && deletePermitted && (
        <RemoveButtonContainer>
          <RemoveButton
            onConfirm={async () => {
              await deleteActionItem({
                variables: {
                  actionItemId: item.id,
                },
              });
              loadActionItems();
              onMissionChange?.('DeleteActionItem');
            }}
            removeButtonText="Remove this task"
            removeModalText="Are you sure you want to remove this task?"
          />
        </RemoveButtonContainer>
      )}
      {
        INDIGO_SOP && !pending && deletePermitted && (
        <RemoveButtonContainer>
            <RemoveButton
              onConfirm={async () => {
                await deleteActionItem({
                  variables: {
                    actionItemId: item.id,
                  },
                });
                loadActionItems();
                onMissionChange?.('DeleteActionItem');
              }}
              removeButtonText="Remove this task"
              removeModalText="Are you sure you want to remove this task?"
            />
        </RemoveButtonContainer>)
      }
    </div>
  );
};

export default ActionItem;
